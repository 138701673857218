import controller from './company-index-controller';
import template from './company-index.html';
import './company-index.scss';

function companyIndexDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            companies: '='
        }
    }
}

export default companyIndexDirective;