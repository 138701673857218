import controller from './device-profile-screen-selection-controller';
import template from './device-profile-screen-selection.html';

function deviceProfileScreenSelectionDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            model: '='
        },
        scope: true
    }
}

export default deviceProfileScreenSelectionDirective;