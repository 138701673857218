import controller from './place-detail-controller';
import template from './place-detail.html';
import './place-detail.scss';

function placeDetailDirective () {
  return {
    template,
    controller,
    restrict: 'E',
    controllerAs: 'vm',
    bindToController: {
      place: '<',
      company: '<'
    }
  }
}

export default placeDetailDirective;