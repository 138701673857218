import controller from './device-profile-navigation-controller';
import template from './device-profile-navigation.html';

function deviceProfileNavigationDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            model: '=',
            deviceProfile: '='
        },
        scope: true
    }
}

export default deviceProfileNavigationDirective;