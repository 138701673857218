import controller from './device-profile-screen-add-controller';
import template from './device-profile-screen-add.html';

function deviceProfileScreenAddDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            deviceProfile: '<'
        }
    }
}

export default deviceProfileScreenAddDirective;