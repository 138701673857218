import controller from './title-controller';
import template from './title.html';

function titleDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm'
    }
}

export default titleDirective;