import template from './nav.html';
import controller from './nav-controller';
import './nav.scss';

function siteNavDirective () {
    return {
        controller,
        template,
        controllerAs: 'vm',
        restrict: 'E',
        scope: {}
    }
}

export default siteNavDirective;