class CompanyUsersController {
    constructor($http, SearchModel, $state, $scope) {
        this.$http = $http;
        this.$state = $state;
        this.$scope = $scope;
    }

    $onInit() {
        this.items = this.users.items;
    }

    setUserFilter(superUsers, developers) {
        this.users.search.filters['IsCompanySuperUser'].value = superUsers;
        this.users.search.filters['IsDeveloper'].value = developers;
    }

    getAccountType(user) {
        if(user.isDeveloper){
            return 'dev';
        }
        else if (user.isCompanySuperUser) {
            return 'super';
        }
        else {
            return "std";
        }
    }
}

CompanyUsersController.$inject = ['$http', 'SearchModel', '$state', '$scope'];

export default CompanyUsersController;