function uniqueCompanyNameDirective($http, $q) {
  return {
    require: 'ngModel',
    scope: {
      companyId: '='
    },
    link: function (scope, element, attrs, ngModel) {
      ngModel.$asyncValidators.uniqueCompanyName = function (modelView, viewValue) {
        let url = `/rateit/api/companies/validate-unique?name=${viewValue}`;

        if (scope.companyId) {
          url += `&companyId=${scope.companyId}`;
        }

        return $http.get(url)
          .then(response => {
            if (!response.data.name) {
              return $q.reject();
            }
            return true;
          });
      }
    }
  };
}

uniqueCompanyNameDirective.$inject = ['$http', '$q'];

export default uniqueCompanyNameDirective;