import controller from './rating-delete-all-controller';
import template from './rating-delete-all.html';

function ratingDeleteAllDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            company: '<',
            place: '<?'
        }
    }
}

export default ratingDeleteAllDirective;