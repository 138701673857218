class RatingDetailController {
    constructor ($http, $scope, $state, messages, moment) {
        this.$http = $http;
        this.$scope = $scope;
        this.$state = $state;
        this.messages = messages;
        this.moment = moment
        this.tab = 'validation';
    }

    $onInit () {
        this.consideredResponseTimings = {};

        var consResp = this.rating.validationResults.find(vr => vr.key.id === '0075ac65-010d-4edd-8b49-f941147ca926');
        if (consResp) {
            var result = consResp.values[0];
            if (result) {
                var options = JSON.parse(result.options);
                if (options) {
                    this.consideredResponseTimings = options.Times || options.times;
                }
            }
        }

        this.orderTelemetry = (telemetryItem) => {
            if (!telemetryItem.activityId) {
                return undefined;
            }

            const activity = this.deviceProfile.definition.activities[telemetryItem.activityId];
            if (activity) {
                return activity.percentageCompletion / 100;
            }

            return undefined;
        }
    }

    isOverride(result) {
        result = result.values[0];
        return (result.positive && result.positiveOverride) || (!result.positive && result.negativeOverride);
    }

    isNotOverride(result) {
        result = result.values[0];
        return !((result.positive && result.positiveOverride) || (!result.positive && result.negativeOverride));
    }

    formatSetBy(name) {
        var regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return regex.test(name) ? 'Validation System' : name;
    }

    telemetryPassFail(item) {
        if (!item.timeSpent || !this.consideredResponseTimings) {
            return 0;
        }

        var durMs = this.moment.duration(item.timeSpent).asMilliseconds();
        var timingMs = this.consideredResponseTimings[item.activityType];

        if (!timingMs) {
            return 0;
        }

        return durMs >= timingMs ? 1 : -1;
    }

    getTelemetryLabel(telemetryItem, deviceProfile) {
        if (!telemetryItem.activityId) {
            return telemetryItem.activityType;
        }

        const activity = deviceProfile.definition.activities[telemetryItem.activityId];
        if (activity) {
            return `${activity.label} (${activity.type})`;
        }

        return telemetryItem.activityType;
    }

    formatTimeSpent(timeSpent) {
        if (!timeSpent) {
            return 'No time captured';
        }

        var dur = this.moment.duration(timeSpent);

        var parts = {
            min: dur.minutes(),
            sec: dur.seconds(),
            ms: dur.milliseconds(),
            suffix: ''
        };

        //if we have no milliseconds total, return a generic string
        if (dur.asMilliseconds() === 0) {
            return 'No time captured';
        }

        let str;

        var padLeft = (padding, value) => {
            return (padding + value).slice(-1 * padding.length);
        }

        //we build backwards
        if (parts.ms !== 0) {
            str = `.${padLeft("000", parts.ms)}`;
            parts.suffix = 'ms';
        }

        //seconds (we include if not 0)
        if (parts.sec !== 0) {
            let padded = ("00" + parts.sec).slice(-2);
            str = `:${padLeft("00", parts.sec)}${str}`;
            parts.suffix = 'sec';
        }

        if (parts.min !== 0) {
            let extra = parts.sec === 0 && parts.ms !== 0 ? ':00' : '';
            str = `${parts.min}${extra}${str}`;
            parts.suffix = 'min';
        }

        //strip the leading chars (and any extra 0's on the seconds)
        if (str) {
            str = str.replace(/^(:0?|\.)/, '');
        }

        return `${str} ${parts.suffix}`;
    }
}

RatingDetailController.$inject = ['$http', '$scope', '$state', 'messages', 'moment'];

export default RatingDetailController;