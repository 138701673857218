import template from './ri-textarea.html';

function riTextareaDirective () {
    return {
        template,
        restrict: 'E',
        scope: {},
        require: 'ngModel',
        controllerAs: 'vm',
        bindToController: {
            rows: '=',
            //ngModel: '<'
            placeholder: '@?',
            disabled: '=?ngDisabled',
            required: '=?ngRequired'
        },
        controller: ['$scope', '$interpolate', '$attrs', function ($scope, $interpolate, $attrs) {
            this.errors = function () {
                return Object.keys($scope.ctrl.$error).filter(v => v != 'required');
            }

            this.errorText = function (name) {
                return $interpolate($attrs[name + 'Text'].replace('%value%', '{{value}}'))({value: $scope.ctrl.$viewValue});
            }
        }],
        link:function(scope, element, attrs, ngModel) {
            scope.ctrl = ngModel;

            scope.$watch(() => ngModel.$modelValue, (n) => {
                if (typeof n == 'undefined' || n == null) return;
                scope.vm.value = n;
            });

            scope.$watch(() => scope.vm.value, (n, o) => {
                if ((typeof n !== 'undefined') && n != null && n !== o) {
                    ngModel.$setViewValue(n);
                }
            });
        }
    }
}

export default riTextareaDirective;