import controller from './company-user-detail-controller';
import template from './company-user-detail.html';
import './company-user-detail.scss';

function companyUserDetailDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            user: '<',
            companyId: '<'
        }
    }
}

export default companyUserDetailDirective;