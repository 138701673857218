import screenTypeOptions from '../screen-type-options';

class TranslationController {
    constructor ($scope) {
        this.i18nModel = {};
        this.$scope = $scope;        
    }

    $onInit () {
        this.keys = screenTypeOptions[this.model.type].extractTranslationKeys(this.model);
        var profileLanguages = this.deviceProfile.definition.languages;
        this.language = profileLanguages[0].key;
        this.translations = {};

        if (!this.model.languages) {
            this.model.languages = [];
        }

        profileLanguages.forEach(l => {
            if (!this.model.languages.find(x=>x.key === l.key)) {
                this.model.languages.push({
                    key: l.key,
                    name: l.name,
                    translations: {}
                });
            }
        });

        this.languages = profileLanguages;
        this.$scope.$watch(() => this.language, (v) => {
            this.selectedTranslations = this.getTranslationModel(v);
        });
    }

    getTranslationModel(key) {
        this.noMatches = [];
        if (!this.translations[key]) {
            let language = this.model.languages.find(l=>l.key === key);
            let translations = language.translations;

            this.keys.forEach(x => {
                if (!translations[x]) {
                    translations[x] = key === this.deviceProfile.definition.languages[0].key ? x : '';
                }
            });

            Object.keys(translations).forEach(t => {
                if (this.keys.indexOf(t) === -1) {
                    this.noMatches.push(t);
                }
            });

            this.translations[key] = translations;
        }

        return this.translations[key];                
    }

    removeTranslation (key) {
        delete this.selectedTranslations[key];
    }
}

TranslationController.$inject = ['$scope'];

export default TranslationController;