import cxSvg from './screens/cx.svg';
import npsSvg from './screens/nps.svg';
import categoriesSvg from './screens/categories.svg';
import commentsSvg from './screens/comments.svg';
import emailSvg from './screens/email.svg';
import thanksSvg from './screens/thank-you.svg';
import yesNoSvg from './screens/yes-no.svg';
import multiChoiceSvg from './screens/multi-choice.svg';
import staffSvg from './screens/staff.svg';
import phoneSvg from './screens/phone.svg';
import formSvg from './screens/form.svg';

var types = {
    'Categories': {
        label: 'Categories',
        directive: 'screen-categories',
        directiveOptions: {
            tintedBg: true
        },
        thumb: categoriesSvg,
        navigationKeys: function (activity) {
            return activity.data.categories.map(c => c.key);
        },
        description: 'The categories activity presents one or more questions to the user and collects a response from awful to awesome in the form of a smiley face for each one.',
        navigationPresets: function (activity) {
            var presets = activity.data.categories.map(o => {
                var p = {
                    label: `${o.title} is awful`,
                    description: `When the category '${o.title}' is awful.`,
                    or: {}
                };

                p.or[o.key] = ['awful']

                return p;
            });

            presets = presets.concat(activity.data.categories.map(o => {
                var p = {
                    label: `${o.title} is not great`,
                    description: `When the category '${o.title}' is not great.`,
                    or: {}
                };

                p.or[o.key] = ['notgreat']

                return p;
            }));

            presets = presets.concat(activity.data.categories.map(o => {
                var p = {
                    label: `${o.title} is good`,
                    description: `When the category '${o.title}' is good.`,
                    or: {}
                };

                p.or[o.key] = ['good']

                return p;
            }));

            presets = presets.concat(activity.data.categories.map(o => {
                var p = {
                    label: `${o.title} is awesome`,
                    description: `When the category '${o.title}' is awesome.`,
                    or: {}
                };

                p.or[o.key] = ['awesome']

                return p;
            }));
            
            return presets.concat([
                {
                    label: 'Two not great or awful',
                    description: 'When the category responses that are awful or not great equal 2 or more.',
                    match: {
                        count: 2,
                        or: {
                            '*': ['notgreat', 'awful']
                        }
                    }
                }
            ]);
        },
        extractTranslationKeys (activity) {
            return [
                activity.data.smileyCaptions.awesome,
                activity.data.smileyCaptions.good,
                activity.data.smileyCaptions.notGreat,
                activity.data.smileyCaptions.awful
            ]
            .concat(activity.data.categories.map(c=>c.title))
            .concat(activity.data.categories.map(c=>c.subtitle))
            .filter(x=>x!== null &&x!==undefined);
        }
    },
    'Comment': {
        label: 'Comments',
        directive: 'screen-comments',
        thumb: commentsSvg,
        description: 'The comments activity collects comments in response to a specific question.',
        extractTranslationKeys (activity) {
            return [
                activity.data.title,
                activity.data.subtitle,
                activity.data.placeholderCaption,
                activity.data.isTemplatingEnabled ? activity.data.templates.hintCaption : undefined,
                activity.data.nextCaption,
                activity.data.isPersonalizationEnabled ? activity.data.personalizationInfo.caption : undefined
            ]
            .concat((activity.data.templates || {}).responses)
            .filter(x=>x!== null &&x!==undefined);
        }
    },
    'Email': {
        label: 'Email',
        directive: 'screen-email',
        thumb: emailSvg,
        description: 'The email activity prompts the user for their email address so they can be contacted.',
        extractTranslationKeys (activity) {
            return [
                activity.data.title,
                activity.data.subtitle,
                activity.data.isExtendedContactEnabled ? activity.data.extendedContactCaption : undefined,
                activity.data.nextCaption,
                activity.data.placeholderCaption,
                activity.data.isPersonalizationEnabled ? activity.data.personalizationInfo.caption : undefined
            ]
            .filter(x=>x!== null &&x!==undefined);
        }
    },
    'Experience': {
        label: 'Customer Experience',
        directive: 'screen-cx',
        directiveOptions: {
            tintedBg: true
        },
        thumb: cxSvg,
        description: 'The customer experience activity presents the user with 4 smiley faces to indicate their experience from awful to awesome.',        
        navigationKeys: function () {
            return ['cx'];
        },
        navigationPresets: function (activity) {
            return [
                {
                    label: 'When awful',
                    description: 'When the user response is awful the user will navigate to this activity.',
                    or: {
                        cx: ['awful']
                    }
                },
                {
                    label: 'When awesome',
                    description: 'When the user response is awesome the user will navigate to this activity.',
                    or: {
                        cx: ['awesome']
                    }
                },
                {
                    label: 'When good',
                    description: 'When the user response is good the user will navigate to this activity.',
                    or: {
                        cx: ['good']
                    }
                },
                {
                    label: 'When not great',
                    description: 'When the user response is not great the user will navigate to this activity.',
                    or: {
                        cx: ['notgreat']
                    }
                },
                {
                    label: 'When positive',
                    description: 'When the user response is awesome or good the user will navigate to this activity.',
                    or: {
                        cx: ['awesome', 'good']
                    }
                },
                {
                    label: 'When negative',
                    description: 'When the user response is awful or not great the user will navigate to this activity.',
                    or: {
                        cx: ['awful', 'notgreat']
                    }
                }
            ];
        },
        extractTranslationKeys (activity) {
            return [
                activity.data.title,
                activity.data.smileyCaptions.awesome,
                activity.data.smileyCaptions.good,
                activity.data.smileyCaptions.notGreat,
                activity.data.smileyCaptions.awful
            ]
            .filter(x=>x!== null &&x!==undefined && x.length > 0);
        }
    },
    'MultiChoice': {
        label: 'Multi choice',
        directive: 'screen-multi-choice',
        thumb: multiChoiceSvg,
        description: 'The multi choice activity allows the user to provide one or several predefined responses to a question.',
        navigationKeys: function (activity) {
            return [{id: 'choice', label: 'choice'}];
        },
        navigationPresets: function (activity) {
            var presets = activity.data.options.map(o => {
                return {
                    label: o.caption,
                    description: `When the option '${o.caption}' is selected.`,
                    or: {
                        choice: [o.id]
                    }                    
                };
            });
            return presets;
        },
        extractTranslationKeys (activity) {
            return [
                activity.data.title,
                activity.data.subtitle,
                activity.data.nextCaption,
                activity.data.otherNextCaption
            ]
            .concat(activity.data.options.map(o=>o.caption))
            .filter(x => x !== null && x !== undefined);
        }
    },
    'Nps': {
        label: 'Net Promoter Score (NPS)',
        directive: 'screen-nps',
        thumb: npsSvg,
        navigationKeys: function () {
            return ['nps'];
        },
        navigationPresets: function () {
            return [{
                label: 'Promoter (9-10)',
                description: `When the user is a promoter.`,
                or: {
                    nps: [10, 9]
                }
            }, {
                label: 'Detractor (0-6)',
                description: `When the user is a detractor.`,
                or: {
                    nps: [0,1,2,3,4,5,6]
                }
            }, {
                label: 'Passive (7-8)',
                description: `When the user is passive.`,
                or: {
                    nps: [7,8]
                }
            }];
        },
        description: 'The NPS activity presents the user with buttons numbered 0 to 10 indicating how likely they are to recommend the business to a friend.',
        extractTranslationKeys (activity) {
            return [
                activity.data.title,
                activity.data.extremelyLikelyCaption,
                activity.data.notAtAllLikelyCaption,
            ]
            .concat(Object.keys(activity.data.npsCaptions).map(k=>activity.data.npsCaptions[k]))
            .filter(x=>x!== null &&x!==undefined);
        }
    },
    'Phone': {
        label: 'Phone',
        directive: 'screen-phone',
        thumb: phoneSvg,
        description: 'The phone activity prompts the user for their phone number so they can be contacted.',
        extractTranslationKeys (activity) {
            return [
                activity.data.title,
                activity.data.subtitle,
                activity.data.nextCaption,
                activity.data.placeholderCaption,
            ]
            .filter(x=>x!== null &&x!==undefined);
        }
    },
    'Form': {
        label: 'Form',
        directive: 'screen-form',
        thumb: formSvg,
        description: 'The form activity allows for arbitrary text or numeric data capture.',
        extractTranslationKeys (activity) {
            return [
                activity.data.title,
                activity.data.subtitle,
                activity.data.nextCaption
            ]
            .concat(activity.data.fields.map(f=>f.placeholderCaption))
            .filter(x=>x!== null &&x!==undefined);
        }
    },
    'Staff': {
        label: 'Staff',
        directive: 'screen-staff',
        thumb: staffSvg, 
        description: 'The staff activity allows the user to associate their experience to a specific staff member.',
        navigationPresets: function (activity) {
            var presets = [];
            if (activity.data.showNoStaffOption) {
                presets.push({
                    label: 'No one served me',
                    description: `When 'No one served me' is selected.`,
                    or: {
                        staff: ['nostaff']
                    }
                });
            }
            if (activity.data.showUnknownStaffOption) {
                presets.push({
                    label: `I don't know`,
                    description: `When 'I don't know' is selected.`,
                    or: {
                        staff: ['unknownstaff']
                    }
                });
            }                
            return presets;
        },            
        extractTranslationKeys (activity) {
            return [
                activity.data.title,
                activity.data.subtitle,
                activity.data.noStaffOption ? activity.data.noStaffOption.label : null,
                activity.data.unknownStaffOption ? activity.data.unknownStaffOption.label : null
            ]
            .filter(x=>x !== null && x!==undefined && x.length > 0);
        }
    },
    'Thanks': {
        label: 'Thank you',
        directive: 'screen-thanks',
        thumb: thanksSvg,
        description: 'The thanks activity lets the user know how much you care.',
        skipNavigation: true,
        extractTranslationKeys (activity) {
            return [
                activity.data.title,
                activity.data.closingCaption,
                activity.data.imageCaption,
            ]
            .filter(x=>x!== null &&x!==undefined);
        }
    },
    'YesNo': {
        label: 'Yes / No',
        directive: 'screen-yesno',
        thumb: yesNoSvg,
        description: 'The yes/no activity allows you to pose a question to the user and get a yes or no response.',
        navigationKeys: function () {
            return ['yes', 'no'];
        },
        navigationPresets: function () {
            return [{
                label: 'Yes',
                description: `When 'Yes' is selected.`,
                or: {
                    choice: ['yes']
                }
            }, {
                label: 'No',
                description: `When 'No' is selected.`,
                or: {
                    choice: ['no']
                }
            }]
        },
        extractTranslationKeys (activity) {
            return [
                activity.data.title,
                activity.data.subtitle,
                activity.data.yesCaption,
                activity.data.noCaption,
                activity.data.personalizationInfo ? activity.data.personalizationInfo.caption : undefined
            ]
            .filter(x=>x!== null &&x!==undefined);
        }
    }    
};

export default types;