import controller from './rating-toggle-validity-controller';
import template from './rating-toggle-validity.html';

function ratingToggleValidityDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            rating: '='
        }
    }
}

export default ratingToggleValidityDirective;