class NpsController {
    constructor () {
    }

    $onInit () {
        if (this.model && this.model.isNew) {
            this.model.data.title = this.model.data.title || 'How likely are you to\nrecommend us to a friend?';
            this.model.data.extremelyLikelyCaption = this.model.data.extremelyLikelyCaption || 'Extremely likely';
            this.model.data.notAtAllLikelyCaption = this.model.data.notAtAllLikelyCaption || 'Not at all likely';
            this.model.data.orientation = this.model.data.orientation  || 'Horizontal';
            if (!this.model.data.npsCaptions) {
                this.model.data.npsCaptions = {
                    0: '0',
                    1: '1',
                    2: '2',
                    3: '3',
                    4: '4',
                    5: '5',
                    6: '6',
                    7: '7',
                    8: '8',
                    9: '9',
                    10: '10'
                };                
            }
        }
    }
}

export default NpsController;