class EmailController {
    constructor () {
    }

    $onInit () {
        if (this.model && this.model.isNew) {
            this.model.data.title = this.model.data.title || 'May we respond\nto you about this?';
            this.model.data.subtitle = this.model.data.subtitle || 'We will personally email you. No Spam!';
            this.model.data.placeholderCaption = this.model.data.placeholderCaption || 'Sure, my email is...';
            this.model.data.nextCaption = this.model.data.nextCaption || 'All done';
            this.model.data.emailDomains = this.model.data.emailDomains || [];
            this.model.data.personalizationInfo = this.model.data.personalizationInfo || {};
        }
    }

    addDomain () {
        if (this.model.data.emailDomains.indexOf(this.domain) === -1) {
            if (this.domain && this.domain.length) {
                this.model.data.emailDomains.push(this.domain);
                this.domain = null;
            }
        }
    }

    removeDomain (domain) {
        if (this.model.data.emailDomains.indexOf(domain) > -1) {
            this.model.data.emailDomains.splice(this.model.data.emailDomains.indexOf(domain), 1);
        }
    }
}

export default EmailController;