function rewriteHttpInterceptor ($window, configuration) {
	var inflightAuthRequest = null;
    const apiPlaceHolder = '/rateit';
    const portalPlaceHolder = '/portal';
	const tokenPlaceHolder = '/token';

    return {
		request: function (config) {
			if (config.url.indexOf(apiPlaceHolder) === 0) {
				config.url = configuration.get('apiUrl') + config.url.substring(apiPlaceHolder.length);
			}

			if (config.url.indexOf(portalPlaceHolder) === 0) {
				config.url = configuration.get('portalUrl') + config.url.substring(portalPlaceHolder.length);
			}

			if (config.url.indexOf(tokenPlaceHolder) === 0) {
				config.url = configuration.get('tokenUrl') + config.url;
			}

			if (config.url.indexOf(configuration.get('apiUrl')) === 0) {
				config.apiRequest = true;
                var token = $window.localStorage.getItem('token');
				config.headers['Authorization'] = 'Bearer ' + token;
			}
			else{
				config.apiRequest = false;
			}

			return config;
		}
	};	
}

rewriteHttpInterceptor.$inject = ['$window', 'configuration'];

export default rewriteHttpInterceptor;