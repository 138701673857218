class Configuration {
    constructor() {
        if (WP_IS_PROD) {
            this.config = require('../config.prod.json');
        } else if (WP_IS_STANDALONE) {
            this.config = require('../config.standalone.json')
        }
        else {
            this.config = require('../config.json');
        }
    }

    get(key) {
        return this.config[key];
    }
}

export default Configuration;