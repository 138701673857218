import screenTypeOptions from './screen-type-options';

class DeviceProfileNavigationController {
    constructor () {
        this.types = screenTypeOptions;        

        this.predicateCheck = (preset) => {
            if (!preset.predicate){
                return true;
            }

            return preset.predicate(this.model);
        }
    }

    $onInit () {
        if (screenTypeOptions[this.model.type].navigationKeys) {
            var keys = screenTypeOptions[this.model.type].navigationKeys(this.model);
            if (keys[0] && !keys[0].id) {
                keys = keys.map(k => {
                    return { id: k, label: k };
                });
            }

            this.navigationKeys = [{id: '*', label: '*'}].concat(keys);
        }
        else {
            this.navigationKeys = [{id: '*', label: '*'}];
        }
    }

    notSelf (value) {
        return value.id !== this.model.id;
    }

    addRule (rule, target) {
        if (!rule) {
            return;
        }

        if (!this.model.navigation.rules) {
            this.model.navigation.rules = [];
        }
        
        this.model.navigation.rules.push({
            label: rule.label,
            or: rule.or,
            match: rule.match,
            targetId: target
        });
    }

    removeRule (rule) {
        if (this.model.navigation.rules.indexOf(rule) > -1) {
            this.model.navigation.rules.splice(this.model.navigation.rules.indexOf(rule), 1);
        }
    }

    customKeyLength () {
        var obj = this.custom.or || (this.custom.match || {}).or;
        if (obj)
            return Object.keys(obj).length;

        return 0;
    }

    deleteKey (obj, key) {
        delete obj[key];
    }
}

export default DeviceProfileNavigationController;