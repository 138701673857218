class PagingController {
    constructor ($scope) {
        this.$scope = $scope;
    }

    $onInit () {
        this.$scope.$watch(()=>this.pageIndex, (v) => {
            if (v !== undefined) {
                this.pageNo = v + 1;
            }
        });

        this.$scope.$watch(()=>this.pageNo, (newV, oldV) => {
            if (newV !== undefined) {
                this.pageIndex = newV - 1;                
            }
        });
    }

    checkKey (ev, value) {
        if (isNaN(ev.key)) {
            switch (ev.key) {
                case 'Backspace':
                case 'Delete':
                case 'ArrowLeft':
                case 'ArrowRight':
                    return;
                default:
                    ev.preventDefault();
                    break;
            }            
        }
    }
}

PagingController.$inject = ['$scope'];

export default PagingController;