import uuidV4 from 'uuid/v4';
import ActivityHelper from './activity';

class DeviceProfileAddController {
    constructor ($state, $http, messages) {
        this.model = {
            skipType: null,            
            languages: [
                {
                    "key": "en-US",
                    "name": "English",
                    "translations": {}
                }
            ],
            webActions: {}
        };
        this.$state = $state;
        this.$http = $http;
        this.messages = messages;

        this.wizardOptions = {
            screens: [
                { 
                    state: 'ri.admin.secure.deviceProfiles.add.step1',
                    onexit: () => {
                        if (this.model.deviceProfileId || this.model.importFrom) {
                            return this.save();
                        }
                        else {
                            if (!this.model.activities)
                            {
                                var helper = new ActivityHelper();

                                this.model.activities = [
                                    Object.assign(helper.create({definition: this.model}), { label: 'CX', type: 'experience' }),
                                    Object.assign(helper.create({definition: this.model}), { label: 'Thanks', type: 'thanks' })                        
                                ];

                                this.model.finishTargetId = this.model.activities[1].id;
                            }

                            this.$http.get('/rateit/api/device-profiles/global-keys')
                                .then(r => {
                                    var keys = r.data;
                                    this.model.languages.forEach(l => {
                                        var isDefault = l.key === 'en-US';
                                        keys.forEach(k => {
                                            l.translations[k] = l.translations[k] || (isDefault ? k : null);
                                        });                                
                                    });
                                });
                        }
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.add.step2']);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.add.step2',
                    onexit: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.add.step3']);
                    },
                    onback: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.add.step1']);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.add.step3',
                    onexit: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.add.step4']);                        
                    },
                    onback: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.add.step2']);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.add.step4',
                    onexit: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.add.step5']);
                    },
                    onback: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.add.step3']);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.add.step5',
                    onexit: () => {
                        return this.save();
                    },
                    onback: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.add.step4']);
                    }
                }
            ]
        };
    }

    save () {
        return this.$http.post('/rateit/api/device-profiles', this.model)
            .then(r => {
                var deviceProfileId = r.data.deviceProfileId;
                this.messages.showSuccessMessage(`A device profile has been successfully created.`, null, -1);
                return ['ri.admin.secure.deviceProfiles.detail', {deviceProfileId: deviceProfileId}];
            });
    }
}

DeviceProfileAddController.$inject = ['$state', '$http', 'messages'];

export default DeviceProfileAddController;