import controller from './data-classification-add-edit-controller';
import template from './data-classification-add-edit.html';
import './data-classification-add-edit.scss';

function dataClassificationAddEditDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            classification: '=?'
        }
    }
}

export default dataClassificationAddEditDirective;