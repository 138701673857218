class ApplicationSettingIndexController {
    constructor ($http, SearchModel, $state, $scope, $stateParams) {
        this.loading = true;
        this.$http = $http;
        this.$state = $state;
        this.$scope = $scope;
        this.path = $stateParams.path;
    }

    $onInit () {
        this.items = this.settings.items;
    }

    open (item) {
        if (item.isPath) {
            this.$state.go('.', { path: item.fullName || item.name });
        }
        else {
            this.$state.go('.detail', {key: item.name, setting: item});
        }
    }
}

ApplicationSettingIndexController.$inject = ['$http', 'SearchModel', '$state', '$scope', '$stateParams'];

export default ApplicationSettingIndexController;