import screenTypeOptions from '../screen-type-options';
import ActivityHelper from '../activity';

class DeviceProfileScreenAddController {
    constructor ($state, $http, messages) {
        this.$state = $state;
        this.$http = $http;
        this.messages = messages;

        this.wizardOptions = {
            screens: [
                { 
                    state: 'ri.admin.secure.deviceProfiles.detail.screenAdd',
                    onexit: () => {
                        this.model.typeLocked = true;
                        this.options = screenTypeOptions[this.model.type].directiveOptions;
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenAdd.data', { type: this.model.type }]);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.detail.screenAdd.data',
                    onexit: () => {
                        if (this.deviceProfile.definition.languages.length === 1) {
                            if (screenTypeOptions[this.model.type].skipNavigation) {
                                return this.save();
                            }
                            
                            return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenAdd.navigation', { type: this.model.type }]);
                        }
                        else {
                            return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenAdd.translation', undefined, screenTypeOptions[this.model.type].skipNavigation]);
                        }
                    },
                    onback: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenAdd']);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.detail.screenAdd.translation',
                    onexit: () => {
                        if (screenTypeOptions[this.model.type].skipNavigation) {
                            return this.save();
                        }
                        
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenAdd.navigation', { type: this.model.type }]);
                    },
                    onback: () => {                        
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenAdd.data', { type: this.model.type }]);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.detail.screenAdd.navigation',
                    onexit: () => {
                        return this.save();
                    },
                    onback: () => {
                        if (this.deviceProfile.definition.languages.length === 1) {
                            return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenAdd.data', { type: this.model.type }]);
                        }
                        else {
                            return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenAdd.translation', undefined, screenTypeOptions[this.model.type].skipNavigation]);
                        }
                    }
                }
            ]
        };
    }

    $onInit () {
        var helper = new ActivityHelper();
        this.model = helper.create(this.deviceProfile);
        this.nonProfileData = {};
    }

    save () {
        return this.$http.post(`/rateit/api/device-profiles/${this.deviceProfile.id}/activities`, { deviceProfileId: this.deviceProfile.id, activity: this.model, nonProfileData: this.nonProfileData })
            .then(r => {
                this.deviceProfile.definition.activities.push(r.data.activity);
                
                if (r.data.validationWarnings && r.data.validationWarnings.length) {
                    this.messages.showWarningMessage(`A new activity has been successfully added to this device profile. However there were some validation errors.`, r.data.validationWarnings.map(m=> {return {errorMessage:m}}), -1);
                }
                else {
                    this.messages.showSuccessMessage(`A new activity has been successfully added to this device profile.`, null, -1);
                }

                return ['ri.admin.secure.deviceProfiles.detail', {deviceProfileId: this.deviceProfile.id}]
            });
    }
}

DeviceProfileScreenAddController.$inject = ['$state', '$http', 'messages'];

export default DeviceProfileScreenAddController;