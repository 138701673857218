import controller from './multi-choice-set-existing-controller';
import template from './multi-choice-set-existing.html';
import './multi-choice-set-existing.scss';

function multiChoiceSetExistingDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            mcOptions: '=',
            classifications: '='
        }
    }
}

export default multiChoiceSetExistingDirective;