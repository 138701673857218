import screenTypeOptions from './screen-type-options';
import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uicommon from '../ui-common/module';
import ngQuickGrid from 'ng-quick-grid';
import headerTemplate from './hero.html';
import detailHeaderTemplate from './detail-hero.html';
import detailHeaderV2Template from './v2/device-profile-detail/detail-hero-v2.html';
import deviceProfileIndexDirective from './device-profile-index-directive';
import deviceProfileAddDirective from './device-profile-add-directive';
import deviceProfileAddV2Directive from './v2/device-profile-add/device-profile-add-v2-directive';
import deviceProfileAddStep1Directive from './device-profile-add-step1-directive';
import deviceProfileGlobalTranslationsDirective from './translation-directive';
import deviceProfileDetailDirective from './device-profile-detail-directive';
import deviceProfileDetailV2Directive from './v2/device-profile-detail/device-profile-detail-v2-directive';
import deviceProfileScreenAddDirective from './device-profile-screen-add/device-profile-screen-add-directive';
import deviceProfileScreenSelectionDirective from './device-profile-screen-add/device-profile-screen-selection-directive';
import deviceProfileNavigationDirective from './device-profile-navigation-directive';
import deviceProfileScreenEditDirective from './device-profile-screen-edit/device-profile-screen-edit-directive';
import deviceProfileEditDirective from './device-profile-edit-directive';
import deviceProfileEditStep1Directive from './device-profile-edit-step1-directive';
import webActionDirective from './web-action-directive';
import deviceProfilePublishDirective from './device-profile-publish-directive';
import deviceProfilePublishV2Directive from './v2/device-profile-publish/device-profile-publish-v2-directive';
import deviceProfileDraftDirective from './device-profile-draft-directive';
import deviceProfileDraftDiscardDirective from './device-profile-draft-discard-directive';
import deviceProfileExportDirective from './device-profile-export-directive';
import deviceProfileDiagramDirective from './device-profile-diagram-directive';
import deviceProfileDeleteActivityDirective from './device-profile-delete-activity-directive';
import screenCxDirective from './screens/cx-directive';
import screenNpsDirective from './screens/nps-directive';
import screenThanksDirective from './screens/thanks-directive';
import screenCategoriesDirective from './screens/categories-directive';
import screenCommentsDirective from './screens/comments-directive';
import screenYesNoDirective from './screens/yesno-directive';
import screenEmailDirective from './screens/email-directive';
import screenMultiChoiceDirective from './screens/multi-choice-directive';
import screenStaffDirective from './screens/staff-directive';
import screenPhoneDirective from './screens/phone-directive';
import screenFormDirective from './screens/form-directive';
import screenTranslationDirective from './screens/translation-directive';

export default angular.module('ri.admin.deviceProfiles', [uiRouter, uicommon.name, 'ngQuickGrid'])
    .directive('deviceProfileIndex', deviceProfileIndexDirective)
    .directive('deviceProfileAdd', deviceProfileAddDirective)
    .directive('deviceProfileAddV2', deviceProfileAddV2Directive)
    .directive('deviceProfileAddStep1', deviceProfileAddStep1Directive)
    .directive('deviceProfileTranslations', deviceProfileGlobalTranslationsDirective)
    .directive('deviceProfileDetail', deviceProfileDetailDirective)
    .directive('deviceProfileDetailV2', deviceProfileDetailV2Directive)
    .directive('deviceProfileDeleteActivity', deviceProfileDeleteActivityDirective)
    .directive('deviceProfileScreenAdd', deviceProfileScreenAddDirective)
    .directive('deviceProfileScreenEdit', deviceProfileScreenEditDirective)
    .directive('deviceProfileScreenSelection', deviceProfileScreenSelectionDirective)
    .directive('deviceProfileNavigation', deviceProfileNavigationDirective)
    .directive('deviceProfileEdit', deviceProfileEditDirective)
    .directive('deviceProfileEditStep1', deviceProfileEditStep1Directive)
    .directive('deviceProfilePublish', deviceProfilePublishDirective)
    .directive('deviceProfilePublishV2', deviceProfilePublishV2Directive)
    .directive('deviceProfileDraft', deviceProfileDraftDirective)
    .directive('deviceProfileDraftDiscard', deviceProfileDraftDiscardDirective)
    .directive('deviceProfileExport', deviceProfileExportDirective)
    .directive('deviceProfileDiagram', deviceProfileDiagramDirective)
    .directive('screenCx', screenCxDirective)
    .directive('screenNps', screenNpsDirective)
    .directive('screenCategories', screenCategoriesDirective)
    .directive('screenThanks', screenThanksDirective)
    .directive('screenComments', screenCommentsDirective)
    .directive('screenYesno', screenYesNoDirective)
    .directive('screenEmail', screenEmailDirective)
    .directive('screenMultiChoice', screenMultiChoiceDirective)
    .directive('screenStaff', screenStaffDirective)
    .directive('screenPhone', screenPhoneDirective)
    .directive('screenForm', screenFormDirective)
    .directive('screenTranslation', screenTranslationDirective)
    .directive('deviceProfileWebAction', webActionDirective)
    .config(['$stateProvider', '$urlRouterProvider', ($stateProvider, $urlRouterProvider) => {
        $stateProvider
            .state('ri.admin.secure.deviceProfiles', {
                url: '^/device-profiles',
                data: {
                    title: 'Device Profiles',
                    secure: true,
                    icon: 'tablet'
                },
                params: {
                    reload: false
                },
                views: {
                    'hero@ri.admin.secure': {
                        template: headerTemplate
                    },
                    'content@ri.admin.secure': {
                        template: '<device-profile-index></device-profile-index>'
                    }
                }
            })
            .state('ri.admin.secure.deviceProfiles.add', {
                url: '/add',
                abstract: true,
                views: {
                    'dialog@ri.admin.secure.deviceProfiles': {
                        template: '<device-profile-add></device-profile-add>',
                    }
                },
                data: {
                    title: 'Add Device Profile',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                },
                params: {
                    companyId: null
                }
            })
            .state('ri.admin.secure.deviceProfiles.add.step1', {
                url: '',
                views: {
                    'content@ri.admin.secure.deviceProfiles.add': {
                        template: '<device-profile-add-step1 model="vm.model"></device-profile-add-step1>',
                    }
                },
                data: {
                    title: 'Add Device Profile',
                    modal: true,
                    secure: true
                },
                params: {
                    companyId: null
                }
            })
            .state('ri.admin.secure.deviceProfiles.add.step2', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.add': {
                        template: '<device-profile-web-action model="vm.model"></device-profile-web-action>',
                    }
                },
                data: {
                    title: 'Add Device Profile',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.add.step3', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.add': {
                        template: '<screen-cx model="vm.model.activities[0]"></screen-cx>',
                    }
                },
                data: {
                    title: 'Add Device Profile - Customer Experience',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.add.step4', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.add': {
                        template: '<screen-thanks model="vm.model.activities[1]"></screen-thanks>',
                    }
                },
                data: {
                    title: 'Add Device Profile - Thanks',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.add.step5', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.add': {
                        template: '<device-profile-translations model="vm.model"></device-profile-translations>',
                    }
                },
                data: {
                    title: 'Add Device Profile - Global Translations',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail', {
                url: '/{deviceProfileId:[^\/]+}',
                views: {
                    'hero@ri.admin.secure': {
                        template: detailHeaderTemplate,
                        controller: ['$scope', 'deviceProfile', 'configuration', function ($scope, deviceProfile, configuration) {
                            $scope.deviceProfile = deviceProfile;

                            $scope.isValid = function (deviceProfile) {
                                return deviceProfile.definition.activities.every((a) => {
                                    if (!a.navigation) {
                                        return false;
                                    }
                                    var rulesOk = !a.navigation.rules || a.navigation.rules.every(x => {
                                        return !!x.targetId;
                                    });
                                    return !!a.navigation.defaultTargetId && rulesOk;
                                });
                            }
                            $scope.webfeedbackBaseUrl = configuration.get('webfeedbackBaseUrl');
                        }]
                    },
                    'content@ri.admin.secure': {
                        template: '<device-profile-detail device-profile="deviceProfile"></device-profile-detail>',
                        controller: ['$scope', 'deviceProfile', function ($scope, deviceProfile) {
                            $scope.deviceProfile = deviceProfile;
                        }]
                    }
                },
                resolve: {
                    deviceProfile: ['$q', 'messages', '$stateParams', '$http', '$state', function ($q, messages, $stateParams, $http, $state) {
                        var deviceProfileId = $stateParams.deviceProfileId;

                        return $http.get(`/rateit/api/device-profiles/${deviceProfileId}`)
                            .then(r => {
                                return r.data;
                            }, (err) => {

                                if (err.status === 404) {
                                    err.redirectTarget = 'ri.admin.secure.deviceProfiles';
                                    err.redirectParams = {};

                                    messages.showErrorMessage('The requested device profile was not found.');
                                }
                                return $q.reject(err);
                            });
                    }]
                },
                data: {
                    title: 'Device Profile Detail',
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.export', {
                views: {
                    'dialog@ri.admin.secure.deviceProfiles.detail': {
                        template: '<device-profile-export device-profile="deviceProfile"></device-profile-export>',
                        controller: ['$scope', 'deviceProfile', function ($scope, deviceProfile) {
                            $scope.deviceProfile = deviceProfile;
                        }]
                    }
                },
                data: {
                    title: 'Export Device Profile',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.edit', {
                url: '/edit',
                abstract: true,
                views: {
                    'dialog@ri.admin.secure.deviceProfiles.detail': {
                        template: '<device-profile-edit model="deviceProfile"></device-profile-edit>',
                        controller: ['$scope', 'deviceProfile', function ($scope, deviceProfile) {
                            $scope.deviceProfile = deviceProfile;
                        }]
                    }
                },
                data: {
                    title: 'Edit Device Profile',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.edit.step1', {
                url: '',
                views: {
                    'content@ri.admin.secure.deviceProfiles.detail.edit': {
                        template: '<device-profile-edit-step1 device-profile="vm.deviceProfile"></device-profile-edit-step1>'
                    }
                },
                data: {
                    title: 'Edit Device Profile',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.edit.step2', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.detail.edit': {
                        template: '<device-profile-web-action model="vm.deviceProfile.definition"></device-profile-web-action>',
                    }
                },
                data: {
                    title: 'Edit Device Profile',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.edit.step3', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.detail.edit': {
                        template: '<device-profile-translations model="vm.deviceProfile.definition"></device-profile-translations>',
                    }
                },
                data: {
                    title: 'Edit Device Profile',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.publish', {
                views: {
                    'dialog@ri.admin.secure.deviceProfiles.detail': {
                        template: '<device-profile-publish device-profile="deviceProfile"></device-profile-publish>',
                        controller: ['$scope', 'deviceProfile', function ($scope, deviceProfile) {
                            $scope.deviceProfile = deviceProfile;
                        }]
                    }
                },
                data: {
                    title: 'Publish Device Profile',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.createDraft', {
                views: {
                    'dialog@ri.admin.secure.deviceProfiles.detail': {
                        template: '<device-profile-draft device-profile="deviceProfile"></device-profile-draft>',
                        controller: ['$scope', 'deviceProfile', function ($scope, deviceProfile) {
                            $scope.deviceProfile = deviceProfile;
                        }]
                    }
                },
                data: {
                    title: 'Create Draft',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.screenDelete', {
                params: {
                    activityId: undefined
                },
                views: {
                    'dialog@ri.admin.secure.deviceProfiles.detail': {
                        template: '<device-profile-delete-activity device-profile="deviceProfile" activity="activity"></device-profile-delete-activity>',
                        controller: ['$scope', 'deviceProfile', '$stateParams', function ($scope, deviceProfile, $stateParams) {
                            $scope.deviceProfile = deviceProfile;
                            $scope.activity = deviceProfile.definition.activities.find(x => x.id === $stateParams.activityId);
                        }]
                    }
                },
                data: {
                    title: 'Delete Activity',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.discardDraft', {
                views: {
                    'dialog@ri.admin.secure.deviceProfiles.detail': {
                        template: '<device-profile-draft-discard device-profile="deviceProfile"></device-profile-draft-discard>',
                        controller: ['$scope', 'deviceProfile', function ($scope, deviceProfile) {
                            $scope.deviceProfile = deviceProfile;
                        }]
                    }
                },
                data: {
                    title: 'Discard Draft',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.screenAdd', {
                url: '/add-activity',
                params: {
                    typeLocked: undefined
                },
                views: {
                    'dialog@ri.admin.secure.deviceProfiles.detail': {
                        template: '<device-profile-screen-add device-profile="deviceProfile"></device-profile-screen-add>',
                        controller: ['$scope', 'deviceProfile', function ($scope, deviceProfile) {
                            $scope.deviceProfile = deviceProfile;
                        }]
                    },
                    'content@ri.admin.secure.deviceProfiles.detail.screenAdd': {
                        template: '<device-profile-screen-selection model="vm.model"></device-profile-screen-selection>'
                    }
                },
                data: {
                    title: 'Add Activity',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.screenAdd.data', {
                params: {
                    type: undefined
                },
                views: {
                    'content@ri.admin.secure.deviceProfiles.detail.screenAdd': {
                        template: function (params) {
                            return `<${screenTypeOptions[params.type].directive} model="vm.model" device-profile="vm.deviceProfile" non-profile-data="vm.nonProfileData"></${screenTypeOptions[params.type].directive}>`;
                        }
                    }
                },
                data: {
                    title: 'Add Activity - Set Data',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.screenAdd.translation', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.detail.screenAdd': {
                        template: function (params) {
                            return `<screen-translation model="vm.model" device-profile="vm.deviceProfile"></screen-translation>`;
                        }
                    }
                },
                data: {
                    title: 'Update Activity - Internationalisation',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.screenAdd.navigation', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.detail.screenAdd': {
                        template: function (params) {
                            return `<device-profile-navigation model="vm.model" device-profile="vm.deviceProfile"></device-profile-navigation>`;
                        }
                    }
                },
                data: {
                    title: 'Add Activity - Navigation',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.screenEdit', {
                url: '/activities/{activityId}/edit',
                views: {
                    'dialog@ri.admin.secure.deviceProfiles.detail': {
                        template: '<device-profile-screen-edit device-profile="deviceProfile" activity="activity"></device-profile-screen-add>',
                        controller: ['$scope', 'deviceProfile', 'activity', function ($scope, deviceProfile, activity) {
                            $scope.deviceProfile = deviceProfile;
                            $scope.activity = activity;
                        }]
                    },
                    'content@ri.admin.secure.deviceProfiles.detail.screenEdit': {
                        template: '<device-profile-screen-selection model="modalController.model"></device-profile-screen-selection>'
                    }
                },
                resolve: {
                    activity: ['deviceProfile', '$stateParams', function (deviceProfile, $stateParams) {
                        var index = deviceProfile.definition.activities.findIndex(x => x.id === $stateParams.activityId)
                        return angular.copy(deviceProfile.definition.activities[index]);
                    }]
                },
                data: {
                    title: 'Update Activity',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.screenEdit.data', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.detail.screenEdit': {
                        templateProvider: ['activity', function (activity) {
                            return `<${screenTypeOptions[activity.type].directive} model="modalController.model" device-profile="modalController.deviceProfile" non-profile-data="modalController.nonProfileData" on-saving="modalController.onSaving"></${screenTypeOptions[activity.type].directive}>`;
                        }]
                    }
                },
                data: {
                    title: 'Update Activity - Set Data',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.screenEdit.navigation', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.detail.screenEdit': {
                        template: function (params) {
                            return `<device-profile-navigation model="modalController.model" device-profile="modalController.deviceProfile"></device-profile-navigation>`;
                        }
                    }
                },
                data: {
                    title: 'Update Activity - Navigation',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail.screenEdit.translation', {
                views: {
                    'content@ri.admin.secure.deviceProfiles.detail.screenEdit': {
                        template: function (params) {
                            return `<screen-translation model="modalController.model" device-profile="modalController.deviceProfile"></screen-translation>`;
                        }
                    }
                },
                data: {
                    title: 'Update Activity - Internationalisation',
                    modal: true,
                    secure: true
                }
            })

            .state('ri.admin.secure.deviceProfiles.add-v2', {
                url: '/v2/add',
                views: {
                    'dialog@ri.admin.secure.deviceProfiles': {
                        template: '<device-profile-add-v2></device-profile-add-v2>'
                    }
                },
                data: {
                    title: 'Add Device Profile',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail-v2', {
                url: '/v2/{deviceProfileId:[^\/]+}',
                views: {
                    'hero@ri.admin.secure': {
                        template: detailHeaderV2Template,
                        controller: ['$scope', 'deviceProfile', function ($scope, deviceProfile) {
                            $scope.deviceProfile = deviceProfile;
                            $scope.$on('addActivitiesListUIByJson', function (evt, res) {
                                $scope.activitiesListUI = res.activitiesListUI
                                $scope.errorMessage = res.errorMessage;
                            });
                        }]
                    },
                    'content@ri.admin.secure': {
                        template: '<device-profile-detail-v2 device-profile="deviceProfile"></device-profile-detail-v2>',
                        controller: ['$rootScope', '$scope', 'deviceProfile', function ($rootScope, $scope, deviceProfile) {
                            $scope.deviceProfile = deviceProfile;
                        }]
                    }
                },
                resolve: {
                    deviceProfile: ['$q', 'messages', '$stateParams', '$http', '$state', function ($q, messages, $stateParams, $http, $state) {
                        var deviceProfileId = $stateParams.deviceProfileId;

                        return $http.get(`/rateit/api/device-profiles/${deviceProfileId}`)
                            .then(r => {
                                return r.data;
                            }, (err) => {

                                if (err.status === 404) {
                                    err.redirectTarget = 'ri.admin.secure.deviceProfiles';
                                    err.redirectParams = {};

                                    messages.showErrorMessage('The requested device profile was not found.');
                                }
                                return $q.reject(err);
                            });
                    }]
                },
                data: {
                    title: 'Device Profile Detail',
                    secure: true
                }
            })
            .state('ri.admin.secure.deviceProfiles.detail-v2.publish', {
                views: {
                    'dialog@ri.admin.secure.deviceProfiles.detail-v2': {
                        template: '<device-profile-publish-v2 device-profile="deviceProfile"></device-profile-publish-v2>',
                        controller: ['$scope', 'deviceProfile', function ($scope, deviceProfile) {
                            $scope.deviceProfile = deviceProfile;
                        }]
                    }
                },
                data: {
                    title: 'Publish Device Profile',
                    modal: true,
                    secure: true,
                    requiredClaim: { name: 'Dp.Ad', value: '*' }
                }
            });
    }]);
