function compareValidator () {
  function compare (modelValue, viewValue) {
    if (this.ctrl.$isEmpty(modelValue)) {
      // consider empty models to be valid
      return true;
    }

    var matches = this.targetModel.$viewValue === this.ctrl.$viewValue;
    //this.targetModel.$setValidity('compare', matches);
    //this.targetModel.$validate();
    if (matches && this.targetModel.$valid && this.forceValue) {
      //this.targetModel.$setViewValue(modelValue);
      //console.log(this.targetModel.$viewValue, this.targetModel.$modelValue);
      //this.targetModel.$setViewValue('');
      //console.log(this.targetModel.$viewValue, this.targetModel.$modelValue);
    }
    return matches;
  }

  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      var targetModel = scope.$eval(attrs.compare);
      

      ctrl.$validators.compare = compare.bind({ctrl: ctrl, targetModel: targetModel, forceValue: true});
      //targetModel.$validators.compare = compare.bind({ctrl: targetModel, targetModel: ctrl});
    }
  };
}

export default compareValidator;
