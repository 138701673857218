import controller from './phone-controller';
import template from './phone.html';

function phoneDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            model: '=',
            deviceProfile: '='
        },
        scope: true
    }
}

export default phoneDirective;