class DeviceProfilePublishController {
    constructor($http, $state, messages) {
        this.$http = $http;
        this.messages = messages;
        this.$state = $state;
        this.places = [];
        this.selectedDeviceRegistrations = [];
        this.selectedPlaces = [];
    }

    $onInit() {
        var registrations = this.deviceProfile.deviceRegistrations;
        registrations = registrations.filter(r => r.registrationStatus === 'Active');
        var places = this.deviceProfile.places;
        this.places = places;
        this.selectedPlaces = places.map(x => x.id);
        this.selectedDeviceRegistrations = registrations.map(r => r.id)
        var groupedRegistrations = [];
        this.registrations = registrations.slice();

        registrations.sort((a, b) => a.place.name.localeCompare(b.place.name)).map((r, i, arr) => {
            if (groupedRegistrations.findIndex(z => z.id === r.place.id) === -1) {
                groupedRegistrations.push({
                    id: r.place.id,
                    name: r.place.name,
                    registrations: [r]
                });
            }
            else {
                groupedRegistrations[groupedRegistrations.length - 1].registrations.push(r);
            }
        });

        this.groupedRegistrations = groupedRegistrations;
    }

    submit() {
        this.loading = true;
        this.$http.post(`/rateit/api/device-profiles/${this.deviceProfile.id}/publish`, { places: this.selectedPlaces, deviceRegistrations: this.selectedDeviceRegistrations })
            .then((r) => {
                this.messages.showSuccessMessage(`This device profile has been successfully published.`, null, -1);
                Object.assign(this.deviceProfile, r.data);
                this.$state.go('^', { deviceProfileId: this.deviceProfile.id });
            }, () => this.loading = false);
    }

    toggleRegistration(dr) {
        if (this.selectedDeviceRegistrations.indexOf(dr.id) > -1) {
            this.selectedDeviceRegistrations.splice(this.selectedDeviceRegistrations.indexOf(dr.id), 1);
        }
        else {
            this.selectedDeviceRegistrations.push(dr.id);
        }
    }

    toggleAllRegistrations() {
        if (this.selectedDeviceRegistrations.length === this.registrations.length) {
            this.selectedDeviceRegistrations = [];
        }
        else {
            this.selectedDeviceRegistrations = this.registrations.map(r => r.id)
        }
    }
}

DeviceProfilePublishController.$inject = ['$http', '$state', 'messages'];

export default DeviceProfilePublishController;