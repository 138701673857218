import screenTypes from './screen-type-options';
import {tree as d3Tree, hierarchy as d3Hierarchy} from 'd3-hierarchy';
import {select as d3Select} from 'd3-selection';

class DeviceProfileDiagramController {
    constructor ($element, $scope, $timeout, $state) {
        this.$element = $element;
        this.$scope = $scope;
        this.$timeout = $timeout
        this.$state = $state;
        this.x = this.y = 0;
    }

    $onInit () {
        var canvas = angular.element(this.$element[0].querySelector('canvas'));
        this.width = this.$element[0].offsetWidth;
        this.height = this.$element[0].offsetHeight + 20;
        canvas.attr('width', this.width + 'px');
        canvas.attr('height', (this.height) + 'px');
        var activityWatchers = [];

        this.$scope.$watchCollection(() => this.deviceProfile.definition.activities, (n, o) => {
            this.redraw();
        });
    }

    drawImage (src, x, y) {
        var image = new Image();
        image.onload = () => {
            this.ctx.drawImage(image, x, y, 60, image.height * (60 / image.width));
        }
        image.src = src;
    }

    redraw () {
        var activities = this.deviceProfile.definition.activities;
        
        var startActivity = activities.find(a=>a.id === this.deviceProfile.definition.initialActivityId);
        var startNode = { 
            label: startActivity.label, 
            id: startActivity.id,
            type: startActivity.type, 
            activity: startActivity,
            children: []
        };
        
        this.recurse(startActivity, startNode, []);

        var tree = d3Tree()
	        .size([this.width, this.height]);
        
        this.$element.find('svg').remove();

        var svg = d3Select(this.$element[0]).append("svg")
            .attr("preserveAspectRatio", "xMinYMin meet")
            .attr('viewBox', '20 20 ' + this.width + ' ' + this.height);

        var g = svg.append("g")
            .attr("transform", "translate(20,60)");

        function diagonal(s, d) {
            var path = `M ${s.x} ${s.y}
                    C ${(s.x + d.x) / 2} ${s.y},
                    ${(s.x + d.x) / 2} ${d.y},
                    ${d.x} ${d.y}`

            return path;
        }

        var root = d3Hierarchy(startNode, d => d.children)
            .sort(function(a, b) { return (a.height - b.height) || a.data.id.localeCompare(b.data.id); });        

        var update = function (source) {
            var i = 0;
            var treeData = tree(root);

            var nodes = treeData.descendants(),
	            links = treeData.descendants().slice(1);

            nodes.forEach((d) => { d.y = d.depth * 150});
            
            var link = g.selectAll(".link")
	            .data(links)
                .enter().append("path", "g")
                .attr("class", "link")
                .attr("d", function(d) {
                    return "M" + d.x + "," + d.y
                        + "C" + (d.x + d.parent.x) / 2 + "," + d.y
                        + " " + (d.x + d.parent.x) / 2 + "," + d.parent.y
                        + " " + d.parent.x + "," + d.parent.y;
                });

            g.selectAll('path').sort((a,b) => {
                if (a.id != b.id) return -1;
                return 1;
            })

            var node = g.selectAll(".node")
	            .data(nodes)
                .enter()
                .append("g")
                .attr("class", "node")
                .attr("transform", function(d) { 
                    return "translate(" + (d.x - 40) + "," + (d.y - 20) + ")";
                })
                .on('mouseover', function (d) {
                        d3Select(this).select('rect').style("opacity", 1)
                    })
                .on('mouseout', function (d) {
                    d3Select(this).select('rect').style("opacity", 0);
                })
                .on('click', (d) => {
                    if (d.data.activity) {
                        this.$state.go('.screenEdit', {deviceProfileId: this.deviceProfile.id, activityId: d.data.id});
                    }
                })
            
            this.$timeout(()=> {
                node.append('rect')
                    .attr('x', -2)
                    .attr('y', -2)
                    .attr('rx', 10)
                    .attr('ry', 10)
                    .attr('width', 84)
                    .attr('height', 111)
                    .attr('class', 'hovereffect')
                    

                node.append("image")
                    .attr('width', 80)
                    .attr('href', (d) => screenTypes[d.data.type].thumb);

                node.append('rect')                
                    .attr('x', function(d, i, arr) { 
                        return 0;
                    })
                    .attr('width', function(d, i, arr) {
                        var box = arr[i].parentElement.getBoundingClientRect();
                        return box.width;
                    })
                    .attr('height', function(d, i, arr) {
                        return (d.children|| []).length ? 20 : 0;
                    })
                    .attr("y", function(d, i, arr) { 
                        var box = arr[i].parentElement.getBoundingClientRect();
                        return -24;
                    })
                    .attr('fill', 'white')
                    .attr('fill-opacity', 0.8);
                    

                node.append('text')                
                    .attr('x', function(d, i, arr) { 
                        var box = arr[i].parentElement.getBoundingClientRect();
                        return (box.width / 2) - 4;
                    })
                    .attr("y", function(d, i, arr) { 
                        var box = arr[i].parentElement.getBoundingClientRect();
                        return d.children || d._children ? -10 : box.height + 20; 
                    })
                    .attr("text-anchor", 'middle')                    
                    .text(function(d) { return d.data.label; });
            }, 100)
        }.bind(this)

        update (root);
    }

    recurse(activity, node, path, depth) {
        depth = depth || 0;
        if (depth === 20) {
            return;//max depth
        }

        var activities = this.deviceProfile.definition.activities;
        var defaultTargetId = activity.navigation ? activity.navigation.defaultTargetId : null;
        var ruleTargets = activity.navigation ? activity.navigation.rules.map(r=>r.targetId) : [];
        var targets = [];
        
        if (defaultTargetId) {
            let t = activities.find(a => a.id === defaultTargetId);
            if (t) {
                node.children.push({
                    parent: node,
                    defaultRef: true,
                    label: t.label, 
                    id: t.id,
                    type: t.type, 
                    activity: t,
                    children: []
                });
            }            
        }

        ruleTargets.forEach(r => {
            if (r) {
                let t = activities.find(a => a.id === r);
                if (t) {
                    if (path.indexOf(t) > -1) //recursion
                    {
                        node.children.push({
                            parent: node,
                            label: 'Recursion detected',
                            id: t.id,
                            type: 'recursion',
                            children: []
                        })
                    }
                    else {
                        node.children.push({
                            parent: node,
                            label: t.label, 
                            id: t.id,
                            type: t.type, 
                            activity: t,
                            children: []
                        });
                    }
                }
            }
        });

        node.children.forEach(c => {
            if (c.type !== 'recursion') {
                this.recurse(c.activity, c, path.slice(0), depth + 1)
            }
        });        
    }
}

DeviceProfileDiagramController.$inject = ['$element', '$scope', '$timeout', '$state'];

export default DeviceProfileDiagramController;