function error400Interceptor ($rootScope, $q, messages) {
	return {
		responseError: function (response) {
			if (response.status === 400) {
				if (response.data.message) {
                    messages.showWarningMessage(response.data.message, response.data.properties);
				}
			}

            return $q.reject(response);
		}
	}
}

error400Interceptor.$inject = ['$rootScope', '$q', 'messages'];

export default error400Interceptor;