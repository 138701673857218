class DataClassificationDetailController {
    constructor ($state, messages) {
        this.$state = $state;
        this.messages = messages;
    }

    $onInit () {
    }
}

DataClassificationDetailController.$inject = ['$state', 'messages'];

export default DataClassificationDetailController;