import controller from './paging-controller';
import template from './paging.html';
import './paging.scss';

function pagingDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            pageIndex: '=',
            pageCount: '='
        }
    }
}

export default pagingDirective;