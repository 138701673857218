class LoadClickController {
    constructor ($element, $scope) {
        this.$element = $element;
        this.$scope = $scope;
        $element.on('click', this.runClick.bind(this));
    }

    runClick () {
        if (this.loadClick) {
            this.$element.addClass('is-loading');
            var result = this.$scope.$eval(this.loadClick);
            result.then(() => this.$element.removeClass('is-loading'), () => this.$element.removeClass('is-loading'))
        }
    }
}

LoadClickController.$inject = ['$element', '$scope'];

export default LoadClickController;