class BreadcrumbController {
    constructor($state, $interpolate, $scope, $transitions) {
        this.$state = $state;
        this.$scope = $scope;
        this.$interpolate = $interpolate;

        $transitions.onSuccess({}, (transition) =>{
            this.buildTrail();
        });

        this.buildTrail();
    }


    buildTrail() {
        var current = this.$state.$current;
        var states = [];

        while (current != null && current.navigable) {
            if (!current.data.modal && !current.data.tab) {
                states.push({
                    name: current.self.name,
                    title: this.$interpolate(current.data.title)(this.$scope),
                    icon: current.data.icon,
                    overrideUrl: this.$interpolate(current.data.overrideUrl || '')(this.$scope)
                });
            }

            current = current.parent;
        }

        states.reverse();

        this.states = states;
    }
}

BreadcrumbController.$inject = ['$state', '$interpolate', '$scope', '$transitions'];

export default BreadcrumbController;
