import awesomeSvg from './awesome.svg';
import goodSvg from './good.svg';
import notGreatSvg from './notgreat.svg';
import awfulSvg from './awful.svg';

class CategoriesController {
    constructor () {
        this.images = {
            awesomeSvg,
            goodSvg,
            notGreatSvg,
            awfulSvg
        };
    }

    $onInit () {
        if (this.model && this.model.isNew) {
            if (this.model.data.isRandomized === undefined) {
                this.model.data.isRandomized = true;
            }
            if (!this.model.data.categories) {
                this.model.data.categories = [];
            }
            if (!this.model.data.smileyCaptions) {
                this.model.data.smileyCaptions = {
                    awesome: "Awesome",
                    good: "Good",
                    notGreat: "Not Great",
                    awful: "Awful"
                };
            }
        }
    }

    addCategory () {
        this.model.data.categories.push(Object.assign({}, this.category));
        this.category = {};
    }

    remove (category) {
        this.model.data.categories.splice(this.model.data.categories.indexOf(category), 1);

        if (this.model.navigation && this.model.navigation.rules) {
            var remove = [];
            this.model.navigation.rules.forEach(r => {
                if (r.or) {
                    if (r.or[category.key]) {
                        delete r.or[category.key];
                    }

                    if (Object.keys(r.or).length === 0) {
                        remove.push(r);
                    }
                }
            });

            remove.forEach(r => {
                this.model.navigation.rules.splice(this.model.navigation.rules.indexOf(r), 1);
            });
        }
    }
}

export default CategoriesController;