class DataClassificationIndexController {
    constructor ($http, $state, $scope, $stateParams) {
    }

    $onInit () {
    }
}

DataClassificationIndexController.$inject = ['$http', '$state', '$scope', '$stateParams'];

export default DataClassificationIndexController;