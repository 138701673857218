class PlaceDetailController {
  constructor($http, $state, $scope) {
    this.$http = $http;
    this.$state = $state;
    this.$scope = $scope;
  }

  $onInit(){
  }
}

PlaceDetailController.$inject = ['$http', '$state', '$scope'];

export default PlaceDetailController;