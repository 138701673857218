class ApplicationSettingEditController {
    constructor($http, $state, messages, $q) {
        this.$http = $http;
        this.$state = $state;
        this.messages = messages;
        this.$q = $q;
    }

    $onInit () {
        this.value = JSON.stringify(JSON.parse(this.setting.value), null, "\t");
    }

    submit() {
        this.loading = true;
        this.$http.put(`/rateit/api/system/values/${this.setting.fullName}`, { value: this.value }).then(() => {
            this.messages.showSuccessMessage(`The application setting has been updated.`);
            this.setting.value = this.value;
            this.$state.go('^');
            this.result.resolve(this.setting);
        }, () => this.loading = false);
    }
}

ApplicationSettingEditController.$inject = ['$http', '$state', 'messages', '$q'];

export default ApplicationSettingEditController;