import controller from './company-details-controller';
import template from './company-details.html';
import './company-details.scss';

function companyDetailsDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
          company: '=',
          isEditing: '='
        },
        scope: true
    }
}

export default companyDetailsDirective;