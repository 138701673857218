class CompanyUserEditController {
  constructor($http, $state, $scope, messages) {
    this.$http = $http;
    this.$state = $state;
    this.$scope = $scope;
    this.messages = messages;

    this.userTypes = [{
      name: 'Standard',
      value: 0
    }, {
      name: 'Super User',
      value: 1
    }, {
      name: 'Developer',
      value: 2
    }];

    this.remindPeriods = [];

    for (var i = 1; i < 24; ++i) {
      this.remindPeriods.push({ display: i + '', value: i });
    }

    this.offlineStartHours = this.createOfflineHours(true, false);
    this.offlineEndHours = this.createOfflineHours(false, true);
  }

  $onInit() {
    this.user.placeIds = this.user.accessiblePlaces.map(place => place.id);
    this.user.defaultCompanyId = this.user.accessibleCompanies.find(company => company.isDefault).id;
    this.notificationSettings = [
      { label: 'Rating with comment received', field: 'sendAllComments' },
      { label: 'Negative rating received', field: 'sendNegativeRatings' },
      { label: 'Daily Reports', field: 'enableDailyStats' },
      { label: 'Weekly Reports', field: 'enableWeeklyStats' }
    ]

    if (this.user.subscribeSettings) {
      this.user.subscribeSettings.offlineDeviceAlertRemindMeEvery = this.user.subscribeSettings.hasOwnProperty('offlineAlertReminderInterval');
      this.user.subscribeSettings.offlineDeviceAlertOnlyNotifyBetween = this.user.subscribeSettings.hasOwnProperty('offlineAlertStartHour');
    }

    this.user.lockoutEndDateLocal = this.user.lockoutEndDateUtc ? new Date(this.user.lockoutEndDateUtc.substr(0, 16) + "z") : null;

  }

  submit() {
    this.loading = true;
    var user = this.user;
    user.isSuperUser = user.accountType === 1;
    user.isDeveloper = user.accountType === 2;

    if (this.user.subscribeSettings) {
      if (!this.user.subscribeSettings.offlineDeviceAlertRemindMeEvery) {
        delete this.user.subscribeSettings.offlineAlertReminderInterval;
      }

      if (!this.user.subscribeSettings.offlineDeviceAlertOnlyNotifyBetween) {
        delete this.user.subscribeSettings.offlineAlertStartHour;
        delete this.user.subscribeSettings.offlineAlertEndHour;
      }
    }

    this.user.lockoutEndDateUtc = this.user.lockoutEndDateLocal ? this.user.lockoutEndDateLocal.toISOString() : null;

    this.$http.put(`/rateit/api/companies/${this.company.id}/users/${user.id}`, user).then(() => {
      this.messages.showSuccessMessage(`The portal user has been updated.`);
      this.$state.go('^', null, { reload: true });
    }, () => this.loading = false);
  }

  togglePlace(placeId) {
    if (this.user.accountType !== 0) {
      return;
    }

    var index = this.user.placeIds.indexOf(placeId);

    if (index === -1) {
      this.user.placeIds.push(placeId);
    } else {
      this.user.placeIds.splice(index, 1);
    }
  }

  toggleAllPlaces(isAll) {
    if (this.user.accountType !== 0) {
      return;
    }

    if (isAll) {
      this.user.placeIds = this.places.map(p => p.id);
    } else {
      this.user.placeIds = [];
    }
  }

  createOfflineHours(includeMidnightToday, includeMidnightTomorrow) {
    var arr = [];
    for (var hour = 0; hour < 25; hour++) {
      var amPm = hour >= 12 && hour < 24 ? 'PM' : 'AM';

      var twelveHour = hour;
      if (hour === 0 || hour === 24) {
        twelveHour = 12;
      }
      else if (hour >= 13) {
        twelveHour = hour - 12;
      }

      arr.push({
        value: hour,
        label: twelveHour + ' ' + amPm
      });
    }

    if (!includeMidnightToday) {
      arr.shift();
    }

    if (!includeMidnightTomorrow) {
      arr.pop();
    }

    return arr;
  }
  companyChanged() {
    this.user.defaultCompanyName = this.user.accessibleCompanies.find(company => company.id == this.user.defaultCompanyId).name;
  }
}

CompanyUserEditController.$inject = ['$http', '$state', '$scope', 'messages'];

export default CompanyUserEditController;