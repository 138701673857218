import controller from './router-progress-controller';
import template from './router-progress.html';
import './router-progress.scss';

function routerProgressDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {}
    }
}

export default routerProgressDirective;