function authHttpInterceptor ($window, $q, $injector, $httpParamSerializerJQLike, $location) {
	var inflightAuthRequest = null;
	return {		
		responseError: function (originalResponse) {
			if (originalResponse.config.apiRequest && !originalResponse.config.authRequest) {
				switch (originalResponse.status){
					case 401:
						var $http = $injector.get('$http');
						var refreshToken = $window.localStorage.getItem('refreshToken');
						if (!refreshToken) {
							$location.path('/login');
							break;
						}

						var deferred = $q.defer();

						if (!inflightAuthRequest) {
							var headers = {'Content-Type': 'application/x-www-form-urlencoded'};
							inflightAuthRequest = $http({
								authRequest: true,
							    method: 'POST',
							    url: '/token',
								headers: headers,
								data: $httpParamSerializerJQLike({refresh_token: refreshToken, grant_type: 'refresh_token'})
							});
						}
						else {

						}

						inflightAuthRequest.then((response) => {
							inflightAuthRequest = null;
							var data = response.data;
							var token = data['access_token'];
							var refreshToken = data['refresh_token'];

							$window.localStorage.setItem('token', token);
							$window.localStorage.setItem('refreshToken', refreshToken);

							$http(originalResponse.config)
								.then((r) => {
									deferred.resolve(r);
								}, (r) => {
									deferred.reject(r);
								});
						}, (err) => {
							
							inflightAuthRequest = null;
							deferred.reject(originalResponse);
							if (err.status === 401 || err.status === 400) {
								$window.localStorage.removeItem('token');
								$window.localStorage.removeItem('refreshToken');
								$location.path('/login');
							}
						});

						return deferred.promise;
				}
			}
						
			return $q.reject(originalResponse);
		}
	};	
}

authHttpInterceptor.$inject = ['$window', '$q', '$injector', '$httpParamSerializerJQLike', '$location'];

export default authHttpInterceptor;