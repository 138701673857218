class WebActionController {
    constructor ($scope) {
        this.i18nModel = {};
        this.$scope = $scope;
    }

    $onInit () {
    }

    addButton (type) {
        var buttonList = this.model.webActions[type] || (this.model.webActions[type] = []);
        buttonList.push({});
    }

    removeButton (list, button) {
        list.splice(list.indexOf(button), 1);
    }

    imageChange (button, value) {
        button.imageUrl = value;
    }
}

WebActionController.$inject = ['$scope'];

export default WebActionController;