class CompanySettingsController {
    constructor($http, $state, $scope) {
        this.$http = $http;
        this.$state = $state;
        this.$scope = $scope;

        this.$scope.validationRules.sort((r1, r2) => {
            return this.isRuleEnabled(r2) - this.isRuleEnabled(r1);
        });
    }

    isRuleEnabled(rule) {
        return this.$scope.company.companyRules.some(cr => {
            return cr.validationRuleId === rule.id;
        });
    }
}

CompanySettingsController.$inject = ['$http', '$state', '$scope'];

export default CompanySettingsController;