function tabInputDirective () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $element, $attrs, ngModel) {
            $element.on('keydown', (ev) => {
                if (ev.key === "Tab") {
                    ev.preventDefault();
                    var start = $element[0].selectionStart;
                    var end = $element[0].selectedEnd || start;
                    var value = $element.val();

                    $scope.$apply(() => {                        
                        if (start === end && start === value.length) {
                            newValue = value + '\t';
                            $element.val(newValue);
                            ngModel.$viewValue = newValue;
                        }
                        else {
                            var newValue = value.substring(0, start) + '\t' + value.substring(end);
                            $element.val(newValue);
                            ngModel.$viewValue = newValue;
                            if (start !== newValue.length) {
                                $element[0].selectionStart = $element[0].selectionEnd = start + 1;
                            }
                        }
                    });                   
                }
            })
        }        
    }
}

export default tabInputDirective;