import angular from 'angular';

class DeviceProfileExportController {
    constructor($state, $http, configuration) {
        this.$state = $state;
        this.$http = $http;
        this.configuration = configuration;
    }

    submit() {
        this.$http.get(`/rateit/api/device-profiles/${this.deviceProfile.id}/export`)
            .then(x => {
                var blob = new Blob([JSON.stringify(x.data, null, 2)], {
                    type: 'application/json'
                });

                const data = window.URL.createObjectURL(blob);
                var link = document.createElement('a');

                // Add the element to the DOM so that it works in Firefox. See https://stackoverflow.com/a/32226068/5380.
                link.setAttribute("type", "hidden");
                document.body.appendChild(link);

                link.href = data;
                link.download = `${this.deviceProfile.name}-v${this.deviceProfile.revision}.json`;
                link.click();
                link.remove();

                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                    this.$state.go('^');
                }, 100);
            });
    }
}

DeviceProfileExportController.$inject = ['$state', '$http', 'configuration'];

export default DeviceProfileExportController;