class DataClassificationAddEditController {
    constructor($scope, $http, $state, messages, $q) {
        this.$scope = $scope;
        this.$http = $http;
        this.$state = $state;
        this.messages = messages;
        this.$q = $q;

    }

    $onInit() {
        this.isNew = !this.$scope.classification;

        if (!this.$scope.classification) {
            this.$scope.classification = {
                children: []
            };
        }
        else {
            //get the child key parts from the key
            this.$scope.classification.children.forEach(child => {
                child.keyPart = child.key.split('_')[1];
            });
        }

        this.classification = this.$scope.classification;

        this.$scope.$watch(() => this.classification.key, (newVal, oldVal) => {
            if (newVal === oldVal) {
                return;
            }

            //update the child keys
            this.classification.children.forEach(child => {
                child.key = newVal + '_' + child.keyPart;
            });
        });
    }

    submit() {
        this.loading = true;

        this.classification.key = this.classification.key.toUpperCase();
        this.classification.children.forEach(child => {
            child.key = child.key.toUpperCase();
        });

        var request;
        if (this.isNew) {
            request = this.$http.post(`/rateit/api/data-classifications`, this.classification);
        }
        else {
            request = this.$http.put(`/rateit/api/data-classifications/${this.classification.id}`, this.classification);
        }

        request.then(() => {
            this.loading = false
            if (this.isNew) {
                this.messages.showSuccessMessage(`The data classification has been created.`);
            }
            else {
                this.messages.showSuccessMessage('The data classification has been updated.');
            }
            this.$state.go('^', undefined, { reload: true });
        }, (error) => {
            this.loading = false
            if (error.status >= 400 && error.status < 500) {
                this.messages.showErrorMessage(error.data.message);
            }
            else {
                this.messages.showErrorMessage("A server error occurred. Please try again or let the Product team know.");
            }
        });
    }

    addChild() {
        var child = this.editingChild || {};

        child.key = this.classification.key + '_' + this.childKey;
        child.keyPart = this.childKey;
        child.label = this.childLabel;

        if (!this.editingChild) {
            this.classification.children.push(child);
        }

        this.closeChildEdit();
    }

    editChild(child) {
        this.childKey = child.keyPart;
        this.childLabel = child.label;

        this.showChildEdit = true;
        this.editingChild = child;
    }

    deleteChild(child) {
        if (confirm('Are you sure you want to delete this child classification?')) {
            var index = this.classification.children.indexOf(child);
            if (index >= 0) {
                this.classification.children.splice(index, 1);
            }
        }
    }

    closeChildEdit() {
        this.childKey = undefined;
        this.childLabel = undefined;
        this.showChildEdit = false;
        this.editingChild = undefined;
    }

    onKeyKeyDown(event) {
        var regex = /^[A-Za-z]+$/;
        if (regex.test(event.key)) {
            return;
        }
        else {
            event.preventDefault();
        }
    }
}

DataClassificationAddEditController.$inject = ['$scope', '$http', '$state', 'messages', '$q'];

export default DataClassificationAddEditController;