class DeviceProfileAddStep1Controller {
    constructor($http, $state) {
        this.$http = $http;
        this.$state = $state;
        this.companyId = Number(this.$state.params.companyId);
        this.i18nModel = {};
        this.loadDeviceProfiles = (query) => {
            return this.$http.get(`/rateit/api/device-profiles?options.query=${query}&options.excludeDefinition=true`)
                .then(r => {
                    return r.data.results;
                });
        }
        this.companies = this.$http.get('/rateit/api/companies')
            .then(r => {
                if (this.companyId) {
                    this.companies = r.data.results.filter(c => c.id === this.companyId);
                    this.model.companyId = this.companyId;
                } else {
                    this.companies = r.data.results;
                }
            })
    }

    submit() {

    }

    valueTransform(dp) {
        return dp.id;
    }

    displayTransform(dp) {
        return `${dp.name} v${dp.revision} - ${dp.company.name}`;
    }

    addLanguage() {
        var key = `${this.i18nModel.codeLeft.toLowerCase()}-${this.i18nModel.codeRight.toUpperCase()}`;
        var existingDefinition = this.model.languages.find(x => x.key.toLowerCase() === key.toLowerCase());
        if (existingDefinition) {
            return;
        }

        var language = {
            key: key,
            name: this.i18nModel.name,
            translations: {}
        };

        this.model.languages.push(language);
        this.i18nModel = {};
    }

    removeLanguage(language) {
        var index = this.model.languages.indexOf(language);
        this.model.languages.splice(index, 1);
    }

    moveUp(language) {
        var index = this.model.languages.indexOf(language);
        this.model.languages.splice(index, 1);
        this.model.languages.splice(index - 1, 0, language);
    }

    moveDown(language) {
        var index = this.model.languages.indexOf(language);
        this.model.languages.splice(index, 1);
        this.model.languages.splice(index + 1, 0, language);
    }
}

DeviceProfileAddStep1Controller.$inject = ['$http', '$state'];

export default DeviceProfileAddStep1Controller;