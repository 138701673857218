function numericValidator () {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.numeric = function compare (modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty models to be valid
          return true;
        }

        if (!isNaN(viewValue)) {
          if (attrs.numeric) {            
            let max = parseInt(attrs.numeric);
            if (parseInt(viewValue) > max) {
              return false;
            }

            return true;
          }
          else {
            return true;
          }
        }
        return false
      }      
    }
  };
}

export default numericValidator;
