class RouterProgressController {
    constructor($scope, $transitions, $timeout) {
        this.$scope = $scope;
        this.$timeout = $timeout;

        this.value = 0;
        this.hasError = false;

        $transitions.onBefore({}, transition => {
            this.hasError = false;
            this.value = 25;
        });
        $transitions.onStart({}, transition => {
            this.value = 50;
        });
        $transitions.onFinish({}, transition => {
            this.value = 75;
        });
        $transitions.onSuccess({}, transition => {
            this.value = 100;

            $timeout(() => this.value = 0, 500);
        });
        $transitions.onError({}, transition => {
            this.value = 100;
            this.hasError = true;

            $timeout(() => this.value = 0, 500);
        });
    }
}

RouterProgressController.$inject = ['$scope', '$transitions', '$timeout'];

export default RouterProgressController;