import controller from './company-users-controller';
import template from './company-users.html';
import './company-users.scss';

function companyUsersDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            companyId: '<',
            users: '<'
        }
    }
}

export default companyUsersDirective;