import controller from './rating-delete-one-controller';
import template from './rating-delete-one.html';

function ratingDeleteOneDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            rating: '<',
            ratings: '<'
        }
    }
}

export default ratingDeleteOneDirective;