function minValidator () {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.min = function compare (modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty models to be valid
          return true;
        }

        try {
            if (parseInt(viewValue) >= parseInt(attrs.min))
                return true;

            return false;
        }
        catch (m) {
            return false;
        }
      }      
    }
  };
}

function maxValidator () {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.max = function compare (modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty models to be valid
          return true;
        }

        try {
            if (parseInt(viewValue) <= parseInt(attrs.max))
                return true;

            return false;
        }
        catch (m) {
            return false;
        }
      }      
    }
  };
}

export {minValidator, maxValidator}
