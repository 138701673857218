import ajv from 'ajv';

class DeviceProfileDetailV2Controller {
    constructor($state, $http, $rootScope, messages, $scope) {
        this.$state = $state;
        this.$http = $http;
        this.messages = messages;
        this.errorMessage = "";
        this.$rootScope = $rootScope;
        this.model = $scope.model;
        this.model.definition = JSON.stringify(
            typeof this.model.definition === 'string' ? JSON.parse(this.model.definition) : this.model.definition,
            undefined,
            2
        );

        this.ajv = new ajv();
    }

    $onInit() {
        this.$http.get(`/rateit/api/device-profiles/v2/json-schema`).then((r) => {
            this.ajv.addSchema(r.data);
        });

        var getErrorsText = (errors) => {
            if (!errors) {
                return 'No errors';
            }

            var uniqueErrors = {};
            var text = '';
            var separator = '\n';

            for (var i = 0; i < errors.length; i++) {
                var e = errors[i];
                var errorText = '';

                if (e) {
                    errorText += e.dataPath.slice(1) + ' ' + e.message;

                    if (e.params.allowedValue) {
                        errorText += ' (' + e.params.allowedValue + ')';
                    }

                    errorText += separator;
                }

                if (!uniqueErrors.hasOwnProperty(errorText)) {
                    text += errorText;
                    uniqueErrors[errorText] = true;
                }
            }

            return text.slice(0, -separator.length);
        };
        this.getErrorsText = getErrorsText;
    }

    validationJson() {
        try {
            // Pull out the MicroSurvey sub-schema from the full inbound schema because that's what we actually want
            // to validate against (we don't want the user entering a full configuration object here - just a MS).
            const schema = this.ajv.getSchema('https://rateitapp.com/schemas/configuration_model_v2_inbound.json#/definitions/micro_survey');

            if (!schema) {
                throw 'could not obtain schema';
            }

            const jsonParse = JSON.parse(this.model.definition);
            schema.errors = null;
            const valid = schema(jsonParse);
            this.errorMessage = "";
            let activitiesListUI = this.model.definition;
            if (!valid) {
                this.errorMessage = this.getErrorsText(schema.errors);
                activitiesListUI = null
            }
            this.$rootScope.$broadcast('addActivitiesListUIByJson', {
                activitiesListUI: activitiesListUI,
                errorMessage: this.errorMessage
            });
        } catch (e) {
            this.errorMessage = "Error parsing schema";

            this.$rootScope.$broadcast('addActivitiesListUIByJson', {
                activitiesListUI: null,
                errorMessage: this.errorMessage
            });
        }
    }
}

DeviceProfileDetailV2Controller.$inject = ['$state', '$http', '$rootScope', 'messages', '$scope'];

export default DeviceProfileDetailV2Controller;
