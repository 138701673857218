class RatingToggleValidityController {
    constructor ($http, $scope, $state, messages) {
        this.$http = $http;
        this.$scope = $scope;
        this.$state = $state;
        this.messages = messages;
    }

    $onInit () {
    }

    toggle () {
        this.loading = true;

        this.$http.post(`/rateit/api/companies/${this.rating.place.companyId}/places/${this.rating.place.id}/ratings/${this.rating.deviceRatingId}/validation-result/35cc04eb-7c2c-40f2-8b18-113e3628ae80`, {
                deviceRatingId: this.rating.deviceRatingId,
                validationRuleId: '35cc04eb-7c2c-40f2-8b18-113e3628ae80',
                meta: {
                    notes: this.notes,
                    positive: !this.rating.isValid
                }
            })
            .then((response) => {
                var data = response.data;
                this.rating.isValid = data.isValid;
                if (data.overrideValidationRuleId) {
                    this.rating.overrideValidationRuleId = data.overrideValidationRuleId;
                    this.rating.overrideValidationRuleName = data.overrideValidationRuleName;
                    this.rating.overrideDateTime = data.overrideDateTime;
                    this.rating.overrideSetBy = data.overrideSetBy;
                    this.rating.overrideValidationNotes = data.overrideValidationNotes;
                }

                this.messages.showSuccessMessage(`Rating validity was successfully changed.`, null, -1);
                this.$state.go('^');
            })
            .catch(()=>{this.loading = false});
    }
}

RatingToggleValidityController.$inject = ['$http', '$scope', '$state', 'messages'];

export default RatingToggleValidityController;