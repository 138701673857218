function isolateFormDirective () {
    return {
        restrict: 'A',
        require: ['form', '^form'],
        link: function(scope, element, attrs, forms) {
            forms[1].$removeControl(forms[0]);
        }
    }
}

export default isolateFormDirective;