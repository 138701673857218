import controller from './load-click-controller';

function loadClickDirective () {
    return {
        controller,		
        restrict: 'A',
        bindToController: {
            loadClick: '@'
        }
    }
}

export default loadClickDirective;