import controller from './nps-controller';
import template from './nps.html';

function npsDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            model: '=',
            deviceProfile: '='
        },
        scope: true
    }
}

export default npsDirective;