import controller from './rating-search-controller';
import template from './rating-search.html';
import './rating-search.scss';

function ratingSearchDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            ratings: '=',
            companyId: '<',
            placeId: '<?'
        }
    }
}

export default ratingSearchDirective;