import uuidV4 from 'uuid/v4';
import ActivityHelper from './activity';

class DeviceProfileEditController {
    constructor ($state, $http, messages) {        
        this.$state = $state;
        this.$http = $http;
        this.messages = messages;

        this.wizardOptions = {
            screens: [
                { 
                    state: 'ri.admin.secure.deviceProfiles.detail.edit.step1',
                    onexit: () => {

                        this.$http.get('/rateit/api/device-profiles/global-keys')
                            .then(r => {
                                var keys = r.data;
                                this.deviceProfile.definition.languages.forEach(l => {
                                    var isDefault = l.key === 'en-US';
                                    keys.forEach(k => {
                                        l.translations[k] = l.translations[k] || (isDefault ? k : null);
                                    });                                
                                });
                            });

                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.edit.step2']);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.detail.edit.step2',
                    onexit: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.edit.step3']);
                    },
                    onback: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.edit.step1']);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.detail.edit.step3',
                    onexit: () => {
                        return this.save();
                    },
                    onback: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.edit.step2']);
                    }
                }
            ]
        };
    }

    $onInit() {
        this.deviceProfile = angular.copy(this.model);
    }

    save () {
        return this.$http.put(`/rateit/api/device-profiles/${this.deviceProfile.id}`, this.deviceProfile)
            .then(r => {
                this.model.name = r.data.name;
                this.model.definition.skipType = r.data.definition.skipType;
                this.model.definition.skipCount = r.data.definition.skipCount;
                this.model.definition.languages = r.data.definition.languages;
                this.model.definition.finishTargetId = r.data.definition.finishTargetId;
                this.model.definition.webActions = r.data.definition.webActions;
                
                var languageKeys = this.deviceProfile.definition.languages.map(x=>x.key);
                this.deviceProfile.definition.activities.forEach(a => {                        
                    var removedLanguages = a.languages.filter(l=> languageKeys.every(x=> x != l.key));
                    removedLanguages.forEach(l => {
                        a.languages.splice(a.languages.indexOf(l), 1);
                    });
                });
                if (r.data.validationWarnings && r.data.validationWarnings.length) {
                    this.messages.showWarningMessage(`The device profile was successfully updated. However there were some validation errors.`, r.data.validationWarnings.map(m=> {return {errorMessage:m}}), -1);
                }
                else {
                    this.messages.showSuccessMessage(`The device profile was successfully updated.`, null, -1);
                }
                return ['ri.admin.secure.deviceProfiles.detail', {deviceProfileId: this.deviceProfile.id}];
            });   
    }
}

DeviceProfileEditController.$inject = ['$state', '$http', 'messages'];

export default DeviceProfileEditController;