class RatingSearchController {
    constructor($http, SearchModel, $state, $scope, authContext, $document) {
        this.loading = true;
        this.$http = $http;
        this.$state = $state;
        this.$scope = $scope;
        this.authContext = authContext;
        this.loading = true;
        this.$document = $document;
        this.blur = true;

        this.listenForBlurKeys();
    }

    $onInit() {
        var claimVal = `${this.companyId}|${this.placeId || '*'}`;

        //check to see if we have the correct claim to manage validation
        this.canValidate = this.authContext.hasClaim('Ra.Va', claimVal);
        this.canManage = this.authContext.hasClaim('Ra.Ad', claimVal);
        this.canViewFaces = this.authContext.hasClaim('Ra.Fa_Vi', claimVal);

        this.items = this.ratings.items;

        this.$http.get('/rateit/api/countries')
            .then(xhr => {
                this.countries = xhr.data.results;
            });

        if (!this.placeId) {
            this.$http.get(`/rateit/api/companies/${this.companyId}/places`)
                .then(xhr => {
                    this.places = xhr.data;
                });
        }

        if (this.placeId) {
            this.$http.get(`/rateit/api/companies/${this.companyId}/places/${this.placeId}/device-registrations`)
                .then(xhr => {
                    this.registrations = xhr.data;
                });
        }
        else {
            this.$http.get(`/rateit/api/companies/${this.companyId}/device-registrations`)
                .then(xhr => {
                    this.registrations = xhr.data;
                });
        }

        this.$http.get(`/rateit/api/companies/${this.companyId}/staff-profiles`)
            .then(xhr => {
                this.staffProfiles = xhr.data.reduce((arr, v) => {
                    arr[v.id] = v;
                    return arr
                }, {});
            });

        this.$http.get(`/rateit/api/device-profiles?companyId=${this.companyId}&includeHistory=true&minRatings=1`)
            .then(xhr => {
                this.deviceProfiles = xhr.data.results.reduce((arr, v) => {
                    v.definition.activities = v.definition.activities.reduce((arr, a) => {
                        arr[a.id] = a;

                        if (a.type.toLowerCase() === 'categories') {
                            a.data.categories = a.data.categories.reduce((arr, v) => {
                                arr[v.key] = v;
                                return arr;
                            }, {})
                        }

                        if (a.type.toLowerCase() === 'multichoice') {
                            a.data.options = a.data.options.reduce((arr, v) => {
                                arr[v.id] = v;
                                return arr;
                            }, {})
                        }

                        if (a.type.toLowerCase() === 'form') {
                            a.data.fields = a.data.fields.reduce((arr, v) => {
                                arr[v.id] = v;
                                return arr;
                            }, {})
                        }

                        return arr;
                    }, {});

                    arr[v.id] = v;
                    return arr;
                }, {});

                this.loading = false;
            });
    }

    reset() {
        Object.keys(this.ratings.search.filters).forEach(key => {
            if (this.placeId && key === 'Place.Id') {
                return;
            }

            this.ratings.search.filters[key].value = undefined;
        });

        this.ratings.search.model.capturedAtEnd = undefined;
    }

    refresh() {
        this.ratings.search.apply();
    }

    groupMcData(deviceProfileId, responses) {
        return responses.reduce((agg, v) => {
            var activity = this.deviceProfiles[deviceProfileId].definition.activities[v.activityId].data;
            var option = activity.options[v.selectedOptionId];

            if (!agg[v.activityId]) {
                agg[v.activityId] = {
                    insightsTitle: activity.insightsTitle,
                    responses: []
                }
            }

            agg[v.activityId].responses.push({
                imageUrl: option.imageUrl,
                isOther: option.isOther,
                caption: option.isOther ? v.other : option.caption
            });

            return agg;
        }, {});
    }

    highlight(rating) {
        let urlBase = `/rateit/api/apps/admin/ratings/${rating.id}/`;
        const targetHighlight = !rating.isHighlighted;

        if (targetHighlight) {
            urlBase += 'sethighlight';
        } else {
            urlBase += 'removehighlight';
        }

        rating.loading = true;
        this.$http.put(urlBase)
            .then((request) => {
                rating.isHighlighted = targetHighlight;
                rating.loading = false;
            })
            .catch(() => { rating.loading = false });
    }

    listenForBlurKeys() {
        var body = angular.element(this.$document[0].querySelector('body'));
        this.keyBuffer = [];

        var keyDown = ev => {
            this.keyBuffer.push(ev.keyCode);

            var codes = {};

            this.keyBuffer.forEach(function (code) {
                codes['key_' + code] = 1;
            });

            if (codes.key_17 && codes.key_18 && codes.key_66) {//ctrl+shift+b
                this.blur = false;
            }
        }

        var keyUp = ev => {
            this.keyBuffer = [];
        }

        body.on('keydown', keyDown);
        body.on('keyUp', keyUp);
    }
}

RatingSearchController.$inject = ['$http', 'SearchModel', '$state', '$scope', 'authContext', '$document', '$element', '$timeout'];

export default RatingSearchController;