function error500Interceptor ($rootScope, $q, messages) {
	return {
		responseError: function (response) {
			if (response.status === 500) {
				if (response.data.message && response.data.properties) {
                    messages.showErrorMessage(response.data.message, response.data.properties);
				}
			}

            return $q.reject(response);
		}
	}
}

error500Interceptor.$inject = ['$rootScope', '$q', 'messages'];

export default error500Interceptor;