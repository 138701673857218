class AdminUserAddController {
    constructor ($http, messages, $state) {
        this.$http = $http;
        this.$state = $state;
        this.messages = messages;

        this.model = {
            claims: [],
            enableOtp: true
        };
    }

    $onInit () {
        this.$http.get('/rateit/api/apps/admin/users/claimsets')
            .then(r=> {
                this.claimSets = r.data;
            });
    }

    claimCompare(claim) {
        return this.model.claims.indexOf(claim) > -1;        
    }

    toggleClaim(set) {
        var claimSet = set.claims;
        var on = claimSet.every(this.claimCompare, this);
        if (on) {
            claimSet.forEach(c => { 
                var currentIndex = this.model.claims.indexOf(c);
                if (currentIndex > -1) {
                    this.model.claims.splice(currentIndex, 1);
                }
            });

            (set.children || []).forEach(s => { 
                s.claims.forEach(c => {
                    var currentIndex = this.model.claims.indexOf(c);
                    if (currentIndex > -1) {
                        this.model.claims.splice(currentIndex, 1);
                    }
                });                
            });            
        }
        else {
            claimSet.forEach(c => { 
                this.model.claims.push(c);
            });
        }
    }

    submit () {
        this.loading = true;
        var model = Object.assign({}, this.model);
		model.claims = [];
		this.model.claims.forEach((x) => {
			var pair = x.split('=');
			model.claims.push({name: pair[0], value: pair[1]});
		});

		this.$http.post('/rateit/api/apps/admin/users', model)
			.then((response) => {
				var data = response.data;
				if (data.credentialAlreadyExists) {
                    this.messages.showSuccessMessage(`A user with that username already exists. The administrator account has been created. The display name and password have not been modified.
                    The 2FA authentication key is ${data.otpKey}.`, null)
				}
                else {
                    this.messages.showSuccessMessage(`The administrator account has been created.
                    The 2FA authentication key is ${data.otpKey}.`, null);
                }

                if (this.$state.is('ri.admin.secure.adminUsers.add')) {
                    this.$state.go('^.detail', {username: model.username}, {reload: true});
                }
			}, (err) => {
                this.loading = false;
            });
    }
}

AdminUserAddController.$inject = ['$http', 'messages', '$state'];

export default AdminUserAddController;