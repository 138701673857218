class ModalWizardController {
    constructor ($state, $scope) {
        this.$state = $state;
        this.$scope = $scope;
    }

    $onInit () {
        this.index = -1;
        this.isLast = this.config.isLast || false;
        this.config.screens.forEach((x, i) => {
            if (this.$state.is(x.state)) {
                this.index = i;
                this.canBack = !!x.onback;
            }
        });
    }

    back () {
        this.loading = true;

        this.config.screens[this.index].onback().then((r) => {
            this.config.screens.forEach((x, i) => {
                if (r[0] === x.state) {
                    this.index = i;
                    this.canBack = !!x.onback;                    
                }
            });

            this.isLast = false;

            if (this.index === (this.config.screens.length -1) || r[2] && r[2] === true) {
                this.isLast = true;
            }           

            this.$scope.form.$setPristine();
            this.loading = false;

            this.$state.go(r[0], r[1]);
        }, () => this.loading = false);
    }

    submit () {
        this.loading = true;

        this.config.screens[this.index].onexit().then((r) => {
            this.config.screens.forEach((x, i) => {
                if (r[0] === x.state) {
                    this.index = i;
                    this.canBack = !!x.onback;                    
                }
            });

            this.isLast = false;

            if (this.index === (this.config.screens.length -1) || r[2] && r[2] === true) {
                this.isLast = true;
            }           

            this.$scope.form.$setPristine();
            this.loading = false;

            this.$state.go(r[0], r[1], r[3] || {});
        }, () => this.loading = false);
    }
}

ModalWizardController.$inject = ['$state', '$scope'];

export default ModalWizardController;