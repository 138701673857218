import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uicommon from '../ui-common/module';
import loginDirective from './login-directive';
import headerTemplate from './hero.html';

export default angular.module('ri.admin.login', [uicommon.name, uiRouter])
    .directive('login', loginDirective)
    .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
            .state('ri.admin.public.login', {
                url: '^/login',
                views: {
                    'hero@ri.admin.public': {
                        template: headerTemplate
                    },
                    'content@ri.admin.public': {
                        template: '<login></login>'
                    }
                }
            });
    }]);