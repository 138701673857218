import controller from './messages-controller';
import template from './messages.html';
import './messages.scss';

function messagesDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {overrideMessages: '@'},
        require: ['^^messagesBoundary', 'messages'],
        scope: {},
        link: function (scope, element, attrs, messages) {
            var parentMessages = messages[0];
            var controller = messages[1];
            
            controller.parentController = parentMessages;
        }
    }
}

export default messagesDirective;
