import controller from './data-classification-detail-controller';
import template from './data-classification-detail.html';
import './data-classification-detail.scss';

function dataClassificationDetailDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            classification: '='
        }
    }
}

export default dataClassificationDetailDirective;