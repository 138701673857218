import template from './input-file.html';

function inputFileDirective () {
    return {
        template,
        restrict: 'E',
        require: 'ngModel',
        scope: {hidefilename: "@"},
        controller: function (){},
        controllerAs: 'vm',
        bindToController: {
            accept: '=?',
            required: '=?ngRequired'
        },
        link: function(scope, element, attrs, ngModel) {
            scope.ngModel = ngModel;
            scope.ngModel.hidefilename = attrs.hidefilename;
            scope.clear = () => {
                ngModel.$setViewValue(null);
                scope.fileName = null;
            };
            
            scope.$watch(() => ngModel.$viewValue, (n) => {
                if (!n || n.indexOf('data:') !== 0) {
                    scope.fileName = setFileName(n);
                }
                //firstWatch();
            });

            element.find('input').bind("change", function (changeEvent) {
                var reader = new FileReader();
                reader.onload = function (loadEvent) {
                    scope.$apply(function () {                        
                        ngModel.$setViewValue(loadEvent.target.result, 'change');
                    });
                }
                if (changeEvent.target.files[0]) {
                    scope.fileName = setFileName(changeEvent.target.files[0].name);
                    reader.readAsDataURL(changeEvent.target.files[0]);
                }
                
                element.find('input').val('');                
            });

            function setFileName (path) {
                if (path) {
                    if (path.lastIndexOf('/') > -1) {
                        path = path.substring(path.lastIndexOf('/')+1);
                    }
                    if (path.lastIndexOf('\\') > -1) {
                        path = path.substring(path.lastIndexOf('\\')+1);
                    }
                    if (path.indexOf('?') > -1){
                        path = path.substring(0, path.indexOf('?'));
                    }
                }
                return path;
            }
        }
    }
}

export default inputFileDirective;