import uuidV4 from 'uuid/v4';

class FormController {
    constructor() {}

    $onInit() {
        if (this.model && this.model.isNew) {
            this.model.data.title = this.model.data.title || '[Title describing what we\'d like the user to enter]';
            this.model.data.subtitle = this.model.data.subtitle || '[A subtitle about why we want this data]';
            this.model.data.nextCaption = this.model.data.nextCaption || 'All done';

            if (!this.model.data.fields) {
                //we prepopulate with a single field
                this.model.data.fields = [{
                    id: uuidV4(),
                    label: '[My field]',
                    fieldType: 'Text',
                    placeholderCaption: '[My value is...]'
                }];
            }
        }
    }
}

export default FormController;