class MultiChoiceSetExistingController {
    constructor($http, $scope, messages) {
        this.$http = $http;
        this.$scope = $scope;
        this.messages = messages;

        this.loading = false;
        this.filterConfigured = 'All';
        this.pageSize = 250;
    }

    $onInit() {
        this.fullOptions = this.mcOptions;

        this.resetPaging();

        this.$scope.$watch(() => this.searchQuery, (newVal, oldVal) => {
            if (newVal === oldVal || (newVal && newVal.length < 3)) {
                return;
            }

            this.filterOptions();
        });

        this.$scope.$watch(() => this.filterConfigured, (newVal, oldVal) => {
            if (newVal === oldVal) {
                return;
            }

            this.filterOptions();
        });
    }

    filterOptions() {
        this.loading = true;
        var optionsToSet = this.fullOptions;

        if (this.searchQuery) {
            var testVal = this.searchQuery.toLowerCase();
            optionsToSet = optionsToSet.filter(mcOpt => {
                return mcOpt.title.toLowerCase().includes(testVal) ||
                    mcOpt.insightsTitle.toLowerCase().includes(testVal) ||
                    mcOpt.caption.toLowerCase().includes(testVal);
            });
        }

        switch (this.filterConfigured) {
            case 'Configured':
                optionsToSet = optionsToSet.filter(mcOpt => !!mcOpt.dataClassificationId);
                break;
            case 'NotConfigured':
                optionsToSet = optionsToSet.filter(mcOpt => !mcOpt.dataClassificationId);
                break;
            default:
                break;
        }

        this.mcOptions = optionsToSet;
        this.resetPaging();
        this.loading = false;
    }

    setOptionClassification(mcOption) {
        mcOption.isSaving = true;
        mcOption.showError = false;
        mcOption.showSuccess = false;

        delete mcOption.errorMessage;
        this.$http.post('/rateit/api/data-classifications/multi-choice-options', mcOption)
            .then(r => {
                mcOption.isSaving = false;
                mcOption.showSuccess = true;
            })
            .catch(error => {
                mcOption.isSaving = false;
                mcOption.showError = true;
                mcOption.dataClassificationId = undefined;
                mcOption.errorMessage = error.message || 'An unknown error occurred. Please wait a minute and try again.';
            });
    }

    resetPaging() {
        var totalCount = this.mcOptions.length;
        this.maxPage = Math.ceil(totalCount / this.pageSize);
        this.setPage(1, true);
    }

    calculatePages() {
        var pages = [];
        if (this.maxPage < 10) {
            for (let i = 0; i < this.maxPage; i++) {
                pages.push({
                    label: i + 1,
                    pageNumber: i + 1
                });
            }
        }
        else {
            //We always show the 1st page, the last page and the 3 pages around the current one
            pages.push({
                pageNumber: 1
            });

            pages.push({
                pageNumber: this.maxPage
            });

            var surroundingPages = [];
            if (this.currentPage === 1) {
                surroundingPages = [2, 3];
            } else if (this.currentPage === this.maxPage) {
                surroundingPages = [this.maxPage - 2, this.maxPage - 1];
            } else {
                surroundingPages = [this.currentPage - 1, this.currentPage, this.currentPage + 1];
            }
            surroundingPages.forEach(sp => {
                //if the page already exists in the list, don't add it
                if (pages.findIndex(p => p.pageNumber === sp) !== -1) {
                    return;
                }
                pages.splice(pages.length - 1, 0, {
                    pageNumber: sp
                });
            });

            //add the empty objects that represent the elipsis
            if ((pages[0].pageNumber + 1) !== pages[1].pageNumber) {
                pages.splice(1, 0, {});
            }

            if ((pages[pages.length - 2].pageNumber + 1) !== pages[pages.length - 1].pageNumber) {
                pages.splice(pages.length - 1, 0, {});
            }
        }

        this.pages = pages;
    }

    nextPage() {
        this.setPage(this.currentPage + 1)
    }
    previousPage() {
        this.setPage(this.currentPage - 1)
    }
    setPage(number, force) {
        if (this.currentPage === number && !force) {
            return;
        }

        this.currentPage = number;
        //calculate the current offset
        this.currentOffset = (this.currentPage - 1) * this.pageSize;
        this.calculatePages();
    }
}

MultiChoiceSetExistingController.$inject = ['$http', '$scope', 'messages'];

export default MultiChoiceSetExistingController;