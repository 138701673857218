import controller from './device-profile-edit-controller';
import template from './device-profile-edit.html';

function deviceProfileEditDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            model: '<'
        }
    }
}

export default deviceProfileEditDirective;