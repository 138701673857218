import controller from './multi-choice-controller';
import template from './multi-choice.html';
import './multi-choice.scss';

function multiChoiceDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            model: '=',
            deviceProfile: '=',
            nonProfileData: '=',
            onSaving: '='
        },
        scope: true
    }
}

export default multiChoiceDirective;