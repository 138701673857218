import controller from './breadcrumb-controller';
import template from './breadcrumb.html';

function breadcrumbDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        scope: true
    }
}

export default breadcrumbDirective;