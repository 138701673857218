import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uicommon from '../ui-common/module';
import ngQuickGrid from 'ng-quick-grid';
import headerTemplate from './hero.html';
import applicationSettingIndexDirective from './application-setting-index-directive';
import applicationSettingDetailDirective from './application-setting-detail-directive';
import applicationSettingEditDirective from './application-setting-edit-directive';
import DataSource from '../data-source';

export default angular.module('ri.admin.applicationSettings', [uiRouter, uicommon.name, 'ngQuickGrid'])
    .directive('applicationSettingIndex', applicationSettingIndexDirective)
    .directive('applicationSettingDetail', applicationSettingDetailDirective)
    .directive('applicationSettingEdit', applicationSettingEditDirective)
    .config(['$stateProvider', '$urlRouterProvider', ($stateProvider, $urlRouterProvider) => {
        $stateProvider
            .state('ri.admin.secure.applicationSettings',
            {
                url: '^/application-settings/:path',
                data: {
                    title: 'Application Settings',
                    secure: true,
                    icon: 'gear',
                    requiredClaim: {name: 'Admin_AppSet.Vi', value: '*'}
                },
                params: {
                    reload: false,
                    path: {
                        squash: true,
                        value: null
                    }
                },
                views: {
                    'hero@ri.admin.secure': {
                        template: headerTemplate,
                        controller: ['$stateParams', '$scope', function($stateParams, $scope) {
                            var p = '';
                            if ($stateParams.path) {
                                $scope.path = $stateParams.path.split('.')
                                    .map(x=> {
                                        var r = p + x;
                                        p = x + '.';
                                        return {n: x, p: r};
                                    });
                            }
                        }]
                    },
                    'content@ri.admin.secure': {
                        template: '<application-setting-index settings="settings"></application-setting-index>',
                        controller: ['$scope', 'settings', function ($scope, settings) {
                            $scope.settings = settings;
                        }]
                    }
                },
                resolve: {
                    settings: ['$q', '$rootScope', '$http', 'SearchModel', '$stateParams', function ($q, $rootScope, $http, SearchModel, $stateParams) {
                        var defer = $q.defer();

                        var ds = new DataSource(SearchModel, $rootScope, function (searchModel) {
                            var payload = searchModel.toQueryString();
                            if ($stateParams.path) {
                                payload = `path=${$stateParams.path}&${payload}`;
                            }
                            return $http.get(`/rateit/api/apps/admin/application-settings?${payload}`)
                                .then(r => r.data);
                        }, 'Name', defer);

                        ds.search.addFilter('Name', '%');

                        return defer.promise;
                    }]
                },
                onExit: ['settings', function (settings) {
                    settings.destroy();
                }]
            })
            .state('ri.admin.secure.applicationSettings.detail', {
                url: '/{key}',
                params: {
                    setting: undefined
                },
                data: {
                    title: 'View Application Setting',
                    secure: true,
                    modal: true,
                    requiredClaim: {name: 'Admin_AppSet.Vi', value: '{key}'}
                },
                views: {
                    'dialog@ri.admin.secure.applicationSettings': {
                        template: '<application-setting-detail setting="setting"></application-setting-detail>',
                        controller: ['$scope', 'setting', function ($scope, setting) {
                            $scope.setting = setting;
                        }]
                    }
                },
                resolve: {
                    setting: ['$q', 'messages', '$stateParams', '$http', 'settings', function ($q, messages, $stateParams, $http, settings) {
                        if ($stateParams.setting) {
                            return $stateParams.setting;
                        }

                        var key = $stateParams.key;

                        var setting = settings.items.find(c => c.name === key);
                        if (setting) {
                            return setting;
                        }

                        //we can't find the setting in the list of settings, show an error
                        messages.showErrorMessage('The requested application setting was not found.', undefined, -1);
                        return $q.reject(`Invalid application setting '${key}'`);
                    }]
                }
            })
            .state('ri.admin.secure.applicationSettings.detail.edit', {
                url: '/edit',
                data: {
                    title: 'Edit Application Setting',
                    secure: true,
                    modal: true,
                    requiredClaim: {name: 'Sy.Co', value: '{key}'}
                },
                views: {
                    'dialog@ri.admin.secure.applicationSettings': {
                        template: '<application-setting-edit setting="setting" result="result"></application-setting-edit>',
                        controller: ['$scope', 'setting', '$q', function ($scope, setting, $q) {
                            var result = $q.defer();
                            $scope.setting = angular.copy(setting);
                            $scope.result = result;

                            result.promise.then(s => {
                                Object.assign(setting, s);
                            });
                        }]
                    }
                }
            });
    }]);