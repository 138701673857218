import controller from './company-validation-rules-controller';
import template from './company-validation-rules.html';

function companyValidationRulesDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            company: '=',
            validationRules: '=',
            isEditing: '='
        },
        scope: true
    }
}

export default companyValidationRulesDirective;