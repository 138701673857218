class DemoRatingImportController {
    constructor($http, $state, $scope, messages) {
        this.$http = $http;
        this.$state = $state;
        this.$scope = $scope;
        this.messages = messages;
        this.deleteExisting = true;

        this.loadDeviceProfiles = (query) => {
            return this.$http.get(`/rateit/api/device-profiles?options.companyId=${this.companyId}&options.state=Published&options.query=${query}&options.excludeDefinition=true`)
                .then(r => {
                    return r.data.results;
                });
        }
    }

    valueTransform(dp) {
        return dp;
    }
    displayTransform(dp) {
        return `${dp.name} v${dp.revision}`;
    }

    downloadSchema() {
        if (this.isDownloadingSchema) {
            return;
        }
        this.isDownloadingSchema = true;

        this.$http.get(`/rateit/api/apps/admin/companies/${this.companyId}/device-profiles/${this.deviceProfile.id}/csv-schema`)
            .then(response => {
                var blob = new Blob([response.data], {
                    type: 'text/csv'
                });

                const data = window.URL.createObjectURL(blob);
                var link = document.createElement('a');

                // Add the element to the DOM so that it works in Firefox. See https://stackoverflow.com/a/32226068/5380.
                link.setAttribute("type", "hidden");
                document.body.appendChild(link);

                link.href = data;
                link.download = `demo-ratings-${this.companyId}-${this.deviceProfile.name}-v${this.deviceProfile.revision}.csv`;
                link.click();
                link.remove();

                setTimeout(() => {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                }, 100);
            })
            .finally(() => {
                this.isDownloadingSchema = false;
            });
    }

    importRatings() {
        if (this.isImporting) {
            return;
        }

        this.isImporting = true;

        var formData = new FormData();
        formData.append('csv', this.populatedCsv);

        this.$http.post(`/rateit/api/apps/admin/companies/${this.companyId}/demo-ratings?deleteExisting=${this.deleteExisting}`, formData, { headers: { 'Content-Type': undefined } })
            .then(() => {
                this.messages.showSuccessMessage('Successfully imported demo ratings');
                this.$state.go('^.ratings');
            })
            .finally(() => {
                this.isImporting = false;
            });
    }
}

DemoRatingImportController.$inject = ['$http', '$state', '$scope', 'messages'];

export default DemoRatingImportController;