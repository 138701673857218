import uuidV4 from 'uuid/v4';

class ActivityHelper {
    create (deviceProfile) {
        var languages = deviceProfile.definition.languages.map(l => {
            return {
                key: l.key,
                name: l.name,
                translations: {}
            };
        });

        return {
            id: uuidV4(),
            isNew: true,
            data: {
            },
            navigation: {
                rules: [],
                custom: {}
            },
            languages: languages,
            toJSON: function () {
                var a = Object.assign({}, this);
                delete a.isNew;

                return a;
            }
        };
    }
}

export default ActivityHelper;