function expressionValidator () {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      var targetModel = scope.$eval(attrs.expression);
      
      ctrl.$validators.expression = function(modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          return true;
        }

        if (viewValue === scope.$eval(attrs.expression)) {
          return true;
        }

        return false;
      }
    }
  };
}

export default expressionValidator;
