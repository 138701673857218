class StaffController {

    constructor ($scope) {
        this.$scope = $scope;
    }

    $onInit () {
        this.OrderingMode = {
            Alphabetical : "Alphabetical",
            Randomize : "Randomize"
        }
    
        this.SelectionMode = {
            Single: "Single",
            Multi: "Mutli"
        }


        if (this.model && this.model.isNew) {
            this.model.data.title = this.model.data.title || 'Who helped you out today?';
            this.model.data.orderingMode = null; //required field i.e. user to select

            //single/multi-select
            this.model.data.selectionMode = this.SelectionMode.Single; //force single-select for now (the selection mode is hidden from the portal profile designer until multi-select is support)
            //this.model.data.nextCaption = this.model.data.nextCaption || 'Next'; Only need this when we allow for multi select

            //Fall-back options
            this.initNoStaffOption(true);
            this.initUnknownStaffOption(true);
         }

        // Handle the case where the staff profile was created prior to the introduction of staff options.
        // In this scenario we leave the options as disabled (i.e. we don't want to enforce the new options on old profiles globally)
        // but if the user decides to enable the options it should show the default place holder images and text.
        // i.e. the defaults should always be available
        if(this.model.data.noStaffOption == null) {
            this.initNoStaffOption(false);
        }
        if(this.model.data.unknownStaffOption == null) {
            this.initUnknownStaffOption(false);
        }

        if (!this.model.oldId){
             this.model.oldId = this.model.id;
        }
    }

    initNoStaffOption(enabled) {
        this.model.data.showNoStaffOption = enabled; // it was discussed with the CX team that this should be default enabled
        this.model.data.noStaffOption = this.model.data.noStaffOption || {};
        this.model.data.noStaffOption.label = this.model.data.noStaffOption.label || "No one served me";
        this.model.data.noStaffOption.imgUrl = this.model.data.noStaffOption.imgUrl || "https://static.rateitapp.com/images/staff_nostaffservedme.svg";
    }

    initUnknownStaffOption(enabled) {
        this.model.data.unknownStaffOption = this.model.data.unknownStaffOption || {};
        this.model.data.unknownStaffOption.label = this.model.data.unknownStaffOption.label || "I don't know";
        this.model.data.unknownStaffOption.imgUrl = this.model.data.unknownStaffOption.imgUrl || "https://static.rateitapp.com/images/staff_dontknow.svg";
        this.model.data.showUnknownStaffOption = enabled; // it was discussed with the CX team that this should be default enabled
    }
}

StaffController.$inject = ['$scope'];

export default StaffController;