class AuthContext {
    constructor($window) {
        this.$window = $window;
    }

    clear () {
        this.$window.localStorage.removeItem('claims');
        this.$window.localStorage.removeItem('username');
        this.$window.localStorage.removeItem('token');
        this.$window.localStorage.removeItem('refreshToken');
    }

    isLoggedIn() {
        return this.$window.localStorage.getItem('username') !== null;
    }

    getUsername() {
        return this.$window.localStorage.getItem('username');
    }

    hasClaim(type, value) {
        var claims = this.$window.localStorage.getItem('claims')
            .split(',')
            .reduce((x, v) => {
                var parts = v.split('=');
                var name = parts[0];
                var claim = x[name] || (x[name] = []);
                claim.push(parts[1]);

                return x;
            }, {})

        if (value) {
            var claimValues = claims[type];
            if (claimValues) {
                var valParts = value.split('|');
                //if you have multiple values for this claim, only 1 has to pass
                return claimValues.some(claimValue => {
                    var clParts = claimValue.split('|');

                    //the  parts must be the same length
                    if (valParts.length === clParts.length) {
                        var passes = [];
                        for (var i = 0; i < valParts.length; i++) {

                            //if my claim part is a wildcard, we just pass it
                            if (clParts[i] === '*') {
                                passes.push(true);
                            }
                            else {
                                //compare my claim to the supplied test
                                passes.push(clParts[i] === valParts[i]);
                            }
                        }

                        //all claims have to pass to be valid
                        return passes.length === valParts.length && passes.every(b => b);
                    }
                });
            }
            return false;
        }

        return claims[type] !== undefined;
    }
}

AuthContext.$inject = ['$window'];

export default AuthContext;
