import controller from './rating-detail-controller';
import template from './rating-detail.html';
import './rating-detail.scss';

function ratingDetailDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            rating: '<',
            deviceProfile: '<'
        }
    }
}

export default ratingDetailDirective;