class DeviceProfileDeleteActivityController {
    constructor ($http, $state, messages) {
        this.$http = $http;
        this.$state = $state;
        this.messages = messages;
    }

    submit () {
        this.loading = true;
        this.$http.delete(`/rateit/api/device-profiles/${this.deviceProfile.id}/activities/${this.activity.id}`)
            .then (r => {
                if (r.data.validationWarnings && r.data.validationWarnings.length) {
                    this.messages.showWarningMessage(`The activity has been deleted. However there were some validation errors.`, r.data.validationWarnings.map(m=> {return {errorMessage:m}}), -1);
                }
                else {
                    this.messages.showSuccessMessage(`The activity has been deleted.`, null, -1);
                }
                this.deviceProfile.definition = r.data.definition;

                this.$state.go('^', {deviceProfileId: this.deviceProfile.id});
            }, () => {
                this.loading = false;
            });
    }
}

DeviceProfileDeleteActivityController.$inject = ['$http', '$state', 'messages'];

export default DeviceProfileDeleteActivityController;