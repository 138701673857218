import controller from './admin-user-add-controller';
import template from './admin-user-add.html';


function adminUserAddDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        scope: {}
    }
}

export default adminUserAddDirective;