import angular from 'angular';
import ngAnimate from 'angular-animate';
import breadcrumbDirective from './breadcrumb-directive.js';
import siteNavDirective from './nav-directive.js'
import routerProgressDirective from './router-progress-directive';
import messagesDirective from './messages-directive';
import messagesBoundaryDirective from './messages-boundary-directive';
import compareValidator from './compare-validator';
import numericValidator from './numeric-validator';
import pagingDirective from './paging-directive';
import messagesService from './messages-service';
import {requireClaimDirective, withoutClaimDirective} from './require-claim-directive';
import loadClickDirective from './load-click-directive';
import titleDirective from './title-directive';
import isolateFormDirective from './isolate-form-directive';
import autocompleteDirective from './autocomplete-directive';
import validJSONValidator from './valid-json-validator';
import tabInputDirective from './tab-input-directive';
import ngQuickGrid from 'ng-quick-grid';
import lengthTrackDirective from './length-track-directive';
import inputFileDirective from './input-file-directive';
import riTextareaDirective from './ri-textarea-directive';
import riInputDirective from './ri-input-directive';
import formValDirective from './form-val-directive';
import modalWizardDirective from './modal-wizard-directive';
import expressionValidator from './expression-validator';
import focusDirective from './focus-directive.js';
import {minValidator, maxValidator} from './min-max-validator';
import 'moment';
import angularMoment from 'angular-moment';

var module = angular.module('ri.admin.ui.common', [ngAnimate, 'ngQuickGrid', angularMoment])
    .directive('breadcrumb', breadcrumbDirective)
    .directive('siteNav', siteNavDirective)
    .directive('routerProgress', routerProgressDirective)
    .directive('requireClaim', requireClaimDirective)
    .directive('withoutClaim', withoutClaimDirective)
    .directive('compare', compareValidator)
    .directive('json', validJSONValidator)
    .directive('numeric', numericValidator)
    .directive('loadClick', loadClickDirective)
    .directive('messages', messagesDirective)
    .directive('paging', pagingDirective)
    .directive('title', titleDirective)
    .directive('tabInput', tabInputDirective)
    .directive('lengthTrack', lengthTrackDirective)
    .directive('isolateForm', isolateFormDirective)
    .directive('autoComplete', autocompleteDirective)
    .directive('messagesBoundary', messagesBoundaryDirective)
    .directive('inputFile', inputFileDirective)
    .directive('riTextarea', riTextareaDirective)
    .directive('riInput', riInputDirective)
    .directive('formVal', formValDirective)
    .directive('modalWizard', modalWizardDirective)
    .directive('ngMin', minValidator)
    .directive('ngMax', maxValidator)
    .directive('expression', expressionValidator)
    .directive('focus', focusDirective)
    .service('messages', messagesService)
    .config(['$provide', ($provide) => {
        $provide.decorator('quickSortDirective', ['$delegate', function ($delegate) {
            $delegate[0].templateSource = `<span ng-if="grid.searchModel.paging.isSorting(sortBy.toLowerCase()) !== false" class="icon">
                <i class="fa fa-caret-down" ng-if="grid.searchModel.paging.sortBy[grid.searchModel.paging.isSorting(sortBy.toLowerCase())].indexOf(\'desc\') > -1"></i>
                <i class="fa fa-caret-up" ng-if="grid.searchModel.paging.sortBy[grid.searchModel.paging.isSorting(sortBy.toLowerCase())].indexOf(\'desc\') === -1"></i>                
            </span><span class="tag is-info" ng-if="grid.searchModel.paging.sortBy.length > 1">{{grid.searchModel.paging.isSorting(sortBy.toLowerCase())+1}}</span>`;

            return $delegate;
        }]);
    }]);

export default module;
