import screenTypeOptions from '../screen-type-options';

class DeviceProfileScreenSelectionController {
    constructor ($sce) {
        this.types = screenTypeOptions
        this.$sce = $sce;
    }
}

DeviceProfileScreenSelectionController.$inject = ['$sce']

export default DeviceProfileScreenSelectionController;