class CommentsController {
    constructor () {
    }

    $onInit () {
        if (this.model) {
            if (this.model.isNew) {
                this.model.data.title = this.model.data.title || 'Oh no!\nWhat went wrong?';
                this.model.data.subtitle = this.model.data.subtitle || 'Your feedback matters - we read every response!';
                this.model.data.placeholderCaption = this.model.data.placeholderCaption || 'Type your feedback here!';            
                this.model.data.nextCaption = this.model.data.nextCaption || 'Next';
                this.model.data.personalizationInfo = this.model.data.personalizationInfo || {
                    imageUrl: null,
                    caption: null
                };
            }
        
            this.model.data.templates = this.model.data.templates || {
                hintCaption: 'Save typing, choose a template.',
                visibleOnShow: true,
                responses: []
            };
        }
    }

    addTemplate () {
        if (this.model.data.templates.responses.indexOf(this.template) === -1) {
            if (this.template && this.template.length) {
                this.model.data.templates.responses.push(this.template);
                this.template = null;
            }
        }
    }

    removeTemplate (response) {
        if (this.model.data.templates.responses.indexOf(response) > -1) {
            this.model.data.templates.responses.splice(this.model.data.templates.responses.indexOf(response), 1);
        }
    }
}

export default CommentsController;