class RatingDeleteAllController {
    constructor ($http, $scope, $state, messages) {
        this.$http = $http;
        this.$scope = $scope;
        this.$state = $state;
        this.messages = messages;
    }

    $onInit () {
    }

    delete () {
        this.loading = true;

        this.$http.delete(`/rateit/api/apps/admin/ratings/${this.rating.id}`)
            .then((response) => {
                this.messages.showSuccessMessage(`A rating has been removed.`, null, -1);
                var index = this.ratings.indexOf(this.rating);
                this.ratings.splice(index, 1);
                this.$state.go('^');
            })
            .catch(()=>{this.loading = false});
    }
}

RatingDeleteAllController.$inject = ['$http', '$scope', '$state', 'messages'];

export default RatingDeleteAllController;