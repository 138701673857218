import controller from './device-profile-add-v2-controller';
import template from './device-profile-add-v2.html';

function deviceProfileAddV2Directive() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindingToController: true,
        scope: {}
    }
}

export default deviceProfileAddV2Directive;