import uuidV4 from 'uuid/v4';
import screenTypeOptions from '../screen-type-options';

class DeviceProfileScreenEditController {
    constructor($state, $http, messages, authContext) {
        this.$state = $state;
        this.$http = $http;
        this.messages = messages;
        this.authContext = authContext;
    }

    $onInit() {
        this.nonProfileData = {};
        this.onSaving = () => { };
        this.readonly = this.deviceProfile.state === 'Published' || !this.authContext.hasClaim('Dp.Ad', this.deviceProfile.id);
        this.model.typeLocked = true;
        this.options = screenTypeOptions[this.model.type].directiveOptions;
        this.wizardOptions = {
            screens: [
                {
                    state: 'ri.admin.secure.deviceProfiles.detail.screenEdit',
                    onexit: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenEdit.data']);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.detail.screenEdit.data',
                    onexit: () => {
                        if (this.deviceProfile.definition.languages.length === 1) {
                            if (screenTypeOptions[this.model.type].skipNavigation) {
                                return this.save();
                            }
                            return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenEdit.navigation', { type: this.model.type }]);
                        }
                        else {
                            return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenEdit.translation', undefined, screenTypeOptions[this.model.type].skipNavigation]);
                        }
                    },
                    onback: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenEdit']);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.detail.screenEdit.translation',
                    onexit: () => {
                        if (screenTypeOptions[this.model.type].skipNavigation) {
                            return this.save();
                        }

                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenEdit.navigation', { type: this.model.type }]);
                    },
                    onback: () => {
                        return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenEdit.data']);
                    }
                },
                {
                    state: 'ri.admin.secure.deviceProfiles.detail.screenEdit.navigation',
                    onexit: () => {
                        return this.save();

                    },
                    onback: () => {
                        if (this.deviceProfile.definition.languages.length === 1) {
                            return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenEdit.data']);
                        }
                        else {
                            return Promise.resolve(['ri.admin.secure.deviceProfiles.detail.screenEdit.translation', undefined, screenTypeOptions[this.model.type].skipNavigation]);
                        }
                    }
                }
            ]
        };
    }

    save() {
        if (this.readonly) {
            return Promise.resolve(['ri.admin.secure.deviceProfiles.detail', { deviceProfileId: this.deviceProfile.id }]);
        }

        this.onSaving();

        return this.$http.put(`/rateit/api/device-profiles/${this.deviceProfile.id}/activities/${this.model.id}`, { deviceProfileId: this.deviceProfile.id, oldId: this.model.oldId, activity: this.model, nonProfileData: this.nonProfileData })
            .then(r => {
                if (r.data.validationWarnings && r.data.validationWarnings.length) {
                    this.messages.showWarningMessage(`An activity was successfully updated on this device profile. However there were some validation errors.`, r.data.validationWarnings.map(m => { return { errorMessage: m } }), -1);
                }
                else {
                    this.messages.showSuccessMessage(`An activity was successfully updated on this device profile.`, null, -1);
                }
                this.deviceProfile.definition.activities = r.data.activities;
                return ['ri.admin.secure.deviceProfiles.detail', { deviceProfileId: this.deviceProfile.id }]
            });
    }
}

DeviceProfileScreenEditController.$inject = ['$state', '$http', 'messages', 'authContext'];

export default DeviceProfileScreenEditController;