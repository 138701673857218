import uuidV4 from 'uuid/v4';

class DeviceProfileAddV2Controller {
    constructor($state, $http, messages) {
        this.$state = $state;
        this.$http = $http;
        this.messages = messages;
        this.model = {};
        this.companies = this.$http.get('/rateit/api/companies')
            .then(r => {
                this.companies = r.data.results;
            })
    }
    submit() {
        this.loading = true;
        this.$http.post(`/rateit/api/device-profiles/v2`, this.model)
            .then((r) => {
                this.messages.showSuccessMessage(`This device profile has been successfully created.`, null, -1);
                this.$state.go('^.detail-v2', { deviceProfileId: r.data.deviceProfileId });
            }, () => this.loading = false);
    }
}

DeviceProfileAddV2Controller.$inject = ['$state', '$http', 'messages'];

export default DeviceProfileAddV2Controller;
