class CompanyCreateStep1Controller {
  constructor($http, $state, $scope, shotty) {
    this.$http = $http;
    this.$state = $state;
    this.$scope = $scope;
    this.shotty = shotty;
    this.today = new Date();

    this.emailHours = [];
    for (let i = 0; i < 24; i++) {
      var hour = i % 12 || 12;
      var t = i < 12 ? 'AM' : 'PM';
      this.emailHours.push({
        label: `${hour}:00 ${t}`,
        value: i
      });
    }
  }

  $onInit() {
    this.$http
      .get('/rateit/api/countries?excludeStates=true')
      .then(response => {
        this.countries = response.data.results;
      });

    this.$http.get('/rateit/api/company-tiers').then(response => {
      this.companyTiers = response.data;
    });

    this.$http.get('/rateit/api/system/features').then(response => {
      this.features = response.data;
    });

    this.$http.get('/rateit/api/system/timezones').then(response => {
      this.timezones = response.data.map(tz => {
        var offsetLabel = this.getOffsetLabel(tz);
        return {
          id: tz.id,
          offset: offsetLabel
        };
      });
    });

    this.$http.get('/rateit/api/system/industries').then(response => {
      this.industries = response.data
        .filter(ind => ind.subIndustries && ind.subIndustries.length > 0)
        .sort((ind1, ind2) => ind1.name.localeCompare(ind2.name))
        .flatMap(ind => {
          return ind.subIndustries
            .map(si => {
              return {
                id: si.id,
                name: si.name,
                parentIndustry: ind.name
              };
            })
            .sort((si1, si2) => si1.name.localeCompare(si2.name));
        });
    });

    this.$http.get('/rateit/api/premium-insights/reports').then(response => {
      this.premiumInsightsReports = response.data;
    });

    if (this.isEditing) {
      this.hasReducedFunctionality = !!this.company.reducedFunctionalityDate;
      this.company.reducedFunctionalityDate = this.company
        .reducedFunctionalityDate
        ? new Date(this.company.reducedFunctionalityDate)
        : undefined;

      this.minReducedFunctionalityDate =
        this.company.reducedFunctionalityDate || this.today;

      this.$scope.$watch(
        () => this.hasReducedFunctionality,
        (newValue, oldValue) => {
          if (newValue === oldValue) {
            return;
          }

          //we HAVE set a billing cutoff date
          if (newValue) {
            const shotgun = new Audio(this.shotty);
            shotgun.play();

            this.minReducedFunctionalityDate = this.today;
          } else {
            //if we have disabled the billing cutoff date, then remove it from the object we send to the server
            this.company.reducedFunctionalityDate = undefined;
            this.minReducedFunctionalityDate = undefined;
          }
        }
      );
    }

    this.$scope.$watch(() => this.company.premiumInsightsSuperUserReportId, (newValue, oldValue) => {
      if (newValue === oldValue || (newValue && newValue !== '')) {
        return;
      }

      delete this.company.premiumInsightsStandardUserReportId;
    });
  }

  formatTz(tz) {
    const prefix = tz.offset >= 0 ? '+' : '-';

    const absMinutes = Math.abs(tz.offset);

    //padStart is unsupported by IE, but this is in Admin and Admin doesn't support IE11
    const hours = parseInt(absMinutes / 60)
      .toString()
      .padStart(2, '0');
    const minutes = (absMinutes % 60).toString().padStart(2, '0');

    return `(UTC ${prefix}${hours}:${minutes}) ${tz.id}`;
  }

  getOffsetLabel(tz) {
    const prefix = tz.offset >= 0 ? '+' : '-';

    const absMinutes = Math.abs(tz.offset);

    //padStart is unsupported by IE, but this is in Admin and Admin doesn't support IE11
    const hours = parseInt(absMinutes / 60)
      .toString()
      .padStart(2, '0');
    const minutes = (absMinutes % 60).toString().padStart(2, '0');

    return `UTC ${prefix}${hours}:${minutes}`;
  }

  toggleFeature(key) {
    var index = this.company.features.indexOf(key);
    if (index === -1) {
      this.company.features.push(key);
    } else {
      this.company.features.splice(index, 1);
    }
  }


}

CompanyCreateStep1Controller.$inject = ['$http', '$state', '$scope', 'shotty'];

export default CompanyCreateStep1Controller;
