class MessagesController {
    constructor (messages, $scope) {
        this.messages = messages;
        this.parentController = null;

        $scope.$watch(() => this.parentController, () => {
            if (this.validMessage()) {
                this.validMessage().clear = true;
            }
            else {
                $scope.$watch(() => this.validMessage(), (m) => {
                    if (m) {
                        m.clear = true;
                    }
                })
            }
        });
    }

    $onInit () {
        if (this.validMessage()) {
            this.validMessage().clear = true;
        }
    }
    
    clearMessage () {
        this.messages.clearMessage();
    }

    validMessage() {
        if (this.parentController && this.parentController.enabled && this.messages.message && this.messages.message.nesting == this.parentController.depth) {
            return this.messages.message;
        }

        return null;
    }
}

MessagesController.$inject = ['messages', '$scope'];

export default MessagesController;