class ApplicationSettingDetailController {
    constructor ($state, messages) {
        this.$state = $state;
        this.messages = messages;
    }

    $onInit () {
        this.value = JSON.stringify(JSON.parse(this.setting.value), null, "\t");
    }
}

ApplicationSettingDetailController.$inject = ['$state', 'messages'];

export default ApplicationSettingDetailController;