class DeviceProfileIndexController {
    constructor($http, SearchModel, $state, $scope) {
        this.loading = true;
        this.$http = $http;
        this.$state = $state;
        this.$scope = $scope;
        this.items = [];
        this.search = new SearchModel(this.listDeviceProfiles.bind(this), 'Name');
        this.search.paging.pageIndex = 0;
        this.search.model.query = '';
        this.search.model.excludeDefinition = true;
        this.search.model.includeHistory = false;

        if (this.$scope.companyId) {
            this.search.model.companyId = this.$scope.companyId;
        }

        this.isCompanySpecific = !!this.$scope.companyId;

        this.search.watch($scope);
        $scope.$on('addActivitiesListUIByJson', function (evt, res) {
            $scope.activitiesListUI = res.activitiesListUI
            $scope.errorMessage = res.errorMessage;
            $scope.$broadcast('addActivitiesListUIByJson1', function (evt, res) {
                $scope.activitiesListUI = res.activitiesListUI
                $scope.errorMessage = res.errorMessage;
            });
        });
    }

    $onInit() {
        this.search.apply();
    }

    listDeviceProfiles(searchModel) {
        var payload = searchModel.toQueryString();
        this.loading = true;
        return this.$http.get(`/rateit/api/device-profiles?${payload}`)
            .then((r) => {
                this.items = r.data.results;
                this.loading = false;
                return r.data;
            }, () => { this.loading = false });
    }
}

DeviceProfileIndexController.$inject = ['$http', 'SearchModel', '$state', '$scope'];

export default DeviceProfileIndexController;