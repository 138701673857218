import controller from './company-settings-controller';
import template from './company-settings.html';
import './company-settings.scss';

function companySettingsDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            company: '<',
            validationRules: '<'
        }
    }
}

export default companySettingsDirective;