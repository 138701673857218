class AutoCompleteController {
    constructor($element, $window, $scope, $transclude, $timeout) {
        this.$element = $element;
        this.$timeout = $timeout;
        this.$window = $window;
        this.$scope = $scope;
        this.$transclude = $transclude;
        this.transclusionScopes = [];
        this.boundClose = this.close.bind(this);
        this.query = '';
        this.lastQuerySet = ''
    }

    $onInit() {
        this.container = angular.element(`<div name="autocompletelist" class="autocomplete-list">
        </div>`);

        this.body = angular.element(this.$window.document.body);
        this.body.append(this.container);
        var window = angular.element(this.$window);

        window.on('resize', this.position.bind(this));
        window.on('scroll', this.position.bind(this));

        angular.element(this.$window.document.body)
    }

    $onDestroy() {
        this.container.remove();
        this.transclusionScopes.forEach(x => {
            x.$destroy();
        });

        var window = angular.element(this.$window);
        window.off('resize', this.position.bind(this));
    }

    findPos(obj) {
        var curLeft = 0,
            curTop = this.$window.scrollY || this.$window.pageYOffset;

        if (obj.offsetParent) {
            do {
                curLeft += obj.offsetLeft;
                curTop += obj.offsetTop;
                curTop += obj.scrollTop;
            } while (obj = obj.offsetParent);
        }

        return {
            x: curLeft,
            y: curTop
        };
    }

    clear() {
        this.query = '';
        this.ngModel.$setViewValue(undefined, 'click');
    }

    close(ev) {
        var inpath = false;
        if (ev && ev.path) {
            ev.path.forEach(e => {
                if (e === this.$element[0]) {
                    inpath = true;
                }
            });
        }
        if (!inpath) {
            this.container.css('display', 'none');
            this.body.off('click', this.boundClose);
            //this seems to fix a bug where `this` is null in the $timeout callback
            var self = this;
            this.$timeout(() => {
                self.query = self.lastQuerySet;
            });
        }
    }

    position() {
        var position = this.findPos(this.$element[0]);
        var elementHeight = this.$element.prop('offsetHeight');
        this.container.css('width', this.$element.parent().prop('offsetWidth') + 'px')
        this.container.css('left', position.x + 'px');
        this.container.css('top', (elementHeight + position.y) + 'px');
    }

    refreshList() {
        this.position();
        this.container.empty()
        this.transclusionScopes.forEach(x => {
            x.$destroy();
        });

        if (this.query && this.query.length)
            this.autoFunc(this.query)
                .then(data => {
                    this.container.css('display', 'block');
                    if (!data.length) {
                        var item = angular.element('<div class="autocomplete-item"></div>');
                        item.html('No matches');
                        this.container.append(item);
                    } else {
                        this.body.on('click', this.boundClose);
                        data.forEach(i => {
                            this.$transclude((clone, scope) => {
                                var item = angular.element('<div class="autocomplete-item"></div>');
                                item.on('click', () => {
                                    this.lastQuerySet = this.displayTransform(i);
                                    let value = i;
                                    if (this.valueTransform) {
                                        value = this.valueTransform(i);
                                    }
                                    this.ngModel.$setViewValue(value, 'click');
                                    if (this.onSelect) {
                                        scope.$apply(() => {
                                            this.onSelect(i);;
                                        });
                                    }
                                });
                                item.append(clone);
                                this.transclusionScopes.push(scope);
                                Object.assign(scope, i);
                                this.container.append(item);
                            });
                        });
                    }
                });
        else
            this.close();
    }
}

AutoCompleteController.$inject = ['$element', '$window', '$scope', '$transclude', '$timeout']

export default AutoCompleteController;