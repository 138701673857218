class AdminUserDetailController {
    constructor ($http, $state, messages, $q) {
        this.$http = $http;
        this.$state = $state;
        this.messages = messages;
        this.$q = $q;
        this.modalLoading = true;
    }

    $onInit () {
        var user;

        if (!this.user) {
            user = this.$http.get(`/rateit/api/apps/admin/users/${this.username}`)
                .then(r => {
                    this.user = r.data;
                    return this.user;
                });
        }
        else {
            user = this.$q.resolve(this.user);
        }

        this.$q.all([user, this.$http.get('/rateit/api/apps/admin/users/claimsets')])
            .then(r => {
                var activeClaimSets = [];
                var claimSets = r[1].data;
                var user = r[0];
                var userClaims = user.claims.map(x => x.name);
                
                claimSets.forEach(c=> {                    
                    let names = c.claims.map(z=>z.split('=')[0]);
                    if (names.every(z=> userClaims.indexOf(z) > -1)) {
                        activeClaimSets.push(c.name);
                    }

                    (c.children || []).forEach(c => {
                        let names = c.claims.map(z=>z.split('=')[0]);
                        if (names.every(z=> userClaims.indexOf(z) > -1)) {
                            activeClaimSets.push(c.name);
                        }
                    });
                });

                this.activeClaimSets = activeClaimSets;
                this.modalLoading = false;
            });
    }

    resend () {
        this.loading = true;
        return this.$http.post(`/rateit/api/apps/admin/users/${this.username}/resend-invitation`)
            .then(r => {
                this.messages.showSuccessMessage('The users invitation has been resent.')
                this.loading = false;
            }, () => this.loading = false);
    }

    regenerate () {
        this.loading = true;
        return this.$http.post(`/rateit/api/apps/admin/users/${this.username}/regenerate-otp`)
            .then(r => {
                this.messages.showSuccessMessage('The users otp key has been regenerated and sent to them via email.')
                this.loading = false;
            }, () => this.loading = false);
    }

    delete () {
        this.$state.go('.delete', {user: this.user});
    }

    close () {
        this.$state.go('^');
    }
}

AdminUserDetailController.$inject = ['$http', '$state', 'messages', '$q'];

export default AdminUserDetailController;