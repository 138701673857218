class PhoneController {
    constructor () {
    }

    $onInit () {
        if (this.model && this.model.isNew) {
            this.model.data.title = this.model.data.title || 'May we respond\nto you about this?';
            this.model.data.subtitle = this.model.data.subtitle || 'We\'d love to chat to you further';
            this.model.data.placeholderCaption = this.model.data.placeholderCaption || 'My mobile phone is...';
            this.model.data.nextCaption = this.model.data.nextCaption || 'All done';
        }
    }
}

export default PhoneController;