class CompanyValidationRulesController {
  constructor($http, $state, $scope) {
    this.$http = $http;
    this.$state = $state;
    this.$scope = $scope;
  }

  $onInit() {
    //if we are creating a company, pre-set the validation rules
    if (!this.isEditing) {
      this.validationRules.forEach(r => {
        if (r.id.toLowerCase() !== 'fde49c86-9fde-4fed-8481-6a29b9cd0d82') { //cx only RI-157
          this.company.rules.push(r.id);
        }
      });
    }
  }

  toggleRule(ev, ruleId) {
    let index = this.company.rules.indexOf(ruleId)
    if (index > -1) {
      this.company.rules.splice(index, 1);
    }
    else {
      this.company.rules.push(ruleId);
    }
  }
}

CompanyValidationRulesController.$inject = ['$http', '$state', '$scope'];

export default CompanyValidationRulesController;