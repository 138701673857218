import controller from './modal-wizard-controller';
import template from './modal-wizard.html';

function modalWizardDirective () {
    return {
        controller,
        template,
        restrict: 'E',
        controllerAs: 'wizard',
        bindToController: {
            config: '='
        },
        scope: true
        //scope: {},
        // link: function (scope, element, attrs, messages) {
        //     var parentMessages = messages[0];
        //     var controller = messages[1];
            
        //     if (parentMessages !== null) {
        //         parentMessages.disable();
        //     }

        //     scope.$on('$destroy', () => {
        //         if (parentMessages !== null) {
        //             parentMessages.enable();
        //         }
        //     });
        // }
    }
}

export default modalWizardDirective;