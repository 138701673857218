import controller from './yesno-controller';
import template from './yesno.html';

function yesNoDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            model: '=',
            deviceProfile: '='
        },
        scope: true
    }
}

export default yesNoDirective;