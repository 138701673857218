import controller from './data-classification-index-controller';
import template from './data-classification-index.html';

function dataClassificationIndexDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            classifications: '='
        }
    }
}

export default dataClassificationIndexDirective;