class NavController {
    constructor ($state, authContext, $http) {
        this.$state = $state;
        this.authContext = authContext;
        this.$http = $http;
    }

    logout () {
        this.authContext.clear();
        this.$http.post('/account/logoff')
            .then(() => {
                this.$state.go('ri.admin.public.login');
            });
    }
}

NavController.$inject = ['$state', 'authContext', '$http'];

export default NavController;