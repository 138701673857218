class CompanyEditController {
  constructor($http, $state, $scope, messages) {
    this.$http = $http;
    this.$state = $state;
    this.$scope = $scope;
    this.messages = messages;
    this.$scope.appBotValidation;

    this.wizardOptions = {
      screens: [
        {
          state: "ri.admin.secure.companies.detail.edit.step1",
          onexit: () => {
            return Promise.resolve([
              "ri.admin.secure.companies.detail.edit.step2"
            ]);
          }
        },
        {
          state: "ri.admin.secure.companies.detail.edit.step2",
          onexit: () => {
            return this.save();
          },
          onback: () => {
            return Promise.resolve([
              "ri.admin.secure.companies.detail.edit.step1"
            ]);
          }
        }
      ]
    };

    this.$scope.validateAppBotApiCredentials = function(
      appBotAppId,
      appBotApiKey
    ) {
      if (!appBotAppId || !appBotApiKey) {
        $scope.appBotValidation = "invalid";
        return;
      }

      $scope.appBotValidation = "loading";

      $http({
        method: "GET",
        url: `https://api.appbot.co/api/v2/apps/${appBotAppId}/reviews`,
        headers: {
          Authorization: `Basic ${appBotApiKey}`
        }
      }).then(
        function successCallback() {
          $scope.appBotValidation = "valid";
        },
        function errorCallback() {
          $scope.appBotValidation = "invalid";
        }
      );
    };
  }

  save() {
    //we only want the date portion of this sent to the server
    if (!!this.company.reducedFunctionalityDate) {
      this.company.reducedFunctionalityDate.toJSON = function() {
        var mm = this.getMonth() + 1;
        var dd = this.getDate();
        return `${this.getFullYear()}-${mm
          .toString()
          .padStart(2, "0")}-${dd.toString().padStart(2, "0")}`;
      };
    }

    return this.$http
      .put(`/rateit/api/companies/${this.company.id}`, this.company)
      .then(r => {
        this.messages.showSuccessMessage(
          `${this.company.name} has been successfully updated.`,
          null,
          -1
        );
        return [
          "ri.admin.secure.companies.detail",
          { companyId: this.company.id },
          undefined,
          { reload: true }
        ];
      });
  }
}

CompanyEditController.$inject = ["$http", "$state", "$scope", "messages"];

export default CompanyEditController;
