class AdminUserDeleteController
{
    constructor ($state, $http, messages) {
        this.$state = $state;
        this.$http = $http
        this.messages = messages;
    }

    $onInit () {

    }

    delete () {
        this.loading = true;
        return this.$http.delete(`/rateit/api/apps/admin/users/${this.user.username}`)
            .then(() => {
                this.messages.showSuccessMessage(`The user ${this.user.username} was successfully deleted.`, null, -1);
                this.$state.go(this.$state.$current.parent.parent, null,  {reload: true});
            }, () => {this.loading = false})
    }

    cancel () {
        this.$state.go('^');
    }
}

AdminUserDeleteController.$inject = ['$state', '$http', 'messages'];

export default AdminUserDeleteController;