import './paging.scss';

function requireClaimDirective ($state, authContext) {
    return {
        restrict: 'A',
        scope: false,
        link: function(scope, element, attrs) {
            var claim = attrs['requireClaim'].split('=')[0];
            var claimValue = attrs['requireClaim'].split('=')[1]
            var state = $state.current;
            if (!authContext.isLoggedIn()) {
                element.remove();
                return;
            }
            
            claimValue = claimValue.replace(/\{([A-Za-z0-9\*]+)\}/g, (match, name) => {
                return state.params[name];
            });

            if (!authContext.hasClaim(claim, claimValue)) {                    
                element.remove();
            }
        }
    }
}

requireClaimDirective.$inject = ['$state', 'authContext'];

function withoutClaimDirective ($state, authContext) {
    return {
        restrict: 'A',
        scope: false,
        link: function(scope, element, attrs) {
            var claim = attrs['withoutClaim'].split('=')[0];
            var claimValue = attrs['withoutClaim'].split('=')[1]
            var state = $state.current;
            if (!authContext.isLoggedIn()) {
                element.remove();
                return;
            }
            
            claimValue = claimValue.replace(/\{([A-Za-z0-9\*]+)\}/g, (match, name) => {
                return state.params[name];
            });

            if (authContext.hasClaim(claim, claimValue)) {                    
                element.remove();
            }
        }
    }
}

withoutClaimDirective.$inject = ['$state', 'authContext'];

export {requireClaimDirective, withoutClaimDirective};