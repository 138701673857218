import controller from './device-profile-edit-step1-controller';
import template from './device-profile-edit-step1.html';

function deviceProfileEditStep1Directive () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            deviceProfile: '<'
        }
    }
}

export default deviceProfileEditStep1Directive;