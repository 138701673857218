import controller from './login-controller';
import template from './login.html';

function loginDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        scope: {}
    }
}

export default loginDirective;