import controller from './admin-user-delete-controller';
import template from './admin-user-delete.html';


function adminUserDeleteDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            user: '='
        }
    }
}

export default adminUserDeleteDirective;