import uuidV4 from 'uuid/v4';

const dataIntegrityEnforcementStatus = {
    // Data integrity won't be enforced, so there's nothing to opt out of.
    NO_OPT_OUT_REQUIRED: 0,
    // Data integrity will be enforced unless the user opts out.
    CAN_OPT_OUT: 1,
    // Data integrity will be enforced and the user is unable to opt out.
    CANNOT_OPT_OUT: 2
}

class MultiChoiceController {
    constructor($scope, $http) {
        this.$scope = $scope;
        this.$http = $http;

        this.dataClassifications = [];
        this.optionClassificationsMap = {};
        this.optionCaptionsMap = {};
        this.dataIntegrityEnforcementStatus = dataIntegrityEnforcementStatus.NO_OPT_OUT_REQUIRED;
        this.optOutOfDataIntegrityEnforcement = false;
    }

    $onInit() {
        var onSaving = () => {
            this.updateNavigationRuleLabels();

            var enforceDataIntegrity = this.dataIntegrityEnforcementStatus === dataIntegrityEnforcementStatus.CANNOT_OPT_OUT ||
                (this.dataIntegrityEnforcementStatus === dataIntegrityEnforcementStatus.CAN_OPT_OUT && !this.optOutOfDataIntegrityEnforcement);

            if (enforceDataIntegrity) {
                this.enforceDataIntegrity();
            }
        };
        this.onSaving = onSaving;

        // load all the classification keys
        this.$http.get(`/rateit/api/data-classifications`)
            .then(r => {
                this.dataClassifications = r.data.filter(dc => dc.children && dc.children.length > 0)
                    .sort((dc1, dc2) => dc1.key.localeCompare(dc2.key))
                    .flatMap(dc => {
                        return dc.children.map(dcc => {
                            var displayLabel = dcc.label ? `${dcc.key} - ${dcc.label}` : dcc.key;
                            var parentLabel = dc.label ? `${dc.key} - ${dc.label}` : dc.key;
                            return {
                                id: dcc.id,
                                displayLabel: displayLabel,
                                parentClassification: parentLabel
                            };
                        }).sort((dcc1, dcc2) => dcc1.displayLabel.localeCompare(dcc2.displayLabel));
                    });
            });

        if (this.model && this.model.isNew) {
            this.model.data.title = this.model.data.title || 'May we respond\nto you about this?';
            this.model.data.nextCaption = this.model.data.nextCaption || 'All done';
            this.model.data.otherNextCaption = this.model.data.otherNextCaption || 'Next';
            this.model.data.options = this.model.data.options || [];
            this.model.data.isRandomized = true;
        } else {
            //this is not a new activity

            //load the existing multi-choice data classifications for this entity (if any exist)
            // load all the classifications
            this.$http.get(`/rateit/api/device-profiles/${this.deviceProfile.id}/activities/${this.model.id}/multi-choice-data-classifications`)
                .then(r => {
                    this.optionClassificationsMap = {};
                    // build a map of option id's to data classification ids
                    r.data.forEach(mcdc => {
                        this.optionClassificationsMap[mcdc.optionId] = mcdc.dataClassificationId;
                    });
                });
        }

        if (!this.model.oldId) {
            this.model.oldId = this.model.id;
        }

        this.$scope.$watch(() => this.model.data.selectionMode, (n) => {
            if (n === 'Multi') {
                this.model.data.options = this.model.data.options.filter(o => !o.isOther);
            }
        });

        this.$scope.$watch(() => this.model.data.layoutType, (n) => {
            if (n === 'Grid') {
                this.model.data.options = this.model.data.options.filter(o => !o.isOther);
            }
        });

        this.$scope.$watchCollection(() => this.optionClassificationsMap, (newVal, oldVal) => {
            for (const key in newVal) {
                if (newVal.hasOwnProperty(key)) {
                    this.nonProfileData[key] = newVal[key];
                }
            }

            //remove any in the non-profile data that aren't in the new value
            for (const key in this.nonProfileData) {
                if (!newVal.hasOwnProperty(key)) {
                    delete this.nonProfileData[key];
                }
            }
        });

        var showOptOutMessage = () => this.dataIntegrityEnforcementStatus === dataIntegrityEnforcementStatus.CAN_OPT_OUT;
        this.showOptOutMessage = showOptOutMessage;

        var showNonOptOutMessage = () => this.dataIntegrityEnforcementStatus === dataIntegrityEnforcementStatus.CANNOT_OPT_OUT;
        this.showNonOptOutMessage = showNonOptOutMessage;

        var upgradeDataEnforcementIntegrityStatus = (upgradeToStatus) => {
            if (this.dataIntegrityEnforcementStatus < upgradeToStatus) {
                this.dataIntegrityEnforcementStatus = upgradeToStatus;
            }
        }
        this.upgradeDataEnforcementIntegrityStatus = upgradeDataEnforcementIntegrityStatus;

        var updateNavigationRuleLabels = () => {
            if (this.model.navigation.rules) {
                this.model.navigation.rules.forEach(r => {
                    for (const captionMapping of Object.values(this.optionCaptionsMap)) {
                        r.label = r.label.replace(captionMapping.oldCaption, captionMapping.newCaption);
                    }
                });
            }
        }
        this.updateNavigationRuleLabels = updateNavigationRuleLabels;

        var enforceDataIntegrity = () => {
            this.model.id = uuidV4();
            var oldToNew = {};

            this.model.data.options.forEach(o => {
                var oldId = o.id;
                oldToNew[o.id] = uuidV4()
                o.id = oldToNew[o.id];

                var dckId = this.optionClassificationsMap[oldId];
                if (dckId) {
                    delete this.optionClassificationsMap[oldId];
                    this.optionClassificationsMap[o.id] = dckId
                }
            });

            if (this.model.navigation.rules) {
                var oldIds = Object.keys(oldToNew);
                this.model.navigation.rules.forEach(r => {
                    oldIds.forEach(id => {
                        var idx = r.or.choice.indexOf(id);
                        if (idx > -1) {
                            r.or.choice[idx] = oldToNew[id];
                        }
                    });
                });
            }

            this.dataIntegrityEnforcementStatus = dataIntegrityEnforcementStatus.NO_OPT_OUT_REQUIRED;
        }
        this.enforceDataIntegrity = enforceDataIntegrity;

        this.$scope.$watch(() => this.model.data.title, (n, o) => {
            if (n !== o) {
                this.upgradeDataEnforcementIntegrityStatus(dataIntegrityEnforcementStatus.CAN_OPT_OUT);
            }
        });

        this.$scope.$watch(() => this.model.data.subtitle, (n, o) => {
            if (n !== o) {
                this.upgradeDataEnforcementIntegrityStatus(dataIntegrityEnforcementStatus.CAN_OPT_OUT);
            }
        });

        this.$scope.$watch(() => this.model.data.options.length, (n, o) => {
            if (n !== o) {
                this.upgradeDataEnforcementIntegrityStatus(dataIntegrityEnforcementStatus.CANNOT_OPT_OUT);
            }
        });
    }

    downOption(option) {
        var idx = this.model.data.options.indexOf(option);
        var replace = this.model.data.options[idx + 1];
        this.model.data.options[idx + 1] = this.model.data.options[idx];
        this.model.data.options[idx] = replace;
    }

    upOption(option) {
        var idx = this.model.data.options.indexOf(option);
        var replace = this.model.data.options[idx - 1];
        this.model.data.options[idx - 1] = this.model.data.options[idx];
        this.model.data.options[idx] = replace;
    }

    editOption(option) {
        this.caption = option.caption;
        this.isOther = option.isOther;
        this.imageUrl = option.imageUrl;
        this.dataClassificationId = this.optionClassificationsMap[option.id];
        this.option = option;
    }

    cancelOption() {
        delete this.option;

        this.caption = null;
        this.isOther = null;
        this.imageUrl = null;
        this.dataClassificationId = null;
    }

    hasOther() {
        if (this.option && this.option.isOther) {
            return false;
        }

        if (this.model.data.options.some(o => o.isOther)) {
            return true;
        }

        return false;
    }

    addOption() {
        var option = this.option || {
            caption: this.caption,
            isOther: this.isOther,
            imageUrl: this.imageUrl,
            id: uuidV4()
        };

        if (this.option) {
            if (option.caption !== this.caption) {
                var existingMapping = this.optionCaptionsMap[option.id];

                if (existingMapping === undefined) {
                    // No mapping exists, so create one.
                    this.optionCaptionsMap[option.id] = {
                        oldCaption: option.caption,
                        newCaption: this.caption
                    };
                } else {
                    // A mapping already exists, so the user changed the caption more than once before saving. Thus, we only want
                    // to update the new caption - the old caption should retain its original value.
                    existingMapping.newCaption = this.caption;
                }
            }

            if (option.caption !== this.caption || option.isOther !== this.isOther) {
                this.upgradeDataEnforcementIntegrityStatus(dataIntegrityEnforcementStatus.CAN_OPT_OUT);
            }

            option.caption = this.caption;
            option.isOther = this.isOther;
            option.imageUrl = this.imageUrl;
            delete this.option;
        }
        else
            this.model.data.options.push(option);

        this.optionClassificationsMap[option.id] = this.dataClassificationId;

        //clears the form
        this.cancelOption();
    }

    removeOption(option) {
        if (this.model.data.options.indexOf(option) > -1) {
            if (this.option && this.option.id === option.id) {
                delete this.option;
                this.caption = this.isOther = this.imageUrl = null;
            }

            delete this.optionClassificationsMap[option.id];
            this.model.data.options.splice(this.model.data.options.indexOf(option), 1);

            if (this.model.navigation && this.model.navigation.rules) {
                var remove = [];
                this.model.navigation.rules.forEach(r => {
                    if (r.or) {
                        if (r.or[option.id]) {
                            delete r.or[option.id];
                        }

                        Object.keys(r.or).forEach(k => {
                            if (r.or[k].indexOf(option.id) > -1) {
                                r.or[k].splice(r.or[k].indexOf(option.id), 1);

                                if (r.or[k].length === 0) {
                                    delete r.or[k];
                                }
                            }
                        });

                        if (Object.keys(r.or).length === 0) {
                            remove.push(r);
                        }
                    }
                });

                remove.forEach(r => {
                    this.model.navigation.rules.splice(this.model.navigation.rules.indexOf(r), 1);
                });
            }
        }
    }

    getClassificationKey(option) {
        if (!this.optionClassificationsMap[option.id]) {
            return;
        }

        var dck = this.dataClassifications.find(dck => dck.id === this.optionClassificationsMap[option.id]);
        return dck && dck.displayLabel;
    }
}

MultiChoiceController.$inject = ['$scope', '$http'];

export default MultiChoiceController;