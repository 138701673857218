import controller from './company-create-controller';
import template from './company-create.html';
import './company-create.scss';

function companyCreateDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
        }
    }
}

export default companyCreateDirective;