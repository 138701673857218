import screenTypeOptions from './screen-type-options';

class DeviceProfileDetailController {
    constructor ($state) {
        this.$state = $state;
        this.screenTypeOptions = screenTypeOptions;
    }

    $onInit () {

    }

    usedTypes () {        
        var list = [];
        var types = this.model.definition.activities.reduce((acc, v, i) => {
            if (list.indexOf(screenTypeOptions[v.type]) === -1){
                acc.push({type:v.type, label: screenTypeOptions[v.type].label});
                list.push(screenTypeOptions[v.type]);
            }

            return acc;
        }, []);

        return types;
    }

    checkRules (rules) {
        return rules.every(x=> {
            return !!x.targetId;
        });
    }

    isOrphaned (activity) {
        if (activity.id === this.model.definition.initialActivityId) {
            return false;
        }

        var orphaned = true;

        this.model.definition.activities.forEach((a) => {
            if (a.navigation.defaultTargetId === activity.id) {
                orphaned = false;
            }

            if (a.navigation.rules) {
                a.navigation.rules.forEach(r => {
                    if (r.targetId === activity.id) {
                        orphaned = false;
                    }
                })
            }
        });

        return orphaned;
    }

    edit (activity) {
        this.$state.go('.screenEdit', {deviceProfileId: this.model.id, activityId: activity.id});
    }

    filterActivities (a) {
        return (this.query === undefined || this.query === null || this.query.length === 0 || a.label.toLowerCase().indexOf(this.query.toLowerCase()) > -1)
            && (this.queryType === undefined || this.queryType === null || a.type === this.queryType);
    }

    orderActivities (a, b) {
        a = a.value;
        b = b.value;
        
        if (a.id === this.model.definition.initialActivityId) {
            return -1;
        }

        if (b.id === this.model.definition.initialActivityId) {
            return 1;
        }

        if (a.navigation && (a.navigation.defaultTargetId === '00000000000000000000000000000001' || a.navigation.defaultTargetId === '00000000-0000-0000-0000-000000000001')) {
            return 1;
        }
        
        if (a.percentageCompletion === b.percentageCompletion)
            return 0;

        if (a.percentageCompletion > b.percentageCompletion) {
            return 1;
        }

        return -1;
    }

    delete (activity) {
        this.$state.go('.screenDelete', {deviceProfileId: this.model.id, activityId: activity.id});
    }
}

DeviceProfileDetailController.$inject = ['$state'];

export default DeviceProfileDetailController;