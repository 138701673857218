function validJSONValidator () {
  return {
    require: 'ngModel',
    link: function(scope, elm, attrs, ctrl) {
      ctrl.$validators.json = function compare (modelValue, viewValue) {
        if (ctrl.$isEmpty(modelValue)) {
          // consider empty models to be valid
          return true;
        }
        
        try {
            JSON.parse(viewValue);
            return true;
        }
        catch (m) {
            return false;
        }
      }      
    }
  };
}

export default validJSONValidator;
