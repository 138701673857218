class CompanyCreateDemoController {
  constructor($http, $state, $scope, moment) {
    this.$http = $http;
    this.$state = $state;
    this.$scope = $scope;
    this.moment = moment;

    this.today = new Date();
    this.maxExpiry = this.moment().add(120, 'days').toDate();
  }

  $onInit() {
    this.setExpiryDate = this.company.isDemo && this.company.demoExpiryDate;

    this.$scope.$watch(() => this.company.isDemo, (newValue, oldValue) => {
      if (newValue === oldValue) { return; }

      this.setExpiryDate = newValue;
    });


    this.$scope.$watch(() => this.setExpiryDate, (newValue, oldValue) => {
      if (newValue === oldValue) { return; }

      //if we are enabling the setExpiryDate and the expiry date is not defined
      //we set a default of 30 days.
      if (newValue && !this.company.demoExpiryDate) {
        this.company.demoExpiryDate = this.moment().add(30, 'days').toDate();
      }
      //if we are disabled the expiry date, remove any existing value
      if (!newValue && this.company.demoExpiryDate) {
        delete this.company.demoExpiryDate;
      }
    });
  }
}

CompanyCreateDemoController.$inject = ['$http', '$state', '$scope', 'moment'];

export default CompanyCreateDemoController;