import controller from './device-profile-detail-controller';
import template from './device-profile-detail.html';
import './device-profile-detail.scss';

function deviceProfileDetailDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            model: '<deviceProfile'
        }
    }
}

export default deviceProfileDetailDirective;