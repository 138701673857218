import controller from './demo-rating-import-controller';
import template from './demo-rating-import.html';
import './demo-rating-import.scss';

function demoRatingImportDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            companyId: '='
        }
    }
}

export default demoRatingImportDirective;