function formValDirective ($compile) {
    return {
        restrict: 'E',
        controllerAs: 'vm',
        require: '^form',
        bindToController: true,
        scope: {
            valExpression: '=',
            name: '@'
        },
        controller: function () {},
        link: function (scope, element, attrs, ctrl) {
            var controller = $compile(`<input ng-model="dummy" name="${scope.vm.name}">`)(scope.$new()).controller('ngModel');
            controller.$validators.valExpression = () => {
                return scope.vm.valExpression;
            };

            ctrl.$addControl(controller);

            scope.$watch(() => {
                return scope.vm.valExpression ? 10 : 0
            }, (v) => {
                controller.$setValidity('valExpression', v === 10);
            });
        }
    }
}

formValDirective.$inject = ['$compile'];

export default formValDirective;