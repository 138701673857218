import controller from './web-action-controller';
import template from './web-action.html';
import './web-action.scss';

function webActionDirective () {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            model: '='
        },
        scope: true
    }
}

export default webActionDirective;