import controller from './device-profile-add-controller';
import template from './device-profile-add.html';

function deviceProfileAddDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        scope: {}
    }
}

export default deviceProfileAddDirective;