import angular from 'angular';
import authHttpInterceptor from './auth-http-interceptor';
import rewriteHttpInterceptor from './rewrite-http-interceptor';
import conflict409Interceptor from './conflict-409-interceptor';
import error400Interceptor from './error-400-interceptor';
import error500Interceptor from './error-500-interceptor';
import Config from './config.js';
import uiRouter from '@uirouter/angularjs';
import authContext from './auth-context';

var module = angular.module('ri.admin.common', [uiRouter])
    .service('configuration', Config)
    .service('authContext', authContext)
    .run(['$transitions', '$state', 'authContext', ($transitions, $state, authContext) => {
        $transitions.onStart({}, (trans) => {
            var toState = trans.targetState().state();
            var params = trans.params();
            if (toState.data && toState.data.secure) {
                
                if (!authContext.isLoggedIn()) {
                    return trans.router.stateService.target('ri.admin.public.login');
                }

                if (toState.data.requiredClaim) {
                    var claimValue = toState.data.requiredClaim.value;
                    claimValue = claimValue.replace(/\{([A-Za-z0-9\*]+)\}/g, (match, name) => {
                        return params[name];
                    });

                    if (!authContext.hasClaim(toState.data.requiredClaim.name, claimValue)) {                    
                        return trans.router.stateService.target(trans.from().name);                        
                    }
                }
            }
        });  

        $transitions.onError({}, (trans) => {
            var err = trans.error();
            if (err && err.redirectTarget) {
                return trans.router.stateService.go(err.redirectTarget, err.redirectParams);
            }
        });
    }])
    .config(['$httpProvider', function ($httpProvider) {
    	$httpProvider.interceptors.push(rewriteHttpInterceptor);
        $httpProvider.interceptors.push(authHttpInterceptor);
        $httpProvider.interceptors.push(conflict409Interceptor);
        $httpProvider.interceptors.push(error400Interceptor);
        $httpProvider.interceptors.push(error500Interceptor);
    }]);

export default module;