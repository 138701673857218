import angular from 'angular';

function lengthTrackDirective () {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var target = angular.element(element[0].querySelector('.input,.textarea'));
            
            scope.$watch(() => target.val(), (n) => {
                if (n) {
                    element.attr('data-length', n.length);
                }
                else {
                    element.attr('data-length', 0);
                }                
            });
        }
    }
}

export default lengthTrackDirective;