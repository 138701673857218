class DeviceProfileDraftDiscardController {
    constructor ($http, $state, messages) {
        this.$http = $http;
        this.$state = $state;
        this.messages = messages;
    }

    submit () {
        this.loading = true;
        this.$http.post(`/rateit/api/device-profiles/${this.deviceProfile.id}/discard`)
            .then (r => {
                this.messages.showSuccessMessage(`Your draft has been discarded successfully.`, null, -1);
                this.$state.go('^', {deviceProfileId: r.data.id});
            }, () => this.loading = false);
    }
}

DeviceProfileDraftDiscardController.$inject = ['$http', '$state', 'messages'];

export default DeviceProfileDraftDiscardController;