class MessagesBoundaryController {
    constructor ($scope, messages) {
        this.$scope = $scope;
        this.messages = messages;
        this.enabled = true;
        this.messages.incrementNesting();
        this.depth = this.messages.depth;
    }

    $onInit () {
        this.$scope.$on('$destroy', () => {
            this.messages.decrementNesting();
        });
    }

    enable () {
        this.enabled = true;
    }

    disable () {
        this.enabled = false;
    }
}

MessagesBoundaryController.$inject = ['$scope', 'messages'];

export default MessagesBoundaryController;