import angular from 'angular';
import common from './common/module';
import adminUsers from './admin-users/module';
import deviceProfiles from './device-profiles/module';
import login from './login/module';
import companies from './companies/module';
import applicationSettings from './application-settings/module';
import dataClassification from './data-classification/module';
import uiRouter from '@uirouter/angularjs';
import uiCommon from './ui-common/module';
//import submitter from './submitter/module';
import './style/app.scss';
//import 'angular-i18n/angular-locale_en-au';
import dashboard from './dashboard/module';
import appTemplate from './app.html';
import publicTemplate from './public.html';

angular.module('ri.admin', [common.name, uiCommon.name, dashboard.name, adminUsers.name, login.name, deviceProfiles.name, companies.name, applicationSettings.name, dataClassification.name, /*submitter.name,*/ uiRouter])
    //.config(($locationProvider) => {
    //$locationProvider.html5Mode(true);
    //})
    .run(['$location', ($location) => {
        var absUrl = $location.absUrl();
        if (absUrl.toLowerCase().indexOf('?returnurl=') > -1) {
            window.location.href = absUrl.substring(0, absUrl.toLowerCase().indexOf('?returnurl=')) + '#!/login';
        }
    }])
    .config(['$stateProvider', '$urlRouterProvider', '$urlMatcherFactoryProvider', ($stateProvider, $urlRouterProvider, $urlMatcherFactoryProvider) => {
        $urlRouterProvider.otherwise('/');
        //$urlMatcherFactoryProvider.strictMode(false);
        $urlRouterProvider.rule(function ($injector, $location) {
            var path = $location.url();

            // check to see if the path has a trailing slash
            if ('/' === path[path.length - 1]) {
                return path.replace(/\/$/, '');
            }

            if (path.indexOf('/?') > 0) {
                return path.replace('/?', '?');
            }

            return false;
        });

        $stateProvider
            .state('ri', {
                abstract: true,
                template: '<ui-view></ui-view>'
            })
            .state('ri.admin', {
                abstract: true,
                template: '<ui-view></ui-view>'
            })
            .state('ri.admin.public', {
                abstract: true,
                template: publicTemplate
            })
            .state('ri.admin.secure', {
                url: '/',
                template: appTemplate,
                data: {
                    secure: true,
                    title: 'Dashboard',
                    icon: 'dashboard'
                },
                controller: ['$window', ($window) => {
                    if (WP_IS_PROD) {
                        //$window.document.location.href = '/';
                    }
                }]
            });
    }])
    .config(['$compileProvider', function ($compileProvider) {
        $compileProvider.debugInfoEnabled(!WP_IS_PROD);
    }])
    .run(['$rootScope', '$transitions', ($rootScope, $transitions) => {
        $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams) {
            console.log('$stateChangeError - fired when an error occurs during transition.');
            console.log(arguments);
        });

        $rootScope.$on('$stateNotFound', function (event, unfoundState, fromState, fromParams) {
            console.log('$stateNotFound ' + unfoundState.to + '  - fired when a state cannot be found by its name.');
            console.log(unfoundState, fromState, fromParams);
        });
    }]);