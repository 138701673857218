import uuidV4 from 'uuid/v4';
import angular from 'angular';

class DeviceProfileEditStep1Controller {
    constructor ($state, $http, messages, $scope) {
        this.model = {};
        this.$state = $state;
        this.$http = $http;
        this.messages = messages;
        this.i18nModel = {};

        $scope.$watch(() => this.model.definition.skipType, (val) => {
            if (val) {
                if (!this.model.definition.finishTargetId && (val === 'SkipThenFinish' || val === 'FinishOnly')) {
                    if (this.thanksActivities.length === 1) {
                        this.model.definition.finishTargetId = this.thanksActivities[0].id;
                    }
                }
            }
        })
    }

    $onInit () {
        this.model = this.deviceProfile;

        this.thanksActivities = this.model.definition.activities.filter(a => {
            return a.type.toLowerCase() === 'thanks';
        });
    }

    addLanguage () {
        var key = `${this.i18nModel.codeLeft.toLowerCase()}-${this.i18nModel.codeRight.toUpperCase()}`;
        var existingDefinition = this.model.definition.languages.find(x=>x.key.toLowerCase() === key.toLowerCase());
        if (existingDefinition) {
            return;
        }

        var language = {
            key: key,
            name: this.i18nModel.name,
            translations: {}
        }

        this.model.definition.languages.push(language);
        this.i18nModel = {};
    }

    removeLanguage (language) {
        var index = this.model.definition.languages.indexOf(language);
        this.model.definition.languages.splice(index, 1);
    }

    moveUp (language) {
        var index = this.model.definition.languages.indexOf(language);
        this.model.definition.languages.splice(index, 1);
        this.model.definition.languages.splice(index-1, 0, language);
    }

    moveDown (language) {
        var index = this.model.definition.languages.indexOf(language);
        this.model.definition.languages.splice(index, 1);
        this.model.definition.languages.splice(index+1, 0, language);
    }
}

DeviceProfileEditStep1Controller.$inject = ['$state', '$http', 'messages', '$scope'];

export default DeviceProfileEditStep1Controller;