class MessagesService {
    constructor () {
        this.depth = 0;
    }

    showErrorMessage (message, model, nestingModifier) {
        this.setMessage('danger', message, model, nestingModifier);
    }

    showWarningMessage (message, model, nestingModifier) {
        this.setMessage('warning', message, model, nestingModifier);
    }

    showSuccessMessage (message, model, nestingModifier) {
        this.setMessage('success', message, model, nestingModifier);
    }

    showInfoMessage (message, model, nestingModifier) {
        this.setMessage('info', message, model, nestingModifier);
    }

    setMessage(type, text, model, nestingModifier) {
        if (nestingModifier === undefined || nestingModifier === null) {
            nestingModifier = 0;
        }

        this.message = { type, text, model, nesting: this.depth + nestingModifier };
    }

    clearMessage () {
        this.message = null;
    }

    incrementNesting () {
        this.depth++;

        if (this.message && this.message.clear) {
            this.clearMessage();
        }
    }

    decrementNesting () {
        this.depth--;
    }
}

MessagesService.$inject = [];

export default MessagesService;