import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uicommon from '../ui-common/module';
import headerTemplate from './hero.html';
import dataClassificationIndexDirective from './data-classification-index-directive';
import dataClassificationAddEditDirective from './data-classification-add-edit-directive';
import dataClassificationDetailDirective from './data-classification-detail-directive';
import multiChoiceSetExistingDirective from './multi-choice-set-existing-directive';

export default angular.module('ri.admin.dataClassification', [uiRouter, uicommon.name])
    .directive('classificationIndex', dataClassificationIndexDirective)
    .directive('classificationAddEdit', dataClassificationAddEditDirective)
    .directive('classificationDetail', dataClassificationDetailDirective)
    .directive('multiChoiceSetExisting', multiChoiceSetExistingDirective)
    .config(['$stateProvider', '$urlRouterProvider', ($stateProvider) => {
        $stateProvider
            .state('ri.admin.secure.dataClassification',
                {
                    url: '^/data-classifications',
                    data: {
                        title: 'Data Classifications',
                        secure: true,
                        icon: 'gear'
                    },
                    params: {
                        reload: false
                    },
                    views: {
                        'hero@ri.admin.secure': {
                            template: headerTemplate
                        },
                        'content@ri.admin.secure': {
                            template: '<classification-index classifications="classifications"></classification-index>',
                            controller: ['$scope', 'classifications', function ($scope, classifications) {
                                $scope.classifications = classifications;
                            }]
                        }
                    },
                    resolve: {
                        classifications: ['$http', function ($http) {
                            return $http.get(`/rateit/api/data-classifications`).then(r => r.data);
                        }]
                    }
                })
            .state('ri.admin.secure.dataClassification.add', {
                url: '/add',
                data: {
                    title: 'Add Data Classification',
                    secure: true,
                    modal: true
                },
                views: {
                    'dialog@ri.admin.secure.dataClassification': {
                        template: '<classification-add-edit></classification-add-edit>'
                    }
                }
            })
            .state('ri.admin.secure.dataClassification.detail', {
                url: '/{classificationId}',
                data: {
                    title: 'View Data Classification',
                    secure: true,
                    modal: true
                },
                views: {
                    'dialog@ri.admin.secure.dataClassification': {
                        template: '<classification-detail classification="classification"></classification-detail>',
                        controller: ['$scope', 'classification', function ($scope, classification) {
                            $scope.classification = classification;
                        }]
                    }
                },
                resolve: {
                    classification: ['$q', 'messages', '$stateParams', '$http', function ($q, messages, $stateParams, $http) {
                        var id = $stateParams.classificationId;

                        return $http.get(`/rateit/api/data-classifications/${id}`)
                            .then(r => {
                                return r.data;
                            }, (err) => {
                                if (err.status === 404) {
                                    err.redirectTarget = 'ri.admin.secure.dataClassification';
                                    err.redirectParams = {};

                                    messages.showErrorMessage('The requested data classification was not found.');
                                }
                                return $q.reject(err);
                            });
                    }]
                }
            })
            .state('ri.admin.secure.dataClassification.detail.edit', {
                url: '/edit',
                data: {
                    title: 'Edit Data Classification',
                    secure: true,
                    modal: true
                },
                views: {
                    'dialog@ri.admin.secure.dataClassification': {
                        template: '<classification-add-edit classification="classification"></classification-add-edit>',
                        controller: ['$scope', 'classification', function ($scope, classification) {
                            var localClassification = Object.assign({}, classification);
                            localClassification.children = classification.children.map(c => Object.assign({}, c));

                            $scope.classification = localClassification;
                        }]
                    }
                },
                resolve: {
                    classification: ['$q', 'messages', '$stateParams', '$http', 'classifications', function ($q, messages, $stateParams, $http, classifications) {
                        if ($stateParams.classification) {
                            return $stateParams.classification;
                        }

                        var id = $stateParams.classificationId;

                        var classification = classifications.find(c => c.id === id);
                        if (classification) {
                            return classification;
                        }

                        //we can't find the setting in the list of settings, show an error
                        messages.showErrorMessage('The requested data classification was not found.', undefined, -1);
                        return $q.reject(`Invalid data classification'${id}'`);
                    }]
                }
            }).state('ri.admin.secure.dataClassification.multiChoice',
                {
                    url: '/multi-choice',
                    data: {
                        title: 'Multi Choice Data Classifications',
                        secure: true,
                        icon: 'gear'
                    },
                    params: {
                        reload: false
                    },
                    views: {
                        'hero@ri.admin.secure': {
                            template: headerTemplate
                        },
                        'content@ri.admin.secure': {
                            template: '<multi-choice-set-existing mc-options="mcOptions" classifications="classifications"></multi-choice-set-existing>',
                            controller: ['$scope', 'mcOptions', 'classifications', function ($scope, mcOptions, classifications) {
                                $scope.mcOptions = mcOptions;
                                $scope.classifications = classifications;
                            }]
                        }
                    },
                    resolve: {
                        mcOptions: ['$http', function ($http) {
                            return $http.get(`/rateit/api/data-classifications/multi-choice-options`).then(r => r.data);
                        }],
                        classifications: ['$http', function ($http) {
                            return $http.get(`/rateit/api/data-classifications`).then(r => {
                                return r.data.filter(dc => dc.children && dc.children.length > 0)
                                    .sort((dc1, dc2) => dc1.key.localeCompare(dc2.key))
                                    .flatMap(dc => {
                                        return dc.children.map(dcc => {
                                            var displayLabel = dcc.label ? `${dcc.key} - ${dcc.label}` : dcc.key;
                                            var parentLabel =  dc.label ? `${dc.key} - ${dc.label}` : dc.key;
                                            return {
                                                id: dcc.id,
                                                displayLabel: displayLabel,
                                                parentClassification: parentLabel
                                            };
                                        }).sort((dcc1, dcc2) => dcc1.displayLabel.localeCompare(dcc2.displayLabel));
                                    });
                            });;
                        }]
                    }
                });
    }]);