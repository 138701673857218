class CompanyIndexController {
    constructor($http, SearchModel, $state, $scope) {
        this.loading = true;
        this.$http = $http;
        this.$state = $state;
        this.$scope = $scope;
    }

    $onInit() {
        this.items = this.companies.items;

        this.$http.get('/rateit/api/countries')
            .then(xhr => {
                this.countries = xhr.data.results;
            })
    }
}

CompanyIndexController.$inject = ['$http', 'SearchModel', '$state', '$scope'];

export default CompanyIndexController;