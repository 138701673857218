class AdminUserEditController {
    constructor ($http, $state, $q, messages) {
        this.$http = $http;
        this.$state = $state;
        this.$q = $q;
        this.messages = messages;        
    }

    $onInit () {
        this.modalLoading = true;
        this.$q.all([
            this.$http.get('/rateit/api/apps/admin/users/claimsets'),
            this.$http.get(`/rateit/api/apps/admin/users/${this.username}`)
        ])
        .then(r => {            
            this.model = r[1].data;
            var claims = this.model.claims;
            this.model.claims = [];
            claims.forEach((x) => {
                this.model.claims.push(x.name + '=' + x.value);
            });

            this.claimSets = r[0].data;

            this.model.claimsEdited = [];
            this.claimSets.forEach(cs => {
                var claims = cs.claims.map(c => {
                    return c.split('=')[0];
                });

                if (cs.children)
                    cs.children.forEach(ch => {
                        ch.claims.map(c => {
                            return c.split('=')[0];
                        }).forEach(c=>{
                            claims.push(c)
                        })
                    });

                claims.forEach(c=> {
                    this.model.claimsEdited.push(c);
                })
            });

            this.model.claimsEdited = this.model.claimsEdited.filter((v, i, arr) => {
                 return arr.indexOf(v) === i;
            });

            this.modalLoading = false;
        });
    }

    claimCompare(claim) {
        return this.model.claims.indexOf(claim) > -1;        
    }

    toggleClaim(set) {
        var claimSet = set.claims;
        var on = claimSet.every(this.claimCompare, this);
        if (on) {
            claimSet.forEach(c => { 
                var currentIndex = this.model.claims.indexOf(c);
                if (currentIndex > -1) {
                    this.model.claims.splice(currentIndex, 1);
                }
            });

            (set.children || []).forEach(s => { 
                s.claims.forEach(c => {
                    var currentIndex = this.model.claims.indexOf(c);
                    if (currentIndex > -1) {
                        this.model.claims.splice(currentIndex, 1);
                    }
                });                
            });            
        }
        else {
            claimSet.forEach(c => { 
                this.model.claims.push(c);
            });
        }
    }

    submit () {
        this.loading = true;
        var model = Object.assign({}, this.model);
		model.claims = [];
		this.model.claims.forEach((x) => {
			var pair = x.split('=');
			model.claims.push({name: pair[0], value: pair[1]});
		});

		return this.$http.put(`/rateit/api/apps/admin/users/${this.username}`, model)
			.then((response) => {
				var data = response.data;
				
                this.messages.showSuccessMessage(`The administrator account has been updated.`);
                if (this.$state.is('ri.admin.secure.adminUsers.detail.edit')) {
                    this.$state.go('^', null, {reload: true});
                }
			}, (err) => {
                this.loading = false;
            });
    }
}

AdminUserEditController.$inject = ['$http', '$state', '$q', 'messages']

export default AdminUserEditController;