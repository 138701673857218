import controller from './company-user-delete-controller';
import template from './company-user-delete.html';

function companyUserDeleteDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            user: '<',
            company: '<'
        }
    }
}

export default companyUserDeleteDirective;