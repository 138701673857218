import controller from './device-profile-export-controller';
import template from './device-profile-export.html';

function deviceProfileExportDirective () {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            deviceProfile: '<'
        }
    }
}

export default deviceProfileExportDirective;