import controller from './device-profile-screen-edit-controller';
import template from './device-profile-screen-edit.html';

function deviceProfileScreenEditDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'modalController',
        bindToController: true,
        scope: {
            deviceProfile: '<',
            model: '<activity',
        }
    }
}

export default deviceProfileScreenEditDirective;