import controller from './autocomplete-controller';
import template from './autocomplete.html';
import './autocomplete.scss';

function autocompleteDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        transclude: true,
        scope: {
            autoFunc: '<',
            displayTransform: '<',
            valueTransform: '<?',
            onSelect : '<?'
        },
        require: ['autoComplete', 'ngModel'],
        link: function (scope, element, attrs, ctrl) {
            var autocompleteController = ctrl[0];
            autocompleteController.ngModel = ctrl[1];
        }
    }
}

export default autocompleteDirective;