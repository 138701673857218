import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uicommon from '../ui-common/module';
import ngQuickGrid from 'ng-quick-grid';
import adminUserIndexDirective from './admin-user-index-directive';
import adminUserDetailDirective from './admin-user-detail-directive';
import adminUserEditDirective from './admin-user-edit-directive';
import adminUserAddDirective from './admin-user-add-directive';
import adminUserDeleteDirective from './admin-user-delete-directive';
import headerTemplate from './hero.html';

var module = angular.module('ri.admin.adminUsers', [uiRouter, uicommon.name, 'ngQuickGrid'])
    .directive('adminUserIndex', adminUserIndexDirective)
    .directive('adminUserDetail', adminUserDetailDirective)
    .directive('adminUserEdit', adminUserEditDirective)
    .directive('adminUserAdd', adminUserAddDirective)
    .directive('adminUserDelete', adminUserDeleteDirective)
    .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
            .state('ri.admin.secure.adminUsers', {
                url: '^/admin-users',
                data: {
                    title: 'Administrators',
                    secure: true,
                    requiredClaim: {name: 'Admin_Us.Vi', value: '*'}
                },
                params: {
                    reload: false
                },
                views: {
                    'hero@ri.admin.secure': {
                        template: headerTemplate
                    },
                    'content@ri.admin.secure': {
                        template: '<admin-user-index></admin-user-index>'
                    }
                }
            })            
            .state('ri.admin.secure.adminUsers.add', {
                url: '/add',
                views: {
                    'dialog@ri.admin.secure.adminUsers': {
                        template: '<admin-user-add></admin-user-add>',
                    }
                },                
                data: {
                    title: 'Add Administrator',
                    modal: true,
                    secure: true,
                    requiredClaim: {name: 'Admin_Us.Ed', value: '*'}
                }
            })
            .state('ri.admin.secure.adminUsers.detail', {
                url: '/:username',
                params: {
                    user: null,
                    dontClearMessages: undefined
                },
                views: {
                    'dialog@ri.admin.secure.adminUsers': {
                        template: '<admin-user-detail username="username" user="user"></admin-user-detail>',
                        controller: ['$stateParams', '$scope', '$state', ($stateParams, $scope, $state) => {
                            $scope.username = $stateParams.username;
                            $scope.user = $stateParams.user;
                        }]
                    }
                },
                data: {
                    title: 'Administrator Detail',
                    modal: true,
                    secure: true,
                    requiredClaim: {name: 'Admin_Us.Vi', value: '{username}'}
                }                
            })
            .state('ri.admin.secure.adminUsers.detail.edit', {
                url: '/edit',
                views: {
                    'dialog@ri.admin.secure.adminUsers': {
                        template: '<admin-user-edit username="username"></admin-user-edit>',
                        controller: ['$stateParams', '$scope', ($stateParams, $scope) => {
                            $scope.username = $stateParams.username;
                        }]
                    }
                },
                data: {
                    title: 'Edit Administrator',
                    modal: true,
                    secure: true,
                    requiredClaim: {name: 'Admin_Us.Ed', value: '{username}'}
                }
            })
            .state('ri.admin.secure.adminUsers.detail.delete', {
                params: {
                    user: null
                },
                views: {
                    'dialog@ri.admin.secure.adminUsers': {
                        template: '<admin-user-delete user="user"></admin-user-delete>',
                        controller: ['$stateParams', '$scope', ($stateParams, $scope) => {
                            $scope.user = $stateParams.user;
                        }]
                    }
                },
                data: {
                    title: 'Edit Administrator',
                    modal: true,
                    secure: true,
                    requiredClaim: {name: 'Admin_Us.Ed', value: '{username}'}
                }
            });
    }]);

export default module;