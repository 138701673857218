import controller from './messages-boundary-controller';

function messagesBoundaryDirective () {
    return {
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        require: ['?^^messagesBoundary', 'messagesBoundary'],
        scope: {},
        link: function (scope, element, attrs, messages) {
            var parentMessages = messages[0];
            var controller = messages[1];
            
            if (parentMessages !== null) {
                parentMessages.disable();
            }

            scope.$on('$destroy', () => {
                if (parentMessages !== null) {
                    parentMessages.enable();
                }
            });
        }
    }
}

export default messagesBoundaryDirective;