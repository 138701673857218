import awesomeSvg from './awesome.svg';
import goodSvg from './good.svg';
import notGreatSvg from './notgreat.svg';
import awfulSvg from './awful.svg';

class CxController {
    constructor () {        
        this.images = {
            awesomeSvg,
            goodSvg,
            notGreatSvg,
            awfulSvg
        };
    }

    $onInit () {
        if (this.model && this.model.isNew) {
            this.model.data.title = this.model.data.title || 'How did we do today?';
            if (!this.model.data.smileyCaptions) {
                this.model.data.smileyCaptions = {
                    awesome: "Awesome",
                    good: "Good",
                    notGreat: "Not Great",
                    awful: "Awful"
                };
            }
        }
    }
}

export default CxController;