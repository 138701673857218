function focusDirective ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            $timeout(() => element[0].focus());
        }
    }
}

focusDirective.$inject = ['$timeout'];

export default focusDirective;