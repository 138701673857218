class DataSource {
    constructor (SearchModel, $scope, list, defaultSort, defer, init) {
        function getData (payload) {
            this.loading = true;
            return list(payload).then(d => {

                // 
                /**
                 * Fixed error when pageCount undefined. . 
                 * Because ng-quick-grid lib only check pageCount !== null
                 * https://github.com/worldspawn/quick-grid
                 * https://github.com/worldspawn/quick-grid/blob/master/src/ng-quick-grid/src/search-model-factory.js
                 * 
                 * updatePaging(result) {
                 *     if (result.pageCount !== null) {
                 *          this.pageCount = Array(result.pageCount);
                 *      }
                 * 
                 *      this.paging.filterHash = result.filterHash;
                 * }
                 * 
                 */

                if(d && d.pageCount === undefined){
                    d.pageCount = null;
                }
                // End

                this.items.splice(0, this.items.length);
                this.items.push.apply(this.items, d.results);

                if (this.firstLoad) {
                    this.firstLoad = false;
                    defer.resolve(this);
                }

                this.loading = false;

                return d;
            });
        }

        this.items = [];
        this.firstLoad = true;
        this.search = new SearchModel(getData.bind(this), defaultSort);
        if (init){ init(this.search); }
        this.search.watch($scope);
        this.search.apply();
    }

    destroy () {
        if(this.search.modelWatchHandler) {
            this.search.modelWatchHandler();
        }
        
        if(this.search.filterWatchHandle) {
            this.search.filterWatchHandle();
        }

        if (this.search.pagingWatchHandle) {
            this.search.pagingWatchHandle();
        }
    }
}

export default DataSource;