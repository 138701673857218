import controller from './device-profile-index-controller';
import template from './device-profile-index.html';

function deviceProfileIndexDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            companyId: '<'
        }
    }
}

export default deviceProfileIndexDirective;