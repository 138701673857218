import controller from './application-setting-edit-controller';
import template from './application-setting-edit.html';

function applicationSettingEditDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            setting: '=',
            result: '='
        }
    }
}

export default applicationSettingEditDirective;