import controller from './application-setting-detail-controller';
import template from './application-setting-detail.html';

function applicationSettingDetailDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            setting: '='
        }
    }
}

export default applicationSettingDetailDirective;