import controller from './company-edit-controller';
import template from './company-edit.html';
import './company-edit.scss';

function companyEditDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            company: '<'
        }
    }
}

export default companyEditDirective;