class CompanyPlacesController {
    constructor($http, SearchModel, $state, $scope) {
        this.$http = $http;
        this.$state = $state;
        this.$scope = $scope;
    }

    $onInit() {
        this.items = this.places.items;
    }

    getRegistrations(placeId, isDevice) {
        return this.registrations.filter(reg => reg.registrationStatus === 'Active' && reg.placeId === placeId && !!reg.device === isDevice);
    }

    hasRegistrations(placeId, isDevice) {
        return this.getRegistrations(placeId, isDevice).length !== 0;
    }
}



CompanyPlacesController.$inject = ['$http', 'SearchModel', '$state', '$scope'];

export default CompanyPlacesController;