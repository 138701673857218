import controller from './categories-controller';
import template from './categories.html';
import './categories.scss';

function categoriesDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            model: '=',
            deviceProfile: '='
        },
        scope: true
    }
}

export default categoriesDirective;