class DeviceProfilePublishV2Controller {
    constructor($http, $state, $scope, messages) {
        this.$http = $http;
        this.messages = messages;
        this.$state = $state;
        this.$scope = $scope;
        this.error = this.$scope.$parent.vm.errorMessage;
        this.activitiesListUI = this.$scope.$parent.vm.model.definition;
        this.places = this.$scope.$parent.vm.model.places;
        this.deviceRegistrations = this.$scope.$parent.vm.model.deviceRegistrations;
    }

    $onInit() {
    }

    submit() {
        this.loading = true;
        this.$http.put(`/rateit/api/device-profiles/v2/${this.deviceProfile.id}/publish`, {
            definition: this.activitiesListUI,
            placeIds: this.places.map(p => p.id),
            deviceRegistrationIds: this.deviceRegistrations.map(p => p.id)
        }).then((r) => {
                this.messages.showSuccessMessage(`This device profile has been successfully published.`, null, -1);
                Object.assign(this.deviceProfile, r.data);
                this.$state.go('^', { deviceProfileId: this.deviceProfile.id });
            }, () => this.loading = false);
    }
}

DeviceProfilePublishV2Controller.$inject = ['$http', '$state', '$scope', 'messages'];

export default DeviceProfilePublishV2Controller;
