import template from './ri-input.html';

function riInputDirective () {
    return {
        template,
        restrict: 'E',
        scope: {},
        require: 'ngModel',
        controllerAs: 'vm',
        bindToController: {
            type: '@?',
            min: '=?',
            max: '=?',
            step: '=?',
            placeholder: '@?',
            disabled: '=?ngDisabled',
            required: '=?ngRequired',
            maxlength: '=?maxlength',
            minlength: '=?ngMinlength',
        },
        controller: ['$scope', '$interpolate', '$attrs', function ($scope, $interpolate, $attrs) {
            this.errors = function () {
                return Object.keys($scope.ctrl.$error).filter(v => v != 'required');
            };

            this.errorText = function (name) {
                return $interpolate($attrs[name + 'Text'].replace('%value%', '{{value}}'))({value: $scope.ctrl.$viewValue});
            };
        }],
        link: function(scope, element, attrs, ngModel) {            
            scope.ctrl = ngModel;

            scope.$watch(() => ngModel.$modelValue, (n) => {
                scope.vm.value = n;
            });

            scope.$watch(() => scope.vm.value, (n, o) => {
                if (n !== o) {
                    ngModel.$setViewValue(n);
                }
            });
        }
    }
}

export default riInputDirective;