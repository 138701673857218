class TitleController {
    constructor ($state, $transitions) {
        $transitions.onSuccess({}, (trans) => {
            var toState = trans.targetState().state();
            if (toState.data && toState.data.title){
                this.title = `RateIt Administration - ${toState.data.title}`;
            }
            else {
                this.title = 'RateIt Administration';
            }
        });
    }
}

TitleController.$inject = ['$state', '$transitions'];

export default TitleController;