class TranslationController {
    constructor ($scope) {
        this.i18nModel = {};
        this.$scope = $scope;        
    }

    $onInit () {
        let profileLanguages = this.model.languages;
        this.keys = Object.keys(profileLanguages[0].translations);
        let buttonKeys = (this.model.webActions['Start'] || []).concat((this.model.webActions['End'] || []))
            .map(b => b.label)
            .filter((v, i, s) => s.indexOf(v) === i);

        this.keys = this.keys.concat(buttonKeys);
        this.language = profileLanguages[0].key;
        this.translations = {};

        this.languages = profileLanguages;
        this.$scope.$watch(() => this.language, (v) => {
            this.selectedTranslations = this.getTranslationModel(v);
        });
    }

    getTranslationModel(key) {
        this.noMatches = [];
        if (!this.translations[key]) {
            let language = this.model.languages.find(l=>l.key === key);
            let translations = language.translations;

            this.translations[key] = translations;
        }

        this.keys.forEach(lkey => {
            if (this.translations[key][lkey] === undefined) {
                this.translations[key][lkey] = lkey;
            }
        });

        return this.translations[key];
    }
}

TranslationController.$inject = ['$scope'];

export default TranslationController;