class RatingDeleteAllController {
    constructor ($http, $scope, $state, messages) {
        this.$http = $http;
        this.$scope = $scope;
        this.$state = $state;
        this.messages = messages;
    }

    $onInit () {
    }

    delete () {
        this.loading = true;
        var path = this.place ? `/rateit/api/apps/admin/companies/${this.company.id}/places/${this.place.id}/ratings`: `/rateit/api/apps/admin/companies/${this.company.id}/ratings`;
        this.$http.delete(path)
            .then((response) => {
                this.messages.showSuccessMessage(`All the ratings have been removed.`, null, -1);
                this.$state.go('^', {}, {reload: true});
            })
            .catch(()=>{this.loading = false});
    }
}

RatingDeleteAllController.$inject = ['$http', '$scope', '$state', 'messages'];

export default RatingDeleteAllController;