import controller from './admin-user-index-controller';
import template from './admin-user-index.html';


function adminUserIndexDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm'
    }
}

export default adminUserIndexDirective;