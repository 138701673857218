import controller from './device-profile-publish-controller';
import template from './device-profile-publish.html';

function deviceProfilePublishDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            deviceProfile: '='
        }
    }
}

export default deviceProfilePublishDirective;