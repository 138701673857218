class CompanyUserDeleteController {
    constructor($http, $scope, $state, messages) {
        this.$http = $http;
        this.$scope = $scope;
        this.$state = $state;
        this.messages = messages;
    }

    $onInit() {
    }

    delete() {
        this.loading = true;

        this.$http.delete(`/rateit/api/companies/${this.company.id}/users/${this.user.id}`)
            .then((response) => {
                this.messages.showSuccessMessage(`A user has been removed.`, null, -1);
                this.$state.go('ri.admin.secure.companies.detail.users', { companyId: this.company.id }, { reload: true });
            })
            .catch(() => { this.loading = false });
    }
}

CompanyUserDeleteController.$inject = ['$http', '$scope', '$state', 'messages'];

export default CompanyUserDeleteController;