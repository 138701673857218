class AdminUserIndexController {
    constructor ($http, SearchModel, $state, $scope) {
        this.loading = true;
        this.$http = $http;
        this.$state = $state;
        this.$scope = $scope;
        this.items = [];
        this.search = new SearchModel(this.listUsers.bind(this), 'DisplayName');        
        this.search.paging.pageIndex = 0;
        this.search.model.query = '';
        this.search.model.hasLoggedIn = null;
        this.search.watch($scope);
    }

    $onInit () {
        this.search.apply();
    }

    listUsers (searchModel) {
        var payload = searchModel.toQueryString();
        this.loading = true;
        return this.$http.get(`/rateit/api/apps/admin/users?${payload}`)
            .then((r) => {
                this.items = r.data.results;                
                this.loading = false;
                return r.data;
            }, () => {this.loading = false});
    }

    open (item) {
        this.$state.go('.detail', {username: item.username});
    }
}

AdminUserIndexController.$inject = ['$http', 'SearchModel', '$state', '$scope'];

export default AdminUserIndexController;