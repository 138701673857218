import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uicommon from '../ui-common/module';
import headerTemplate from './hero.html';
import companyDetailHeaderTemplate from './hero-company-detail.html';
import ratingsPlaceHeaderTemplate from './hero-place-ratings.html';
import companyIndexDirective from './company-index-directive';
import companyCreateDirective from './create-edit/company-create-directive';
import companyEditDirective from './create-edit/company-edit-directive';
import companyDetailsDirective from './create-edit/company-details-directive';
import companyValidationRulesDirective from './create-edit/company-validation-rules-directive';
import companyDemoDirective from './create-edit/company-demo-directive';
import companyDemoRatingImportDirective from './demo-rating-import-directive';
import companyPlacesDirective from './detail-tabs/company-places-directive';
import companyUsersDirective from './detail-tabs/company-users-directive';
import companySettingsDirective from './detail-tabs/company-settings-directive';
import companyUserDetailDirective from './users/company-user-detail-directive';
import companyUserEditDirective from './users/company-user-edit-directive';
import companyUserDeleteDirective from './users/company-user-delete-directive';
import ratingSearchDirective from './rating-search-directive';
import ratingPictureDirective from './rating-picture-directive';
import ratingDeleteAllDirective from './rating-delete-all-directive';
import ratingDeleteOneDirective from './rating-delete-one-directive';
import ratingToggleValidityDirective from './rating-toggle-validity-directive';
import ratingDetailDirective from './rating-detail-directive';
import placeDetailDirective from './places/place-detail-directive';
import DataSource from '../data-source';
import shotty from './create-edit/doom_shotgun.mp3'
import uniqueNameDirective from './create-edit/unique-company-name-directive';

function createViewDefinition(name, path, definition) {
    var view = {};
    view[`${name}@${path}`] = definition;
    return view;
}

function createCommonRatingStates($stateProvider, prefix) {
    $stateProvider.state(`${prefix}.viewphoto`, {
        params: {
            imageUrl: null
        },
        views: createViewDefinition('dialog', prefix, {
            template: '<rating-picture image-url="imageUrl"></rating-detail>',
            controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
                $scope.imageUrl = $stateParams.imageUrl;
            }]
        })
    })
        .state(`${prefix}.toggle`, {
            params: {
                rating: null
            },
            views: createViewDefinition('dialog', prefix, {
                template: '<rating-toggle-validity rating="rating"></rating-toggle-validity>',
                controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
                    $scope.rating = $stateParams.rating;
                }]
            })
        })
        .state(`${prefix}.deleteone`, {
            params: {
                rating: null,
                ratings: null
            },
            views: createViewDefinition('dialog', prefix, {
                template: '<rating-delete-one rating="rating" ratings="ratings"></rating-delete-one>',
                controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
                    $scope.rating = $stateParams.rating;
                    $scope.ratings = $stateParams.ratings;
                }]
            })
        })
        .state(`${prefix}.detail`, {
            data: {
                title: 'Rating Detail',
                secure: true
            },
            params: {
                rating: null,
                deviceProfile: null
            },
            views: createViewDefinition('dialog', prefix, {
                template: '<rating-detail device-profile="deviceProfile" rating="rating"></rating-detail>',
                controller: ['$scope', '$stateParams', function ($scope, $stateParams) {
                    $scope.rating = $stateParams.rating;
                    $scope.deviceProfile = $stateParams.deviceProfile;
                }]
            })
        });
}

function createPlaceRatingStates($stateProvider) {
    $stateProvider.state('ri.admin.secure.companies.detail.places.detail.ratings', {
        url: '/ratings',
        data: {
            title: 'Ratings',
            secure: true,
            icon: 'flag'
        },
        views: {
            'hero@ri.admin.secure': {
                template: ratingsPlaceHeaderTemplate,
                controller: ['company', 'place', '$scope', 'authContext', (company, place, $scope, authContext) => {
                    $scope.company = company;
                    $scope.place = place;
                    $scope.canManage = authContext.hasClaim('Ra.Ad', `${company.id}|${place.id}`);
                }]
            },
            'content@ri.admin.secure': {
                template: '<rating-search place-id="placeId" company-id="companyId" ratings="ratings"></rating-search>',
                controller: ['$scope', 'ratings', 'companyId', 'placeId', ($scope, ratings, companyId, placeId) => {
                    $scope.ratings = ratings;
                    $scope.companyId = companyId;
                    $scope.placeId = placeId;
                }]
            }
        },
        resolve: {
            ratings: ['$q', '$rootScope', '$http', 'SearchModel', 'companyId', 'placeId', function ($q, $rootScope, $http, SearchModel, companyId, placeId) {
                var defer = $q.defer();

                var ds = new DataSource(SearchModel, $rootScope, function (searchModel) {
                    var payload = searchModel.toQueryString();
                    return $http.get(`/rateit/api/apps/admin/companies/${companyId}/ratings?${payload}&paging.pageSize=30`)
                        .then((r) => {
                            return r.data;
                        });
                }, 'CaptureDate desc', defer, (sm) => {
                    sm.paging.pageIndex = 0;

                    sm.addFilter('CaptureDate', '>=');//need to allow the specifying of multiple filters targeting the same property
                    sm.addFilter('DeviceRegistration.Id', '=');
                    sm.addFilter('Place.Id', '=');
                    sm.addFilter('Comment', '%');
                    sm.addFilter('IsHumanValidated', '=');
                    sm.addFilter('IsValid', '=');
                    sm.addFilter('HasComment', '=');
                    sm.filters['Place.Id'].value = placeId;
                });

                return defer.promise;
            }]
        },
        onExit: ['ratings', function (ratings) {
            ratings.destroy();
        }]

    })
        .state(`ri.admin.secure.companies.detail.places.detail.ratings.deleteall`, {
            views: createViewDefinition('dialog', 'ri.admin.secure.companies.detail.places.detail.ratings', {
                template: '<rating-delete-all place="place" company="company"></rating-delete-all>',
                controller: ['$scope', 'company', 'place', function ($scope, company, place) {
                    $scope.company = company;
                    $scope.place = place;
                }]
            })
        });

    createCommonRatingStates($stateProvider, 'ri.admin.secure.companies.detail.places.detail.ratings')
}

function createCompanyRatingStates($stateProvider) {
    $stateProvider.state('ri.admin.secure.companies.detail.ratings', {
        url: '/ratings',
        data: {
            title: 'Ratings',
            secure: true,
            icon: 'flag',
            tab: true
        },
        views: {
            'tabContent@ri.admin.secure.companies.detail': {
                template: '<rating-search company-id="companyId" ratings="ratings"></rating-search>',
                controller: ['$scope', 'ratings', 'companyId', 'authContext', ($scope, ratings, companyId, authContext) => {
                    $scope.ratings = ratings;
                    $scope.companyId = companyId;
                    $scope.canManage = authContext.hasClaim('Ra.Ad', `${companyId}|*}`);
                }]
            }
        },
        resolve: {
            ratings: ['$q', '$stateParams', '$rootScope', '$http', 'SearchModel', 'companyId', function ($q, $stateParams, $rootScope, $http, SearchModel) {
                var defer = $q.defer();

                var ds = new DataSource(SearchModel, $rootScope, function (searchModel) {
                    var payload = searchModel.toQueryString();
                    return $http.get(`/rateit/api/apps/admin/companies/${$stateParams.companyId}/ratings?${payload}&paging.pageSize=30`)
                        .then((r) => {
                            return r.data;
                        });
                }, 'CaptureDate desc', defer, (sm) => {
                    sm.paging.pageIndex = 0;

                    sm.addFilter('CaptureDate', '>=');//need to allow the specifying of multiple filters targeting the same property
                    sm.addFilter('Registration.Id', '=');
                    sm.addFilter('Place.Id', '=');
                    sm.addFilter('Comment', '%');
                    sm.addFilter('IsHumanValidated', '=');
                    sm.addFilter('IsValid', '=');
                    sm.addFilter('HasComment', '=');
                });

                return defer.promise;
            }]
        },
        onExit: ['ratings', function (ratings) {
            ratings.destroy();
        }]
    })
        .state(`ri.admin.secure.companies.detail.ratings.deleteall`, {
            views: createViewDefinition('dialog', 'ri.admin.secure.companies.detail.ratings', {
                template: '<rating-delete-all company="company"></rating-delete-all>',
                controller: ['$scope', 'company', function ($scope, company) {
                    $scope.company = company;
                }]
            })
        });

    createCommonRatingStates($stateProvider, 'ri.admin.secure.companies.detail.ratings');
}

function createCompanyUserStates($stateProvider) {
    $stateProvider.state('ri.admin.secure.companies.detail.users', {
        url: '/users',
        data: {
            title: 'Users',
            secure: true,
            icon: 'flag',
            tab: true
        },
        views: {
            'tabContent@ri.admin.secure.companies.detail': {
                template: '<company-users users="users" company-id="companyId"></company-users>',
                controller: ['$scope', 'companyId', 'users', function ($scope, companyId, users) {
                    $scope.companyId = companyId;
                    $scope.users = users;
                }]
            }
        },
        resolve: {
            companyId: ['$stateParams', ($stateParams) => {
                return $stateParams.companyId;
            }],
            users: ['$q', '$rootScope', '$http', 'SearchModel', 'companyId', '$transition$', '$state', '$stateParams', function ($q, $rootScope, $http, SearchModel, companyId, transition, $state, $stateParams) {
                var defer = $q.defer();

                var ds = new DataSource(SearchModel, $rootScope, function (searchModel) {
                    var payload = searchModel.toQueryString();
                    return $http.get(`/rateit/api/companies/${companyId}/users?${payload}&paging.pageSize=100`)
                        .then((r) => {
                            return r.data;
                        });
                }, 'FullName', defer, (sm) => {
                    sm.paging.pageIndex = 0;
                    sm.addFilter('FullName', '%');
                    sm.addFilter('IsCompanySuperUser', '=');
                    sm.addFilter('IsDeveloper', '=');
                });

                return defer.promise;
            }]
        },
        onRetain: ['users', function (users) {
            if (users.search.filters['Id']) {
                delete users.search.filters['Id'];
            }
        }],
        onExit: ['users', function (users) {
            users.destroy();
        }]
    })
        .state('ri.admin.secure.companies.detail.users.detail', {
            url: '/{userId}',
            data: {
                title: 'View User',
                secure: true,
                modal: true
            },
            views: {
                'dialog@ri.admin.secure.companies.detail': {
                    template: '<company-user-detail user="user" company-id="companyId"></company-user-detail>',
                    controller: ['$scope', 'companyId', 'user', function ($scope, companyId, user) {
                        $scope.user = user;
                        $scope.companyId = companyId;
                    }]
                }
            },

            resolve: {
                companyId: ['$stateParams', ($stateParams) => {
                    return $stateParams.companyId;
                }],
                userId: ['$stateParams', ($stateParams) => {
                    return $stateParams.userId;
                }],
                user: ['companyId', 'userId', '$http', (companyId, userId, $http) => {
                    return $http.get(`/rateit/api/companies/${companyId}/users/${userId}`)
                        .then(r => {
                            return r.data;
                        });
                }]
            }
        })
        .state('ri.admin.secure.companies.detail.users.detail.edit', {
            url: '/edit',
            data: {
                title: 'Edit User',
                secure: true,
                modal: true
            },
            views: {
                'dialog@ri.admin.secure.companies.detail': {
                    template: '<company-user-edit company="company" user="user" places="places"></company-user-edit>',
                    controller: ['$scope', 'company', 'user', 'places', function ($scope, company, user, places) {
                        $scope.company = company;
                        $scope.user = user;
                        $scope.places = places;
                    }]
                }
            },

            resolve: {
                userId: ['$stateParams', ($stateParams) => {
                    return $stateParams.userId;
                }],
                company: ['companyId', '$http', (companyId, $http) => {
                    return $http.get(`/rateit/api/companies/${companyId}`)
                        .then(r => {
                            return r.data;
                        });
                }],
                user: ['companyId', 'userId', '$http', (companyId, userId, $http) => {
                    return $http.get(`/rateit/api/companies/${companyId}/users/${userId}`)
                        .then(r => {
                            return r.data;
                        });
                }],
                places: ['companyId', '$http', (companyId, $http) => {
                    return $http.get(`/rateit/api/companies/${companyId}/places`)
                        .then(r => {
                            return r.data;
                        });
                }]
            }
        })
        .state('ri.admin.secure.companies.detail.users.detail.delete', {
            url: '/delete',
            data: {
                title: 'Delete User',
                secure: true,
                modal: true
            },
            views: {
                'dialog@ri.admin.secure.companies.detail': {
                    template: '<company-user-delete company="company" user="user"></company-user-delete>',
                    controller: ['$scope', 'company', 'user', function ($scope, company, user) {
                        $scope.company = company;
                        $scope.user = user;
                    }]
                }
            },

            resolve: {
                userId: ['$stateParams', ($stateParams) => {
                    return $stateParams.userId;
                }],
                company: ['companyId', '$http', (companyId, $http) => {
                    return $http.get(`/rateit/api/companies/${companyId}`)
                        .then(r => {
                            return r.data;
                        });
                }],
                user: ['companyId', 'userId', '$http', (companyId, userId, $http) => {
                    return $http.get(`/rateit/api/companies/${companyId}/users/${userId}`)
                        .then(r => {
                            return r.data;
                        });
                }]
            }
        });
}

export default angular.module('ri.admin.companies', [uiRouter, uicommon.name, 'ngQuickGrid'])
    .directive('companyIndex', companyIndexDirective)
    .directive('companyCreate', companyCreateDirective)
    .directive('companyEdit', companyEditDirective)
    .directive('companyDetails', companyDetailsDirective)
    .directive('companyValidationRules', companyValidationRulesDirective)
    .directive('companyDemo', companyDemoDirective)
    .directive('companyDemoRatingImport', companyDemoRatingImportDirective)
    .directive('companyPlaces', companyPlacesDirective)
    .directive('companyUsers', companyUsersDirective)
    .directive('companySettings', companySettingsDirective)
    .directive('companyUserDetail', companyUserDetailDirective)
    .directive('companyUserEdit', companyUserEditDirective)
    .directive('companyUserDelete', companyUserDeleteDirective)
    .directive('placeDetail', placeDetailDirective)
    .directive('ratingSearch', ratingSearchDirective)
    .directive('ratingPicture', ratingPictureDirective)
    .directive('ratingDeleteAll', ratingDeleteAllDirective)
    .directive('ratingDeleteOne', ratingDeleteOneDirective)
    .directive('ratingToggleValidity', ratingToggleValidityDirective)
    .directive('ratingDetail', ratingDetailDirective)
    .directive('uniqueCompanyName', uniqueNameDirective)
    .factory('shotty', function () {
        return shotty;
    })
    .config(['$stateProvider', '$urlRouterProvider', ($stateProvider, $urlRouterProvider) => {
        $stateProvider
            .state('ri.admin.secure.companies', {
                url: '^/companies',
                data: {
                    title: 'Companies',
                    secure: true,
                    icon: 'flag'
                },
                views: {
                    'hero@ri.admin.secure': {
                        template: headerTemplate,
                        controller: ['$scope', 'authContext', ($scope, authContext) => {
                            $scope.canCreate = authContext.hasClaim('Co.Ad', '*');
                        }]
                    },
                    'content@ri.admin.secure': {
                        template: '<company-index companies="companies"></company-index>',
                        controller: ['$scope', 'companies', function ($scope, companies) {
                            $scope.companies = companies;
                        }]
                    }
                },
                resolve: {
                    companies: ['$q', '$rootScope', '$http', 'SearchModel', '$transition$', '$state', '$stateParams', function ($q, $rootScope, $http, SearchModel, transition, $state, $stateParams) {
                        var defer = $q.defer();
                        var stateIsTarget = transition.targetState().state().name === 'ri.admin.secure.companies';

                        var ds = new DataSource(SearchModel, $rootScope, function (searchModel) {
                            var payload = searchModel.toQueryString();

                            return $http.get(`/rateit/api/companies?${payload}&paging.pageSize=50`)
                                .then((r) => {
                                    return r.data;
                                });
                        }, 'Name', defer, (sm) => {
                            sm.paging.pageIndex = 0;
                            sm.addFilter('Name', '%');
                            sm.addFilter('Country.Id', '=');
                            sm.addFilter('IsDemo', '=');
                            sm.filters['IsDemo'].value = false;

                            if (!stateIsTarget) {
                                sm.addFilter('Id', '=');
                                sm.filters['Id'].value = $stateParams.companyId;
                            }
                        });

                        return defer.promise;
                    }]
                },
                onRetain: ['companies', function (companies) {
                    if (companies.search.filters['Id']) {
                        delete companies.search.filters['Id'];
                    }
                }],
                onExit: ['companies', function (companies) {
                    companies.destroy();
                }]
            })
            .state('ri.admin.secure.companies.create', {
                url: '/create',
                abstract: true,
                data: {
                    title: 'Create company',
                    modal: true,
                    secure: true
                },
                views: {
                    'dialog@ri.admin.secure.companies': {
                        template: '<company-create></company-create>',
                        controller: ['$scope', function ($scope) {
                        }]
                    }
                }
            })
            .state('ri.admin.secure.companies.create.step1', {
                url: '',
                views: {
                    'content@ri.admin.secure.companies.create': {
                        template: '<company-details company="vm.company"></company-details>',
                    }
                },
                data: {
                    title: 'Create company',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.companies.create.step2', {
                url: '',
                views: {
                    'content@ri.admin.secure.companies.create': {
                        template: '<company-validation-rules company="vm.company" validation-rules="validationRules"></company-validation-rules>',
                        controller: ['$scope', 'validationRules', function ($scope, validationRules) {
                            $scope.validationRules = validationRules.filter(vr => vr.companyOptIn);
                        }]
                    }
                },
                data: {
                    title: 'Create company',
                    modal: true,
                    secure: true
                },
                resolve: {
                    validationRules: ['$http', ($http) => {
                        return $http.get('/rateit/api/validation-rules')
                            .then(r => r.data);
                    }]
                }
            })
            .state('ri.admin.secure.companies.create.step3', {
                url: '',
                views: {
                    'content@ri.admin.secure.companies.create': {
                        template: '<company-demo company="vm.company"></company-demo>'
                    }
                },
                data: {
                    title: 'Create company',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.companies.detail', {
                url: '/{companyId}',
                data: {
                    title: '{{company.name}}',
                    secure: true,
                    icon: 'flag',
                },
                redirectTo: 'ri.admin.secure.companies.detail.places',
                views: {
                    'hero@ri.admin.secure': {
                        template: companyDetailHeaderTemplate,
                        controller: ['$scope', '$state', 'authContext', 'company', 'features', ($scope, $state, authContext, company, features) => {
                            $scope.$state = $state;
                            $scope.company = company;
                            $scope.features = features;
                            $scope.canEdit = authContext.hasClaim('Co.Ad', `${company.id}`);
                        }]
                    },
                    'content@ri.admin.secure': {
                        template: '<div ui-view="tabContent"></div><div ui-view="dialog"></div>',
                        controller: ['$scope', 'company', function ($scope, company) {
                            $scope.company = company;
                        }]
                    }
                },
                resolve: {
                    companyId: ['$stateParams', ($stateParams) => {
                        return $stateParams.companyId;
                    }],
                    company: ['companyId', '$http', (companyId, $http) => {
                        return $http.get(`/rateit/api/companies/${companyId}`)
                            .then(r => {
                                return r.data;
                            });
                    }],
                    features: ['$http', ($http) => {
                        return $http.get(`/rateit/api/system/features`)
                            .then(r => {
                                return r.data;
                            });
                    }]
                }
            })
            .state('ri.admin.secure.companies.detail.edit', {
                url: '/edit',
                abstract: true,
                data: {
                    title: 'Edit company',
                    modal: true,
                    secure: true
                },
                views: {
                    'dialog@ri.admin.secure.companies.detail': {
                        template: '<company-edit company="company"></company-edit>',
                        controller: ['$scope', 'company', function ($scope, company) {
                            $scope.company = company;
                        }]
                    }
                },
                resolve: {
                    companyId: ['$stateParams', ($stateParams) => {
                        return $stateParams.companyId;
                    }],
                    company: ['companyId', '$http', (companyId, $http) => {
                        return $http.get(`/rateit/api/companies/${companyId}`)
                            .then(r => {
                                //all of the code expects plain list of id's, so we map the object returned here into a plain list
                                var company = r.data;
                                company.rules = company.companyRules.map(cr => cr.validationRuleId);
                                return company;
                            });
                    }]
                }
            })
            .state('ri.admin.secure.companies.detail.edit.step1', {
                url: '',
                views: {
                    'content@ri.admin.secure.companies.detail.edit': {
                        template: '<company-details company="vm.company" is-editing="true"></company-details>',
                    }
                },
                data: {
                    title: 'Edit company',
                    modal: true,
                    secure: true
                }
            })
            .state('ri.admin.secure.companies.detail.edit.step2', {
                url: '',
                views: {
                    'content@ri.admin.secure.companies.detail.edit': {
                        template: '<company-validation-rules company="vm.company" is-editing="true" validation-rules="validationRules"></company-validation-rules>',
                        controller: ['$scope', 'validationRules', function ($scope, validationRules) {
                            $scope.validationRules = validationRules.filter(vr => vr.companyOptIn);
                        }]
                    }
                },
                data: {
                    title: 'Edit company',
                    modal: true,
                    secure: true
                },
                resolve: {
                    validationRules: ['$http', ($http) => {
                        return $http.get('/rateit/api/validation-rules')
                            .then(r => r.data);
                    }]
                }
            })
            .state('ri.admin.secure.companies.detail.places', {
                url: '/places',
                data: {
                    title: 'Locations',
                    secure: true,
                    icon: 'flag',
                    tab: true
                },
                views: {
                    'tabContent@ri.admin.secure.companies.detail': {
                        template: '<company-places places="places" company-id="companyId" registrations="registrations"></company-places>',
                        controller: ['$scope', 'companyId', 'places', 'registrations', function ($scope, companyId, places, registrations) {
                            $scope.companyId = companyId;
                            $scope.places = places;
                            $scope.registrations = registrations;
                        }]
                    }
                },
                resolve: {
                    companyId: ['$stateParams', ($stateParams) => {
                        return $stateParams.companyId;
                    }],
                    places: ['$q', '$rootScope', '$http', 'SearchModel', 'companyId', ($q, $rootScope, $http, SearchModel, companyId) => {

                        var defer = $q.defer();

                        var ds = new DataSource(SearchModel, $rootScope, function (searchModel) {
                            var payload = searchModel.toQueryString();
                            return $http.get(`/rateit/api/companies/${companyId}/places/list?${payload}`)
                                .then((r) => {
                                    return r.data;
                                });
                        }, 'Name', defer, (sm) => {
                            sm.paging.pageIndex = 0;
                            sm.addFilter('Name', '%');
                        });

                        return defer.promise;
                    }],
                    registrations: ['companyId', '$http', (companyId, $http) => {
                        return $http.get(`/rateit/api/companies/${companyId}/registrations`)
                            .then(r => r.data);
                    }]
                }
            })
            .state('ri.admin.secure.companies.detail.deviceProfiles', {
                url: '/device-profiles',
                data: {
                    title: 'Device Profiles',
                    secure: true,
                    icon: 'flag',
                    tab: true
                },
                views: {
                    'tabContent@ri.admin.secure.companies.detail': {
                        template: '<device-profile-index company-id="companyId"></device-profile-index>',
                        controller: ['$scope', 'companyId', function ($scope, companyId) {
                            $scope.companyId = companyId;
                        }]
                    }
                },
                resolve: {
                    companyId: ['$stateParams', ($stateParams) => {
                        return $stateParams.companyId;
                    }]
                }
            })
            .state('ri.admin.secure.companies.detail.settings', {
                url: '/settings',
                data: {
                    title: 'Settings',
                    secure: true,
                    icon: 'flag',
                    tab: true
                },
                views: {
                    'tabContent@ri.admin.secure.companies.detail': {
                        template: '<company-settings company="company" validation-rules="validationRules"></company-settings>',
                        controller: ['$scope', 'company', 'validationRules', function ($scope, company, validationRules) {
                            $scope.company = company;
                            $scope.validationRules = validationRules;
                        }]
                    }
                },
                resolve: {
                    companyId: ['$stateParams', ($stateParams) => {
                        return $stateParams.companyId;
                    }],
                    validationRules: ['$http', ($http) => {
                        return $http.get('/rateit/api/validation-rules')
                            .then(r => r.data);
                    }]
                }
            })
            .state('ri.admin.secure.companies.detail.places.detail', {
                url: '/{placeId}',
                data: {
                    title: '{{place.name}}',
                    secure: true,
                    modal: true
                },
                views: {
                    'dialog@ri.admin.secure.companies.detail': {
                        template: '<place-detail company="company" place="place"></place-detail>',
                        controller: ['$scope', 'company', 'place', function ($scope, company, place) {
                            $scope.place = place;
                            $scope.company = company;
                        }]
                    }
                },
                resolve: {
                    placeId: ['$stateParams', ($stateParams) => {
                        return $stateParams.placeId;
                    }],
                    place: ['companyId', 'placeId', '$http', (companyId, placeId, $http) => {
                        return $http.get(`/rateit/api/companies/${companyId}/places/${placeId}`)
                            .then(r => {
                                return r.data;
                            });
                    }]
                }
            })
            .state('ri.admin.secure.companies.detail.demoRatings', {
                url: '/demo-ratings',
                data: {
                    title: 'Import Demo Ratings',
                    modal: true,
                    secure: true
                },
                views: {
                    'dialog@ri.admin.secure.companies.detail': {
                        template: '<company-demo-rating-import company-id="companyId"></company-demo-rating-import>',
                        controller: ['$scope', 'companyId', function ($scope, companyId) {
                            $scope.companyId = companyId;
                        }]
                    }
                },
                resolve: {
                    companyId: ['$stateParams', ($stateParams) => {
                        return $stateParams.companyId;
                    }]
                }
            });

        createCompanyRatingStates($stateProvider);
        createPlaceRatingStates($stateProvider);
        createCompanyUserStates($stateProvider);
    }]);