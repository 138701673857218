import template from './rating-picture.html';

function ratingPictureDirective () {
    return {
        template,
        controller: function () {},
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            imageUrl: '<'
        }
    }
}

export default ratingPictureDirective;