import controller from './application-setting-index-controller';
import template from './application-setting-index.html';

function applicationSettingIndexDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            settings: '='
        }
    }
}

export default applicationSettingIndexDirective;