class YesNoController {
    constructor () {
    }

    $onInit () {
        if (this.model && this.model.isNew) {
            this.model.data.title = this.model.data.title || 'We\'d love to hear more about your experience?';
            this.model.data.subtitle = this.model.data.subtitle || 'We\'ll only need 15-30 seconds';
            this.model.data.yesCaption = this.model.data.yesCaption = 'Yes, I\'d love to help';
            this.model.data.noCaption = this.model.data.noCaption = 'No, thanks';
            this.model.data.personalizationInfo = this.model.data.personalizationInfo || {};
        }
    }
}

export default YesNoController;