class CompanyCreateController {
  constructor($http, $state, $scope, messages) {
    this.$http = $http;
    this.$state = $state;
    this.$scope = $scope;
    this.messages = messages;
    this.$scope.appBotValidation;

    this.company = {
      rules: [],
      emailTriggerHour: 7,
      billingDay: 1,
      currentSmsLimit: 500,
      features: []
    };

    this.wizardOptions = {
      screens: [
        {
          state: "ri.admin.secure.companies.create.step1",
          onexit: () => {
            return Promise.resolve(["ri.admin.secure.companies.create.step2"]);
          }
        },
        {
          state: "ri.admin.secure.companies.create.step2",
          onexit: () => {
            return Promise.resolve(["ri.admin.secure.companies.create.step3"]);
          },
          onback: () => {
            return Promise.resolve(["ri.admin.secure.companies.create.step1"]);
          }
        },
        {
          state: "ri.admin.secure.companies.create.step3",
          onexit: () => {
            return this.save();
          },
          onback: () => {
            return Promise.resolve(["ri.admin.secure.companies.create.step2"]);
          }
        }
      ]
    };

    this.$scope.validateAppBotApiCredentials = function (
      appBotAppId,
      appBotApiKey
    ) {
      if (!appBotAppId || !appBotApiKey) {
        $scope.appBotValidation = "invalid";
        return;
      }

      $scope.appBotValidation = "loading";

      $http({
        method: "GET",
        url: `https://api.appbot.co/api/v2/apps/${appBotAppId}/reviews`,
        headers: {
          Authorization: `Basic ${appBotApiKey}`
        }
      }).then(
        function successCallback() {
          $scope.appBotValidation = "valid";
        },
        function errorCallback() {
          $scope.appBotValidation = "invalid";
        }
      );
    };
  }

  save() {
    return this.$http.post("/rateit/api/companies", this.company).then(r => {
      var companyId = r.data.id;
      this.messages.showSuccessMessage(
        `The company has been successfully created.`,
        null,
        -1
      );
      return ["ri.admin.secure.companies.detail", { companyId: companyId }];
    });
  }
}

CompanyCreateController.$inject = ["$http", "$state", "$scope", "messages"];

export default CompanyCreateController;
