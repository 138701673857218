import controller from './company-demo-controller';
import template from './company-demo.html';
import './company-demo.scss';

function companyDemoDirective() {
    return {
        template,
        controller,
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            company: '='
        },
        scope: true
    }
}

export default companyDemoDirective;