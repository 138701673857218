class ThanksController {
    constructor () {
    }

    $onInit () {        
        if (this.model && this.model.isNew) {
            this.model.data.title = this.model.data.title || 'Thanks!\nWe really appreciate\nyour feedback!';
            this.model.data.closingCaption = this.model.data.closingCaption || 'Closing in';
            this.model.data.imageCaption = this.model.data.imageCaption || 'rateitapp.com';
            this.model.data.imageUrl = this.model.data.imageUrl || 'https://static.rateitapp.com/images/rateItLogoBlack.png';
            this.model.navigation = this.model.navigation || {};
            this.model.navigation.defaultTargetId = '00000000000000000000000000000001';            
        }
    }
}

export default ThanksController;