import controller from './device-profile-diagram-controller';
import template from './device-profile-diagram.html';
import './device-profile-diagram.scss';

function deviceProfileDiagramDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: true,
        scope: {
            deviceProfile: '='
        }
    }
}

export default deviceProfileDiagramDirective;