import angular from 'angular';
import uiRouter from '@uirouter/angularjs';
import uicommon from '../ui-common/module';
import ngQuickGrid from 'ng-quick-grid';
import headerTemplate from './hero.html';
import DataSource from '../data-source';

export default angular.module('ri.admin.dashboard', [uiRouter, uicommon.name, 'ngQuickGrid'])    
    .config(['$stateProvider', '$urlRouterProvider', ($stateProvider, $urlRouterProvider) => {
        // $stateProvider
        //     .state('ri.admin.secure', {
        //         url:'/',
        //         template: `<site-nav></site-nav><router-progress></router-progress><div ui-view="hero"></div>
        //         <div class="container">
        //             <div ui-view="content"></div>
        //             </div>`,
        //         data: {
        //             secure: true,
        //             title: 'Dashboard',
        //             icon: 'dashboard'
        //         },
        //         controller: ['$window', ($window) => {
        //             if (WP_IS_PROD) {
        //                 //$window.document.location.href = '/';
        //             }
        //         }]
        //      })
    }]);