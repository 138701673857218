class LoginController {
    constructor ($http, $httpParamSerializerJQLike, $location, $window) {
        this.$http = $http;
		this.$httpParamSerializerJQLike = $httpParamSerializerJQLike;
        this.$location = $location;
		this.$window = $window;
        this.model = {};
    }

    login () {
        var headers = {'Content-Type': 'application/x-www-form-urlencoded'};
		if (this.model.otp) {
			headers['X-OTP'] = this.model.otp;
		}

		this.loading = true;

        return this.$http({
		    method: 'POST',
		    url: '/token',
		    headers: headers,
		    data: this.$httpParamSerializerJQLike({username: this.model.username, password: this.model.password, grant_type: 'password'})
		}).then((response) => {
			var data = response.data;
			var token = data['access_token'];
			var refreshToken = data['refresh_token'];
			var username = data.username;
			var claims = data.claims;
			
			this.$window.localStorage.setItem('claims', claims);
			this.$window.localStorage.setItem('username', username);
			this.$window.localStorage.setItem('token', token);
			this.$window.localStorage.setItem('refreshToken', refreshToken);
			//this.$location.path('/');''
			this.$window.document.location.href = '/';
		}, () => {			
			this.loading = false;
		});
    }
}

LoginController.$inject = ['$http', '$httpParamSerializerJQLike', '$location', '$window'];

export default LoginController;