class CompanyUserDetailController {
  constructor($http, $state, $scope) {
    this.$http = $http;
    this.$state = $state;
    this.$scope = $scope;
  }

  $onInit() {
    this.tags = [
      { label: 'Super User', color: 'success', state: this.user.accountType === 1 },
      { label: 'Developer', color: 'warning', state: this.user.accountType === 2 },
      { label: 'Can Reply', color: 'success', state: this.user.canReplyToCustomers },
      { label: 'Can Validate Ratings', color: 'success', state: this.user.canValidateRatings },
      { label: 'Can Access Targeted Email/SMS', color: 'success', state: this.user.canAccessWebFeedback },
    ].filter(x => x.state);

    this.notificationSettings = [
      { label: 'Rating with comment received', state: this.user.subscribeSettings && this.user.subscribeSettings.sendAllComments },
      { label: 'Negative rating received', state: this.user.subscribeSettings && this.user.subscribeSettings.sendNegativeRatings },
      { label: 'Daily Reports', state: this.user.subscribeSettings && this.user.subscribeSettings.enableDailyStats },
      { label: 'Weekly Reports', state: this.user.subscribeSettings && this.user.subscribeSettings.enableWeeklyStats },
      { label: 'Offline Device Alerts', state: this.user.subscribeSettings && this.user.subscribeSettings.sendOfflineDeviceAlert },
    ]
    
    this.user.currentCompanyName = this.user.accessibleCompanies.find(company => company.id == this.companyId).name;
    this.user.lockoutEndDateLocal = this.user.lockoutEndDateUtc ? new Date(this.user.lockoutEndDateUtc + "z") : null;

  }
}

CompanyUserDetailController.$inject = ['$http', '$state', '$scope'];

export default CompanyUserDetailController;