import controller from './company-places-controller';
import template from './company-places.html';
import './company-places.scss';

function companyPlacesDirective () {
    return {
        template,
        controller,		
        restrict: 'E',
        controllerAs: 'vm',
        bindToController: {
            companyId: '<',
            places: '<',
            registrations: '<'
        }
    }
}

export default companyPlacesDirective;